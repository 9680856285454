import React, { useState } from 'react'
import AuthenticationLayout from 'layouts/AuthenticationLayout'
import { Typography, TextField, makeStyles, Link, Box } from '@material-ui/core'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import LoadableButton from 'components/LoadableButton'
import { styles } from './styles'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { parseDomain, parseBaseDomain } from 'contexts/SubdomainContext'
import { useFirestore } from 'reactfire'
import { validateLearningSpace } from 'utils/LearningSpaceUtils'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

interface IProps {}

const EnterSubDomain: React.FC<IProps> = () => {
  let [loading, setLoading] = useState<boolean>(false)
  let [subdomain, setSubdomain] = useState<string>('')
  let [error, setError] = useState<{ [key: string]: string }>({})
  const learningSpaceCollections = useFirestore().collection('learning_spaces')
  const history = useHistory()

  const classes = useStyles(() => {})

  const handleEnterSubdomain = (event: any) => {
    setSubdomain(event.target.value)
  }

  const handleSubmitSubDomain = (event: any) => {
    event.preventDefault()
    let $e = validateLearningSpace({ subdomain: subdomain })
    setError($e)
    if (_.isEmpty($e)) {
      setLoading(true)
      learningSpaceCollections
        .doc(subdomain)
        .get()
        .then((querySnapshot: firebase.firestore.DocumentSnapshot) => {
          if (querySnapshot.data()) {
            let currentPathName = window.location.pathname
            window.location.href = parseDomain(subdomain) + currentPathName
          } else {
            setError({ subdomain: 'Không gian học tập này không tồn tại!.' })
          }
        })
        .finally(() => setLoading(false))
    }
  }

  const redirectToRegisterLearningSpace = () => {
    history.push('register-learning-space')
  }

  return (
    <AuthenticationLayout>
      <div className={classes.formTitle}>
        <div style={{ marginBottom: '10px' }}>
          <Typography variant="h5" gutterBottom>
            <strong>Đăng nhập</strong>
          </Typography>
          <Typography variant="body2" component="span" color="textSecondary">
            Vui lòng nhập khu vực học tập của bạn!
          </Typography>
        </div>

        <Grid item sm={8} className={classes.formSubDomain}>
          <form onSubmit={handleSubmitSubDomain}>
            <div>
              <div className={classes.inputSubdomain}>
                <TextField
                  error={error.subdomain !== undefined}
                  onChange={handleEnterSubdomain}
                  fullWidth={true}
                  size="small"
                  label="Khu vực học tập"
                  variant="outlined"
                />
                <h3 className={classes.baseDomain}>.{parseBaseDomain()}</h3>
              </div>
              {error.subdomain && (
                <small className={classes.textValidate}>
                  {error.subdomain}
                </small>
              )}
            </div>

            <LoadableButton
              type="submit"
              loading={loading}
              endIcon={<ArrowRightAlt />}
              size="large"
              variant="contained"
              fullWidth={true}
              className={classes.buttonSubmitSubDomain}
              color="primary"
            >
              Continue
            </LoadableButton>
          </form>
          <Box mt={2} textAlign={'center'}>
            <Link onClick={redirectToRegisterLearningSpace}>
              Tạo không gian học tập
            </Link>
          </Box>
        </Grid>
      </div>
    </AuthenticationLayout>
  )
}

export default EnterSubDomain
