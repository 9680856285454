import MaybachTable from 'components/MaybachTable/MaybachTable'
import { TableCellProps } from '@material-ui/core'
import { Range } from 'xlsx'

export interface IHeader {
  key: string
  label: string
  w?: number // width
  tooltip?: string
  bold?: boolean
  mw?: number // min width
  maxW?: number // max width
  sticky?: boolean
  vertical?: boolean
  style?: TableCellProps['style']
  align?: TableCellProps['align']
}

export interface IRow {
  [row: string]: {
    label: any
    value?: any
    navigateTo?: string
    exportValue?: string
  }
}

export interface IProps {
  headers: IHeader[]
  rows: IRow[]
  showIndex?: boolean
  filters?: {
    [key: string]: {
      data: { id: string; label: string }[]
      options?: {
        autocomplete?: boolean
      }
      label?: string
    }
  }
  searchBox?: boolean
  searchFields?: string[]
  onSearch?: (value: string) => void
  paginationComponent?: any
  bottomToolbar?: any
  rowsPerPage?: number
  removable?: boolean
  onRemove?: (index: number, row: { [key: string]: any }) => void
  onClick?: () => void
  page?: number
  exportable?: boolean
  csvTitle?: string
  csvHeader?: string[]
  getCsvRow?: (row: { [key: string]: { [key: string]: any } }) => string[]
  toolbar?: any
  loading?: boolean
  getServerExportData?: () => Promise<IRow[]>
  merges?: Range[]
  rowsAboveHeader?: string[][]
  caption?: any
  defaultFilterValues?: { [k: string]: string }
  leftToolbar?: any
}

export interface ITableRowProps {
  headers: IHeader[]
  row: IRow
  removable?: boolean
  onRemove?: (index: number, row: { [key: string]: any }) => void
  page: number
  index: number
  rowsPerPage: number
  showIndex?: boolean
}

export default MaybachTable
