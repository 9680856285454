import {
  LectureType,
  ActivityType,
  EnrolledCourseType,
  LessonResultType,
  ActivityResultType,
  EnrolledCourseTimeTrackOption
} from 'types'
import firebase, { firestore } from 'firebase/app'
import _ from 'lodash'

export function isFirstTimeLearning(enrolledCourse: EnrolledCourseType) {
  return enrolledCourse.status === 'PENDING'
}

export function saveResultAndUpdateCurrentEntry(
  lesson: LectureType,
  enrolled: EnrolledCourseType
): Promise<void> {
  let db = firestore()

  const batch = db.batch()
  const data: Pick<LessonResultType, 'state' | 'courseId' | 'timeTrack'> = {
    state: 'INCOMPLETE',
    courseId: enrolled.courseId,
    timeTrack: {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }
  }

  enrolled._meta.ref = db.collection('enrolled_courses').doc(enrolled.id)

  try {
    const enrolledCourseData = {
      currentEntry: lesson.id
    } as EnrolledCourseType

    if (isFirstTimeLearning(enrolled)) {
      enrolledCourseData.status = 'IN PROGRESS'
      const data = {
        startedAt: firestore.FieldValue.serverTimestamp()
      } as EnrolledCourseTimeTrackOption
      enrolledCourseData.timeTrack = data
    }

    batch.set(enrolled._meta.ref, enrolledCourseData, { merge: true })

    const resultRef = enrolled._meta.ref.collection('results').doc(lesson.id)
    batch.set(resultRef, data)
    if (lesson.activities) {
      for (const ii in lesson.activities) {
        const data: Omit<ActivityResultType, '_meta'> = {
          actRef: db.doc(lesson.activities[ii].refPath as string),
          actTitle: lesson.activities[ii].title,
          actType: lesson.activities[ii].type,
          state: 'UNFINISHED',
          timeTrack: {}
        }
        batch.set(
          resultRef.collection('activities').doc(lesson.activities[ii].id),
          data
        )
      }
    }

    return batch.commit()
  } catch (error) {
    throw error
  }
}

/**
 * Create new activityResult from activity
 * @param activity
 * @param ref
 * @param initial optional setup data, example create new document which was finished
 */
export async function createResultFromActivity(
  activity: ActivityType,
  ref: firebase.firestore.DocumentReference,
  initial?: { [key: string]: any }
): Promise<ActivityResultType> {
  const data: Omit<ActivityResultType, '_meta'> = {
    state: initial?.state || 'UNFINISHED',
    actTitle: activity.title,
    actType: activity.type,
    actRef: ref,
    timeTrack: {
      firstFinishedAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastFinishedAt: firebase.firestore.FieldValue.serverTimestamp()
    }
  }
  await ref.set(data)
  return { ...data, id: activity.id, _meta: { ref: ref } }
}

export async function passActivity(
  activity: ActivityType,
  result: ActivityResultType | undefined,
  activityRef: firebase.firestore.DocumentReference,
  ref?: firebase.firestore.DocumentReference // use for create new result or result missing
): Promise<ActivityResultType> {
  if (!result && ref) {
    return await createResultFromActivity(activity, ref, {
      state: 'FINISHED'
    })
  }

  if (result && result.state !== 'FINISHED') {
    if (ref && !result._meta.ref) result._meta.ref = ref
    const data: Pick<ActivityResultType, 'state' | 'timeTrack'> = {
      state: 'FINISHED',
      timeTrack: {
        firstFinishedAt:
          result.timeTrack.firstFinishedAt ||
          firebase.firestore.FieldValue.serverTimestamp(),
        lastFinishedAt: firebase.firestore.FieldValue.serverTimestamp()
      }
    }
    await result._meta.ref?.update(data)
    return _.assign(result, data)
  }

  throw new Error('Neither result nor ref exists')
}
