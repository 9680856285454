export const generateRandomString = (length: number) => {
  const template = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += template.charAt(Math.floor(Math.random() * template.length))
  }
  return result
}

export function strcmp(a: string, b: string) {
  return a < b ? -1 : a > b ? 1 : 0
}
