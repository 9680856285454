 const images = {
  LOGO: require('./Agilearn-logo.png').default,
  LOGO_WHITE: require('./Icon-white.svg').default,
  APPLE_STORE: require('./apple-store.png').default,
  GOOGLE_PLAY: require('./google-play.png').default,
  CERT_CONGRATS_ICON: require('./cert-congrats-icon.png').default,
  CERT_CONTENT_BG: require('./cert-content-bg.png').default,
  MC_WORD_ICON: require('./word.png').default,
  PPTX_ICON: require('./PowerPointIcon.png').default,
  EXCEL_ICON: require('./Excel-icon.png').default,
  PDF_ICON: require('./pdf-icon.png').default,
  DOC_ICON: require('./doc.png').default,
  CROWN_ICON: require('./crown.png').default,
  BLANK_CERTIFICATE: require('./blank_certificate.png').default,
  AUDIO_ICON: require('./audio_icon.png').default
}

export default images
