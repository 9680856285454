import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfQuarter,
  endOfQuarter,
  addQuarters,
  startOfYear,
  endOfYear,
  addYears,
} from "date-fns";

export interface IRange {
  id: string;
  type: string;
  isCurrent: boolean;
  label: string;
  previousLabel: string;
  range: () => {
      startDate: Date;
      endDate: Date;
  };
}

const today = new Date();
const lastWeek = addDays(today, -7);
const yesterday = addDays(today, -1);
const lastMonth = addMonths(today, -1);
const lastQuarter = addQuarters(today, -1);
const lastYear = addYears(today, -1);

export const RANGE_LABELS = {
  THIS_WEEK: "Tuần này",
  LAST_WEEK: "Tuần trước",
  PREVIOUS_OF_LAST_WEEK: "Tuần trước nữa",
  THIS_QUARTER: "Quý này",
  LAST_QUARTER: "Quý trước",
  PREVIOUS_OF_LAST_QUARTER: "Quý trước nữa",
  THIS_MONTH: "Tháng này",
  LAST_MONTH: "Tháng trước",
  PREVIOUS_OF_LAST_MONTH: "Tháng trước nữa",
  THIS_YEAR: "Năm nay",
  LAST_YEAR: "Năm trước",
  PREVIOUS_OF_LAST_YEAR: "Năm trước nữa",
};

export const defineds = {
  startOfWeek: addDays(startOfWeek(today), 1),
  endOfWeek: addDays(endOfWeek(today), 1),
  startOfLastWeek: addDays(startOfWeek(lastWeek), 1),
  endOfLastWeek: addDays(endOfWeek(lastWeek), 1),
  startOfToday: startOfDay(today),
  endOfToday: endOfDay(today),
  startOfYesterday: startOfDay(yesterday),
  endOfYesterday: endOfDay(yesterday),
  startOfMonth: startOfMonth(today),
  endOfMonth: endOfMonth(today),
  startOfLastMonth: startOfMonth(lastMonth),
  endOfLastMonth: endOfMonth(lastMonth),
  startOfThisQuarter: startOfQuarter(today),
  endOfOfThisQuarter: endOfQuarter(today),
  startOfLastQuarter: startOfQuarter(lastQuarter),
  endOfLastQuarter: endOfQuarter(lastQuarter),
  startOfThisYear: startOfYear(today),
  endOfThisYear: endOfYear(today),
  startOfLastYear: startOfYear(lastYear),
  endOfLastYear: endOfYear(lastYear),
};

export const defaultStaticRanges = [
  {
    id: "thisWeek",
    type: "week",
    isCurrent: true,
    label: RANGE_LABELS.THIS_WEEK,
    previousLabel: RANGE_LABELS.LAST_WEEK,
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    id: "lastWeek",
    type: "week",
    isCurrent: false,
    label: RANGE_LABELS.LAST_WEEK,
    previousLabel: RANGE_LABELS.PREVIOUS_OF_LAST_WEEK,
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    id: "thisMonth",
    type: "month",
    isCurrent: true,
    label: RANGE_LABELS.THIS_MONTH,
    previousLabel: RANGE_LABELS.LAST_MONTH,
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    id: "lastMonth",
    type: "month",
    isCurrent: false,
    label: RANGE_LABELS.LAST_MONTH,
    previousLabel: RANGE_LABELS.PREVIOUS_OF_LAST_MONTH,
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    id: "thisQuarter",
    type: "quarter",
    isCurrent: true,
    label: RANGE_LABELS.THIS_QUARTER,
    previousLabel: RANGE_LABELS.LAST_QUARTER,
    range: () => ({
      startDate: defineds.startOfThisQuarter,
      endDate: defineds.endOfOfThisQuarter,
    }),
  },
  {
    id: "lastQuarter",
    type: "quarter",
    isCurrent: false,
    label: RANGE_LABELS.LAST_QUARTER,
    previousLabel: RANGE_LABELS.PREVIOUS_OF_LAST_QUARTER,
    range: () => ({
      startDate: defineds.startOfLastQuarter,
      endDate: defineds.endOfLastQuarter,
    }),
  },
  {
    id: "thisYear",
    type: "year",
    isCurrent: true,
    label: RANGE_LABELS.THIS_YEAR,
    previousLabel: RANGE_LABELS.LAST_YEAR,
    range: () => ({
      startDate: defineds.startOfThisYear,
      endDate: defineds.endOfThisYear,
    }),
  },
  {
    id: "lastYear",
    type: "year",
    isCurrent: false,
    label: RANGE_LABELS.LAST_YEAR,
    previousLabel: RANGE_LABELS.PREVIOUS_OF_LAST_YEAR,
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
];

export const getPreviousRange = (range: IRange) => {
  switch (range.type) {
    case 'week':
      const previousWeek = addDays(range.range().startDate, -7)
      return {
        startDate: startOfWeek(previousWeek, {weekStartsOn: 1}),
        endDate: endOfWeek(previousWeek, {weekStartsOn: 1})
      }
    case 'month':
      const previousMonth = addMonths(range.range().startDate, -1)
      return {
        startDate: startOfMonth(previousMonth),
        endDate: endOfMonth(previousMonth)
      }
    case 'quarter':
      const previousQuarter = addQuarters(range.range().startDate, -1)
      return {
        startDate: startOfQuarter(previousQuarter),
        endDate: endOfQuarter(previousQuarter)
      }
    case 'year':
      const previousYear = addYears(range.range().startDate, -1)
      return {
        startDate: startOfYear(previousYear),
        endDate: endOfYear(previousYear)
      }
    default:
      return undefined;
  }
}

export const getNextRange = (range: IRange) => {
  switch (range.type) {
    case 'week':
      const nextWeek = addDays(range.range().startDate, 7)
      return {
        startDate: startOfWeek(nextWeek, {weekStartsOn: 1}),
        endDate: endOfWeek(nextWeek, {weekStartsOn: 1})
      }
    case 'month':
      const nextMonth = addMonths(range.range().startDate, 1)
      return {
        startDate: startOfMonth(nextMonth),
        endDate: endOfMonth(nextMonth)
      }
    case 'quarter':
      const nextQuarter = addQuarters(range.range().startDate, 1)
      return {
        startDate: startOfQuarter(nextQuarter),
        endDate: endOfQuarter(nextQuarter)
      }
    case 'year':
      const nextYear = addYears(range.range().startDate, 1)
      return {
        startDate: startOfYear(nextYear),
        endDate: endOfYear(nextYear)
      }
    default:
      return undefined;
  }
}
