import {
  Box,
  BoxProps,
  ButtonProps,
  createStyles,
  makeStyles
} from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import CLSX from 'clsx'
import { useActiveWhen } from 'hooks/useActiveWhen'

import MaybachButton, { MaybachButtonProps } from 'components/MaybachButton'

export interface IButtonLink extends Omit<MaybachButtonProps, 'color'> {
  to: string
  color?: ButtonProps['color'] | 'import'
  boxProps?: BoxProps
  minWidth?: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    import: {
      backgroundColor: theme.palette.success.main,
      color: '#fff',

      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    }
  })
)

const ButtonLink: React.FC<IButtonLink> = ({
  to,
  children,
  boxProps = {},
  minWidth = 150,
  color,
  className,
  ...rest
}) => {
  const active = useActiveWhen(rest.activeWhen || [])

  const classes = useStyles(() => {})

  if (!active)
    return (
      <Box {...boxProps}>
        <MaybachButton
          className={className}
          {...rest}
          minWidth={minWidth}
          disabled={true}
        >
          {children}
        </MaybachButton>
      </Box>
    )

  return (
    <Box {...boxProps}>
      <Link
        component={(linkProps) => {
          return (
            <MaybachButton
              onClick={linkProps.navigate}
              children={linkProps.children}
              {...rest}
              minWidth={minWidth}
              className={CLSX({
                [classes.import]: color === 'import',
                [className || '']: true
              })}
              color={color === 'import' ? undefined : color}
            />
          )
        }}
        to={to}
      >
        {children}
      </Link>
    </Box>
  )
}

export default ButtonLink
