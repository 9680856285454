import React, { useMemo, useState } from 'react'
import { formatDuration, fromDurationToSecond } from 'utils/StringFormatter'
import { LectureIconType, LectureType } from 'types'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core'
import MarkdownEditor from 'components/MarkdownEditor'
import { validateLecture } from 'utils'
import firebase from 'firebase'
import LoadableButton from 'components/LoadableButton'
import { IFirestoreMetadata } from 'interfaces'
import { isEmpty, pick } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { getRadioOptionLabel } from 'components/LectureForm/LectureForm'
import lectureIcons from 'configs/lectureIconsConfig'
import { useDispatch, useSelector } from 'react-redux'
import { addTags, selectTagLoadState, selectTags } from 'reducers/redux/tags'
import AddableTagsAutoComplete from 'widgets/AddableTagsAutoComplete'
import { useLoadTags } from 'hooks/useLoadTags'
import {
  createTags,
  getMapOfTags,
  reformatTagAuthocompleteValue as reformatTagAutocompleteValue
} from '../../views/AddNewLecture/AddNewLecture'
import { useFirestore } from 'reactfire'
import Loading from 'components/Loading'
import { useSnackbar } from 'contexts/SnackbarContext'

interface IProps {
  lecture: LectureType & IFirestoreMetadata
}

const LectureFormEditBody: React.FC<IProps> = (props) => {
  const toast = useSnackbar()

  const { lecture } = props
  const [saving, setSaving] = useState<boolean>(false)
  const [intro, setIntro] = useState<string>(lecture.intro ?? '')
  const [brief, setBrief] = useState<string>(lecture.brief ?? '')
  const [duration, setDuration] = useState<string>(lecture.durations + '')
  const [icon, setIcon] = useState<LectureIconType>(lecture.icon)
  const [title, setTitle] = useState<string>(lecture.title ?? '')
  const [error, setError] = useState<{ [key: string]: string }>({})

  const history = useHistory()

  const mapTags = useSelector(selectTags)
  const tags = useMemo(
    () => Object.values(mapTags).map((t) => pick(t, ['id', 'display'])),
    [mapTags]
  )
  const [selectedTags, setSelectedTags] = useState<any>(
    Object.keys(lecture.tags || {})
      .filter((tag) => (lecture.tags as any)[tag] && !!mapTags[tag])
      .map((tag) => pick(mapTags[tag], ['id', 'display']))
  )

  const db = useFirestore()
  const space = useLearningSpace()

  const dispatch = useDispatch()

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault()
    setSaving(!saving)
    const lectureDataUpdate = {
      ...lecture,
      icon: icon,
      durations: fromDurationToSecond(duration),
      brief: brief || '',
      intro: intro || '',
      title: title,
      tags: {},
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    const _e = validateLecture(lectureDataUpdate)

    if (!isEmpty(_e)) {
      setError(_e)
      setSaving(false)
    } else {
      const batch = db.batch()

      const saveTags = reformatTagAutocompleteValue([...selectedTags])
      const newTags = createTags(saveTags, space._meta.ref, batch)

      dispatch(addTags(newTags))

      lectureDataUpdate.tags = getMapOfTags(saveTags)

      batch.update(lecture._meta.ref, lectureDataUpdate)

      batch
        .commit()
        .then(() => {
          history.push(`/admin/lectures`)
          toast('Cập nhật thành công!')
        })
        .catch((err) => {
          toast(err.message, 'error')
          console.error(err)
        })
        .finally(() => {
          setSaving(false)
        })
    }
  }

  return (
    <Box mb={5}>
      <form
        onSubmit={handleSave}
        id="add-lecture-frm"
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12} md={12}>
          <Box mt={2}>
            <TextField
              required
              id="lecture-title"
              name="lecture-title"
              label="Tiêu đề"
              autoFocus
              fullWidth
              defaultValue={title}
              variant="outlined"
              error={error.title !== undefined}
              helperText={error.title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2}>
            <TextField
              label="Thời lượng"
              id="lecture-duration"
              name="lecture-duration"
              variant="outlined"
              helperText="Ví dụ: 3:19"
              defaultValue={formatDuration(duration)}
              size="small"
              onChange={(e) => setDuration(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <FormControl component="fieldset" error={error.icon !== undefined}>
              <FormLabel component="legend">Biểu tượng</FormLabel>
              <RadioGroup
                aria-label="icon"
                name="icon"
                row
                value={icon}
                onChange={(e) => setIcon(e.target.value as LectureIconType)}
              >
                {Object.keys(lectureIcons).map((keyName) => (
                  <FormControlLabel
                    value={keyName}
                    control={<Radio />}
                    label={getRadioOptionLabel(keyName)}
                  />
                ))}
              </RadioGroup>
              {error.icon && <FormHelperText>{error.icon}</FormHelperText>}
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <TextField
              label="Tóm tắt"
              id="lecture-desc"
              name="lecture-desc"
              variant="outlined"
              size="small"
              multiline
              rows={3}
              fullWidth
              defaultValue={brief}
              onChange={(e) => setBrief(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <FormLabel component="legend">Giới thiệu</FormLabel>
            <Box mt={2}>
              <MarkdownEditor
                value={intro}
                onChange={setIntro}
                minEditorHeight={200}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AddableTagsAutoComplete
            tags={tags}
            selectedTags={selectedTags}
            onChangeSelectedTags={setSelectedTags}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <LoadableButton
              type="submit"
              color="primary"
              variant="contained"
              loading={saving}
            >
              Cập nhật
            </LoadableButton>
          </Box>
        </Grid>
      </form>
    </Box>
  )
}

const LectureFormEdit: React.FC<IProps> = (props) => {
  const { lecture } = props
  useLoadTags()

  const loadTagState = useSelector(selectTagLoadState)

  if (!lecture) {
    throw new Error('Something went wrong!. Lecture is required!')
  }

  if (!loadTagState.loaded) return <Loading />

  return <LectureFormEditBody {...props} />
}

export default LectureFormEdit
