import React from 'react'
import { TableCell, TableCellProps, Tooltip } from '@material-ui/core'
import { Help } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/core/styles'

interface IProps {
  tooltip: string
  className?: string
  style?: TableCellProps['style']
  align?: TableCellProps['align']
}

const useStyles = makeStyles(() =>
  createStyles({
    text: { display: 'flex', alignItems: 'initial' },
    helpIcon: { marginLeft: 2, fontSize: 12 }
  })
)

const TableCellTooltip: React.FC<IProps> = ({
  className,
  children,
  tooltip,
  style,
  align
}) => {
  const classes = useStyles(() => {})
  return (
    <TableCell align={align} style={style} className={className}>
      <Tooltip placement={'top'} title={tooltip}>
        <span className={classes.text}>
          {children}
          <Help className={classes.helpIcon} fontSize={'small'} />
        </span>
      </Tooltip>
    </TableCell>
  )
}

export default TableCellTooltip
