import React, { useEffect, useCallback } from 'react'
import { useUser, useAuth } from 'reactfire'
import { getCustomToken } from 'utils'
import { LinearProgress } from '@material-ui/core'

interface IProps {}

const Authentication: React.FC<IProps> = (props) => {
  const user = useUser()
  const auth = useAuth()

  const verifyAuthenticationWithCustomToken = useCallback(async () => {
    try {
      const result = await getCustomToken()

      if (result && result.customToken) {
        await auth.signInWithCustomToken(result.customToken)
        return
      }
    } catch (e) {}

    localStorage.setItem('old_direction', window.location.href)
    window.location.href = '/login'
  }, [auth])

  useEffect(() => {
    if (!user) {
      verifyAuthenticationWithCustomToken()
        .then()
        .catch((e) => {
          console.error(e)
        })
    }
  }, [verifyAuthenticationWithCustomToken, user])

  if (!user) {
    return <LinearProgress />
  }

  return <>{props.children}</>
}

export default Authentication
