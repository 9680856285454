import { Typography, Link as MLink, Grid, Box } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthorization } from '../../hooks/useAuthorization'
import { ROLES } from '../../utils'
import { useLearningSpace } from '../../contexts/LearningSpaceContext'
import { useCollectionData } from '../../hooks'
import {
  LearningSpaceCertificateTemplate,
  LearningSpaceCourse
} from '../../types'
import useStyles from './styles'
import { useSpaceCourse } from '../../contexts/SpaceCourseContext'
import CLSX from 'clsx'
import LoadableButton from '../../components/LoadableButton'
import { useSnackbar } from '../../contexts/SnackbarContext'

interface IProps {}

const LearningSpaceCourseCertificate: React.FC<IProps> = () => {
  const spaceCourse = useSpaceCourse()

  const [selectedCertificate, setSelectedCertificate] = useState(
    spaceCourse.completeCertificate?.ref.path
  )
  const [saving, setSaving] = useState(false)
  const hasChanged = useMemo(
    () => selectedCertificate !== spaceCourse.completeCertificate?.ref.path,
    [spaceCourse.completeCertificate, selectedCertificate]
  )

  const toast = useSnackbar()

  const learningSpace = useLearningSpace()
  const query = learningSpace._meta.ref
    .collection('certificate_templates')
    .where('state', '==', 'PUBLISHED')
    .orderBy('timeTracks.createdAt', 'desc')

  const certificates = useCollectionData<LearningSpaceCertificateTemplate>(
    query,
    { idField: 'id' }
  )
  const handleSaveCertificate = useCallback(async () => {
    if (spaceCourse && !saving) {
      try {
        setSaving(true)
        const selectedCert = certificates.find(
          (cert) => cert._meta.ref.path === selectedCertificate
        )
        if (selectedCert) {
          const updateData: Pick<LearningSpaceCourse, 'completeCertificate'> = {
            completeCertificate: {
              ref: selectedCert?._meta.ref,
              imageUrl: selectedCert.source,
              title: selectedCert.title
            }
          }
          await spaceCourse._meta.ref.set(updateData, { merge: true })

          toast('Cập nhật mẫu chứng chỉ thành công!')
        }
      } catch (e) {
        toast('Có lỗi xảy ra!', 'error')
        console.error(e)
      }

      setSaving(false)
    }
  }, [spaceCourse, saving, certificates, selectedCertificate, toast])

  const classNames = useStyles()

  const canCreateCertification = useAuthorization(ROLES.CERTIFICATION_ADMIN)

  if (!certificates.length) {
    if (!canCreateCertification)
      return (
        <>
          <Typography>Hiện tại không có mẫu chứng chỉ nào</Typography>
        </>
      )

    return (
      <>
        <Typography>
          Hiện tại không có mẫu chứng chỉ nào, bạn có thể tạo chứng chỉ{' '}
          <MLink component={Link} to={'/admin/certification-templates/add'}>
            tại đây
          </MLink>
        </Typography>
      </>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent={'flex-end'}>
          <LoadableButton
            onClick={handleSaveCertificate}
            disabled={!hasChanged}
            variant="contained"
            loading={saving}
            color="primary"
          >
            Lưu chứng chỉ
          </LoadableButton>
        </Box>
      </Grid>
      {certificates.map((certificate) => (
        <Grid
          onClick={() => setSelectedCertificate(certificate._meta.ref.path)}
          className={CLSX({
            [classNames.certBox]: true,
            [classNames.selectedCert]:
              certificate._meta.ref.path === selectedCertificate
          })}
          key={certificate.id}
          item
          xs={3}
        >
          <Box>
            <img
              style={{ width: '100%' }}
              src={certificate.source}
              alt={certificate.title}
            />
            <Typography className={classNames.certName}>
              {certificate.title}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default LearningSpaceCourseCertificate
