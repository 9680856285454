import React, { useMemo } from 'react'
import { ClassCourseCompleteRateStatistics, SchemeSectionType } from 'types'
import MaybachTable, { IHeader, IRow } from 'components/MaybachTable'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import Gadget from 'components/Gadget'
import { Typography } from '@material-ui/core'

interface Props {
  statistics: ClassCourseCompleteRateStatistics[]
  schemeSections: SchemeSectionType[]
  selectedClasses: { selected: boolean; className: string; id: string }[]
}

const CourseCompletionRateComparisonTable: React.FC<Props> = (props) => {
  const learningSpace = useLearningSpace()
  const { selectedClasses, statistics, schemeSections } = props

  const tableHeaders = useMemo(() => {
    const result: IHeader[] = [
      { key: 'className', label: 'Lớp' },
      { key: 'average', label: 'Trung bình', bold: true }
    ]
    schemeSections.forEach((section) => {
      result.push({ key: section.id as string, label: section.title })
    })
    return result
  }, [schemeSections])

  const tableData = useMemo(() => {
    const result: IRow[] = []
    selectedClasses.forEach((classInfo) => {
      const classStatistics = statistics.filter(
        (statistic) =>
          statistic.classId === classInfo.id &&
          statistic.learningSpaceId === learningSpace.id
      )
      const classSectionCourseCompletionRate: { [sectionId: string]: string } =
        {}

      let totalCompleteCourse = 0
      let totalCourses = 0
      classStatistics.forEach((statistic) => {
        totalCompleteCourse += statistic.totalComplete
        totalCourses += statistic.totalCourses
        classSectionCourseCompletionRate[statistic.sectionId] = `${(
          statistic.completeRate * 100
        ).toFixed(0)}%`
      })

      const row: IRow = { className: { label: classInfo.className } }
      schemeSections.forEach((section) => {
        row[section.id as string] = {
          label: classSectionCourseCompletionRate[section.id as string] || 'N/A'
        }
      })
      const average = totalCompleteCourse / totalCourses
      row['average'] = {
        label: average ? `${(average * 100).toFixed(0)}%` : 'N/A'
      }
      result.push(row)
    })
    return result
  }, [learningSpace.id, schemeSections, selectedClasses, statistics])

  return (
    <>
      <Gadget title={'Tỉ lệ hoàn thành khóa học online'}>
        <MaybachTable
          exportable
          headers={tableHeaders}
          rows={tableData}
          showIndex
          csvTitle={'Tỉ lệ hoàn thành khóa học'}
          caption={
            <>
              <Typography>
                Tỉ lệ hoàn thành khóa học online được tính theo
                <br />
              </Typography>
              <ul>
                <li>
                  Mục: Số khóa học đã hoàn thành trong mục / (Số khóa học trong
                  mục x Số học viên trong lớp)
                </li>
                <li>
                  <b>Trung bình</b>: Tổng tỉ lệ hoàn thành của tất cả các mục
                  trong lớp / Số mục trong lớp
                </li>
              </ul>
            </>
          }
        />
      </Gadget>
    </>
  )
}

export default CourseCompletionRateComparisonTable
