import {
  Administration,
  MyCourses,
  MyCourseDetail,
  StudentDashboard,
  Students,
  Classroom,
  ClassroomLessonDetail,
  ClassroomQuizDetail,
  ExecuteQuiz,
  LearningSpaceCourses,
  LearningSpaceCourseDetail,
  ClassroomQuizReview,
  LearningSpaceCourseDetailContent,
  LearningSpaceCourseDetailStudents,
  LearningSpaceCourseProgress,
  AdminExploreCourses,
  AdminDashboard,
  PersonalInfo,
  PersonalInfoDashboard,
  LearningSpaceCoursesExplore,
  AddStudentIntoCourse,
  ChangeLogs,
  LearningSpaceStudentDetail,
  CompleteRegistration,
  LearningSpaceSettings,
  LearningSpaceClasses,
  LearningSpaceClassDetailPeople,
  LearningSpaceClassDetail,
  LearningSpaceClassesCreate,
  LearningSpaceClassDetailStudentsAdd,
  LearningSpaceClassDetailCoursesAdd,
  CertificateGallery,
  OrderDashboard,
  ClassDetailGeneralInfo,
  ClassDetailArea,
  ClassDetailReport,
  LearningSpaceClassEdit,
  ClassDetailStudy,
  ClassDetailStudyArea,
  ClassDetailStudyAssignment,
  RedirectToClassroomFromClass,
  ClassDetailStudyAssignmentReview,
  LearningSpaceClassDetailStudy,
  LSClassDetailStudyAddCourse,
  LSClassDetailStudyAddAssignment,
  LSClassDetailStudyEditAssignment,
  Survey,
  LSClassDetailStudyAddExternalLink,
  LSProgramSectionSurveyAct,
  LSClassDetailStudyAddAttachment,
  LearningSpaceCourseQuizzes,
  LSClassDetailStudyEditExternalLink,
  LSProgramAssignmentEdit,
  ClassProgramCourseDetail,
  ClassDetailGradeActivities,
  LearningSpaceClassAddEvent,
  ClassDetailStudyAssignmentGrade,
  LearningSpaceClassAddQuiz,
  QuestionBanks,
  QuestionBankDetail,
  LearningSpaceAddQuestion,
  ClassDetailStudyQuizReview,
  ClassQuiz,
  ClassQuizExecute,
  ClassQuizReview,
  AddNewLecture,
  LectureDetail,
  AddActivity,
  EditActivity,
  CustomCourses,
  CreateCustomCourse,
  LearningSpaceCustomCourseDetail,
  LearningSpaceCustomCourseAddLecture,
  LearningSpaceEditQuestion,
  LearningSpaceCustomCourseAddQuiz,
  AddNewStudent,
  LearningSpaceCourseQuizDetail,
  CertificationExams,
  CertificationExamDetail,
  CertificationExamExecute,
  CertificationExamResult,
  StdCertificationExam,
  StudentCourseQuizSubmissions,
  StudentCourseQuizSubmissionDetail,
  LearningSpaceCourseImportUsers,
  CertificationExamImportCertified,
  ClassEventAttendance,
  LearningSpaceClassEditEvent,
  LearningSpaceClassChecklists,
  LearningSpaceClassAddChecklist,
  LearningSpaceClassCheckListDetail,
  LearningSpaceClassSettings,
  LearningSpaceClassDetailDiligence,
  LearningSpaceClassCreateBadge,
  ClassCourseSettings,
  TeachingHoursStatistics,
  ClassSectionSettings,
  ClassCourseSectionSettings,
  ClassDetailStudyQuiz,
  ClassDetailStudyQuizSummary,
  LearningSpaceCourseQuiz,
  LearningSpaceCourseQuizSummary,
  Lectures,
  Guidelines,
  AdminReportClass,
  ClassReflectionReview,
  ImportClassPeople,
  ClassStudentModuleGradeReport,
  StatisticTeachingHours,
  LSClassDetailStudyAddLesson,
  WorkflowReport,
  AddNewQuestionBank,
  LearningSpaceClassExpense,
  EditCourseSettings
} from 'views'
import { PrivateRoutCfgType } from 'types'
import QuestionDetail from 'views/QuestionDetail'
import CertificationExamImportUsers from 'views/CertificationExamImportUsers'
import AdminReportClassCompare from 'views/AdminReportClassCompare'
import LSClassFromSchemeConfigure from 'views/LSClassFromSchemeConfigure'
import LSClassDetailEditSurvey from 'views/LSClassDetailEditSurvey'
import EditLecture from 'views/EditLecture'
import LearningSpaceBugReports from 'views/LearningSpaceBugReports'
import { ROLES } from 'utils'
import CertificateTemplateLists from 'views/CertificateTemplateLists'
import CertificateTemplateAdd from 'views/CertificateTemplateAdd'
import LearningSpaceCourseCertificate from './views/LearningSpaceCourseCertificate'
import LearningSpaceClassFeedbacks from 'views/LearningSpaceClassFeebacks'
import { TITLE, TITLE_SIDEBAR } from 'constants/common'

const routing: Array<PrivateRoutCfgType> = [
  { path: '/change-logs', component: ChangeLogs },
  { path: '/', component: StudentDashboard },
  {
    path: '/complete-registration/:userEmail',
    component: CompleteRegistration
  },
  {
    path: '/personalInfo',
    component: PersonalInfo,
    childRoutes: [
      {
        path: '/personalInfo',
        component: PersonalInfoDashboard,
        title: TITLE.PERSONAL_INFO
      }
    ]
  },
  { path: '/survey', component: Survey },
  {
    path: '/certificate',
    component: CertificateGallery
  },
  {
    path: '/myOrders',
    component: OrderDashboard
  },
  {
    path: '/certification-exam/:id',
    component: StdCertificationExam
  },
  {
    path: '/classes/:classId',
    component: ClassDetailArea,
    childRoutes: [
      {
        path: '/classes/:classId',
        component: ClassDetailGeneralInfo
      },
      {
        path: '/classes/:classId/report',
        component: ClassDetailReport
      },
      {
        path: '/classes/:classId/study',
        component: ClassDetailStudyArea,
        childRoutes: [
          {
            path: '/classes/:classId/study',
            component: ClassDetailStudy
          },
          {
            path: '/classes/:classId/study/course/:actCourseId',
            component: ClassProgramCourseDetail
          },
          {
            path: '/classes/:classId/study/grade',
            component: ClassDetailGradeActivities
          },
          {
            path: '/classes/:classId/study/attendance',
            component: ClassEventAttendance
          },
          {
            path: '/classes/:classId/study/quizzes/:qId',
            component: ClassQuiz
          },
          {
            path: '/classes/:classId/study/quizzes/:qId/execute',
            component: ClassQuizExecute
          },

          {
            path: '/classes/:classId/study/quizzes/:qId/review',
            component: ClassQuizReview
          },
          {
            path: '/classes/:classId/study/reflection/:reflectionId/review',
            component: ClassReflectionReview
          },
          {
            path: '/classes/:classId/study/assignments/:assignmentId',
            component: ClassDetailStudyAssignment
          },
          {
            path: '/classes/:classId/study/assignments/:assignmentId/review',
            component: ClassDetailStudyAssignmentReview
          },
          {
            path: '/classes/:classId/study/assignments/:assignmentId/grade',
            component: ClassDetailStudyAssignmentGrade
          },
          {
            path: '/classes/:classId/study/quiz/:quizId',
            component: ClassDetailStudyQuiz,
            childRoutes: [
              {
                path: '/classes/:classId/study/quiz/:quizId/summary',
                component: ClassDetailStudyQuizSummary
              },
              {
                path: '/classes/:classId/study/quiz/:quizId/review',
                component: ClassDetailStudyQuizReview
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/admin',
    component: Administration,
    requiredRoles: { [ROLES.ADMIN_PAGE_ACCESS]: true },
    childRoutes: [
      {
        path: '/admin/bug-report',
        component: LearningSpaceBugReports,
        requiredRoles: { [ROLES.ISSUE_ADMIN]: true },
        title: TITLE_SIDEBAR.BUG_REPORT
      },
      { path: '/admin', component: AdminDashboard, title: TITLE_SIDEBAR.HOME },
      {
        path: '/admin/student/add',
        component: AddNewStudent,
        requiredRoles: { [ROLES.USER_ADMIN]: true },
        title: TITLE.ADD_STUDENT
      },
      {
        path: '/admin/question-banks/:id/import-questions',
        component: AddNewQuestionBank,
        requiredRoles: { [ROLES.QUESTION_BANK_ADMIN]: true },
        title: TITLE.IMPORT_QUESTION
      },
      // { path: '/admin/report', component: LearningSpaceReport },
      {
        path: '/admin/report/class',
        component: AdminReportClass,
        requiredRoles: {
          [ROLES.CLASS_REPORT_ACCESS]: true,
          [ROLES.REPORT_FULL_ACCESS]: true
        },
        title: TITLE_SIDEBAR.REPORT_CLASS
      },
      {
        path: '/admin/report/statistic-teaching-hours',
        component: StatisticTeachingHours,
        requiredRoles: {
          [ROLES.CLASS_REPORT_ACCESS]: true,
          [ROLES.REPORT_FULL_ACCESS]: true
        },
        title: TITLE_SIDEBAR.STATISTIC_TEACHING_HOUR
      },
      {
        path: '/admin/report/compare-classes',
        component: AdminReportClassCompare,
        requiredRoles: {
          [ROLES.CLASS_REPORT_ACCESS]: true,
          [ROLES.REPORT_FULL_ACCESS]: true
        },
        title: TITLE_SIDEBAR.COMPARE_CLASSES
      },
      {
        path: '/admin/report/student-grade-report',
        component: ClassStudentModuleGradeReport,
        title: TITLE_SIDEBAR.STUDENT_GRADE_REPORT
      },
      {
        path: '/admin/report/workflow-report',
        component: WorkflowReport,
        requiredRoles: { [ROLES.REPORT_FULL_ACCESS]: true },
        title: TITLE_SIDEBAR.WORKFLOW_REPORT
      },
      {
        path: '/admin/certification-templates',
        component: CertificateTemplateLists,
        requiredRoles: { [ROLES.CERTIFICATION_ADMIN]: true },
        title: TITLE_SIDEBAR.CERTIFICATION_TEMPLATES
      },
      {
        path: '/admin/certification-templates/add',
        component: CertificateTemplateAdd,
        requiredRoles: { [ROLES.CERTIFICATION_ADMIN]: true },
        title: TITLE.ADD_CERTIFICATION_TEMPLATE
      },
      {
        path: '/admin/question-banks',
        component: QuestionBanks,
        requiredRoles: { [ROLES.QUESTION_BANK_ADMIN]: true },
        title: TITLE_SIDEBAR.QUESTION_BANK
      },
      {
        path: '/admin/question-banks/:qbId',
        component: QuestionBankDetail,
        requiredRoles: { [ROLES.QUESTION_BANK_ADMIN]: true }
      },
      {
        path: '/admin/question-banks/:qbId/questions/:qId',
        component: QuestionDetail,
        requiredRoles: { [ROLES.QUESTION_BANK_ADMIN]: true }
      },
      {
        path: '/admin/question-banks/:qbId/add-question',
        component: LearningSpaceAddQuestion,
        requiredRoles: { [ROLES.QUESTION_BANK_ADMIN]: true },
        title: TITLE.ADD_QUESTION
      },
      {
        path: '/admin/question-banks/:qbId/edit-question/:questionId',
        component: LearningSpaceEditQuestion,
        requiredRoles: { [ROLES.QUESTION_BANK_ADMIN]: true },
        title: TITLE.EDIT_QUESTION
      },
      {
        path: '/admin/students',
        component: Students,
        requiredRoles: { [ROLES.USER_ADMIN]: true },
        title: TITLE_SIDEBAR.STUDENT
      },
      {
        path: '/admin/student/:id',
        component: LearningSpaceStudentDetail
      },
      {
        path: '/admin/courses',
        component: LearningSpaceCourses,
        requiredRoles: { [ROLES.COURSE_ADMIN]: true },
        title: TITLE_SIDEBAR.COURSE
      },
      {
        path: '/admin/lectures/create',
        component: AddNewLecture,
        requiredRoles: { [ROLES.LESSON_ADMIN]: true },
        title: TITLE.ADD_LECTURE
      },
      {
        path: '/admin/lectures/:lid',
        component: LectureDetail,
        requiredRoles: { [ROLES.LESSON_ADMIN]: true }
      },
      {
        path: '/admin/lectures/:lid/edit',
        component: EditLecture,
        requiredRoles: { [ROLES.LESSON_ADMIN]: true },
        title: TITLE.EDIT_LECTURE
      },
      {
        path: '/admin/lectures/:lid/addAct',
        component: AddActivity,
        requiredRoles: { [ROLES.LESSON_ADMIN]: true }
      },
      {
        path: '/admin/lectures/:lid/act/:actId/edit',
        component: EditActivity,
        requiredRoles: { [ROLES.LESSON_ADMIN]: true }
      },
      {
        path: '/admin/explore/:id',
        component: LearningSpaceCoursesExplore
      },
      {
        path: '/admin/classes',
        component: LearningSpaceClasses,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true },
        title: TITLE_SIDEBAR.CLASS
      },
      {
        path: '/admin/custom-courses',
        component: CustomCourses,
        title: TITLE_SIDEBAR.CUSTOM_COURSE
      },
      {
        path: '/admin/create-custom-course',
        component: CreateCustomCourse,
        requiredRoles: { [ROLES.COURSE_ADMIN]: true },
        title: TITLE.CREATE_COURSE
      },
      {
        path: '/admin/classes/create',
        component: LearningSpaceClassesCreate,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true },
        title: TITLE.CREATE_CLASS
      },
      {
        path: '/admin/classes/:id/edit',
        component: LearningSpaceClassEdit,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addCourse',
        component: LSClassDetailStudyAddCourse,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addLesson',
        component: LSClassDetailStudyAddLesson,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addAssignment',
        component: LSClassDetailStudyAddAssignment,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/assignments/:assignmentId/edit',
        component: LSClassDetailStudyEditAssignment,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/externalLink/:externalLinkId/edit',
        component: LSClassDetailStudyEditExternalLink,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addExternalLink',
        component: LSClassDetailStudyAddExternalLink,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addAttachment',
        component: LSClassDetailStudyAddAttachment,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addEvent',
        component: LearningSpaceClassAddEvent,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addQuiz',
        component: LearningSpaceClassAddQuiz,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/addSurvey',
        component: LSProgramSectionSurveyAct,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/survey/:surveyLinkId/edit',
        component: LSClassDetailEditSurvey,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/attachments/:aId/edit',
        component: LSProgramAssignmentEdit,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id/study/events/:aId/edit',
        component: LearningSpaceClassEditEvent,
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/classes/:id',
        component: LearningSpaceClassDetail,
        childRoutes: [
          {
            path: '/admin/classes/:id/configure',
            component: LSClassFromSchemeConfigure
          },
          {
            path: '/admin/classes/:id/people',
            component: LearningSpaceClassDetailPeople
          },
          {
            path: '/admin/classes/:id/people/import',
            component: ImportClassPeople
          },
          {
            path: '/admin/classes/:id/people/add',
            component: LearningSpaceClassDetailStudentsAdd
          },

          {
            path: '/admin/classes/:id/courses/add',
            component: LearningSpaceClassDetailCoursesAdd
          },
          {
            path: '/admin/classes/:id/study',
            component: LearningSpaceClassDetailStudy
          },
          {
            path: '/admin/classes/:id/checklists',
            component: LearningSpaceClassChecklists
          },
          {
            path: '/admin/classes/:id/checklists/add-checklist',
            component: LearningSpaceClassAddChecklist
          },
          {
            path: '/admin/classes/:id/checklists/:checkListId',
            component: LearningSpaceClassCheckListDetail
          },
          {
            path: '/admin/classes/:id/diligence',
            component: LearningSpaceClassDetailDiligence
          },
          {
            path: '/admin/classes/:id/diligence/:diligenceId/badges/create',
            component: LearningSpaceClassCreateBadge
          },
          {
            path: '/admin/classes/:id/settings',
            component: LearningSpaceClassSettings
          },
          {
            path: '/admin/classes/:id/expense',
            component: LearningSpaceClassExpense
          },
          {
            path: '/admin/classes/:id/feedbacks',
            component: LearningSpaceClassFeedbacks
          },
          {
            path: '/admin/classes/:id/study/course/:courseActivityId/settings',
            component: ClassCourseSettings
          },
          {
            path: '/admin/classes/:id/study/course/:courseActivityId/edit',
            component: EditCourseSettings
          },
          {
            path: '/admin/classes/:id/study/course/:courseActivityId/section/:courseSectionId/settings',
            component: ClassCourseSectionSettings
          },
          {
            path: '/admin/classes/:id/study/section/:sectionId/settings',
            component: ClassSectionSettings
          }
        ],
        requiredRoles: { [ROLES.CLASS_ADMIN]: true }
      },
      {
        path: '/admin/certification-exams',
        component: CertificationExams,
        requiredRoles: { [ROLES.CERTIFICATION_ADMIN]: true },
        title: TITLE_SIDEBAR.CERTIFICATION
      },
      {
        path: '/admin/certification-exams/:id',
        component: CertificationExamDetail,
        requiredRoles: { [ROLES.CERTIFICATION_ADMIN]: true }
      },
      {
        path: '/admin/certification-exams/:id/import-users',
        component: CertificationExamImportUsers,
        requiredRoles: { [ROLES.CERTIFICATION_ADMIN]: true },
        title: TITLE.CREATE_EXAM
      },
      {
        path: '/admin/certification-exams/:id/import-certified',
        component: CertificationExamImportCertified,
        requiredRoles: { [ROLES.CERTIFICATION_ADMIN]: true },
        title: TITLE.IMPORT_CERTIFIED
      },

      {
        path: '/admin/course/:courseId/import',
        component: LearningSpaceCourseImportUsers
      },
      {
        path: '/admin/course/:courseId/addStudent',
        component: AddStudentIntoCourse
      },
      {
        path: '/admin/lectures',
        component: Lectures,
        requiredRoles: { [ROLES.LESSON_ADMIN]: true },
        title: TITLE_SIDEBAR.LECTURE
      },
      {
        path: '/admin/course/:id',
        component: LearningSpaceCourseDetail,
        requiredRoles: { [ROLES.COURSE_ADMIN]: true },
        childRoutes: [
          {
            path: '/admin/course/:id',
            component: LearningSpaceCourseDetailContent
          },
          {
            path: '/admin/course/:id/students',
            component: LearningSpaceCourseDetailStudents
          },
          {
            path: '/admin/course/:id/progress',
            component: LearningSpaceCourseProgress
          },
          {
            path: '/admin/course/:id/certificate',
            component: LearningSpaceCourseCertificate
          },
          {
            path: '/admin/course/:id/quizzes',
            component: LearningSpaceCourseQuizzes
          },

          {
            path: '/admin/course/:id/quizzes/:quizId',
            component: LearningSpaceCourseQuiz,
            childRoutes: [
              {
                path: '/admin/course/:id/quizzes/:quizId/summary',
                component: LearningSpaceCourseQuizSummary
              },
              {
                path: '/admin/course/:id/quizzes/:quizId/review',
                component: LearningSpaceCourseQuizDetail
              },
              {
                path: '/admin/course/:courseId/quizzes/:quizId/review/student/:ecId/submissions',
                component: StudentCourseQuizSubmissions
              },
              {
                path: '/admin/course/:courseId/quizzes/:quizId/review/student/:ecId/submissions/:submissionId',
                component: StudentCourseQuizSubmissionDetail
              }
            ]
          }
        ]
      },
      {
        path: '/admin/explore',
        component: AdminExploreCourses,
        title: TITLE_SIDEBAR.EXPLORE
      },
      { path: '/admin/docs/:guideline', component: Guidelines },
      {
        path: '/admin/settings',
        component: LearningSpaceSettings,
        requiredRoles: { [ROLES.ADMIN]: true }
      },
      {
        path: '/admin/custom-courses/:id/',
        component: LearningSpaceCustomCourseDetail
      },
      {
        path: '/admin/custom-courses/:id/section/:sectionId/addLecture',
        component: LearningSpaceCustomCourseAddLecture,
        title: TITLE.ADD_LECTURE
      },
      {
        path: '/admin/custom-courses/:id/section/:sectionId/addQuiz',
        component: LearningSpaceCustomCourseAddQuiz,
        title: TITLE.ADD_QUIZ
      },
      {
        path: '/admin/teaching-hours-statistics',
        component: TeachingHoursStatistics
      }
    ]
  },
  { path: '/my-courses', component: MyCourses },
  {
    path: '/learn/:enrollId',
    component: Classroom,
    childRoutes: [
      {
        path: '/learn/:enrollId/lesson/:lessonId',
        component: ClassroomLessonDetail
      },
      {
        path: '/learn/:enrollId/quiz/:quizId',
        component: ClassroomQuizDetail
      },
      {
        path: '/learn/:enrollId/quiz/:quizId/execute',
        component: ExecuteQuiz
      },
      {
        path: '/learn/:enrollId/quiz/:quizId/review/:submissionId',
        component: ClassroomQuizReview
      }
    ]
  },
  { path: '/my-course/:id', component: MyCourseDetail },
  { path: '/redirect-to-classroom', component: RedirectToClassroomFromClass },
  {
    path: '/certification-exam/:id/execute',
    component: CertificationExamExecute
  },
  {
    path: '/certification-exam/:id/result',
    component: CertificationExamResult
  }
]

export default routing
