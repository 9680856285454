import { CourseActivityType, EnrolledQuizType, LessonResultType } from 'types'

export function checkActivityCompleted(
  activities: (CourseActivityType & {
    status?: 'completed' | 'in_complete' | undefined
    completed?: 'completed' | 'in_complete'
  })[],
  lessonResults: { [key: string]: LessonResultType },
  quizResults: { [k: string]: EnrolledQuizType }
) {
  activities.forEach((act) => {
    const isLecture = act.type === 'LECTURE'
    const actId = act.id as string
    const isQuiz = act.type === 'QUIZ'
    if (isLecture) {
      const lessonResult = lessonResults[actId]
      if (lessonResult && lessonResult.state === 'COMPLETED') {
        act.status = 'completed'
      } else if (lessonResult && lessonResult.state === 'INCOMPLETE') {
        act.status = 'in_complete'
      } else {
        act.status = undefined
      }
    } else if (isQuiz) {
      const result = quizResults[actId] as EnrolledQuizType
      act.completed =
        result && result.state === 'PASSED' ? 'completed' : 'in_complete'
    }
  })

  return activities
}

export function validateLecture(input: any): { [key: string]: string } {
  let error: { [key: string]: string } = {}
  if (!input.title) {
    error.title = 'Tiêu đề không được để trống!'
  }
  if (
    !input.icon ||
    ['media', 'practice', 'document'].indexOf(input.icon) < 0
  ) {
    error.icon = 'Biểu tượng không hợp lệ!'
  }
  return error
}
