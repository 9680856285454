import { Box, Container } from '@material-ui/core'
import LectureForm from 'components/LectureForm'
import PageHeaderAndBackBtn from 'components/PageHeaderAndBackBtn'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import firebase, { firestore } from 'firebase'
import { useQueryParams } from 'hooks'
import _, { pick } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFirestore, useUser } from 'reactfire'
import { LectureIconType } from 'types'
import { validateLecture } from 'utils'
import { fromDurationToSecond } from 'utils/StringFormatter'
import { useLoadTags } from 'hooks/useLoadTags'
import { addTags, selectTags } from 'reducers/redux/tags'
interface IProps {}

export const reformatTagAuthocompleteValue = (
  selectedTags: {
    id: string
    display: string
    needCreation?: boolean
    inputValue?: string
  }[]
) => {
  return selectedTags.map((tag) => {
    if (typeof tag === 'string')
      return {
        id: '',
        display: tag,
        inputValue: tag,
        needCreation: true
      }

    return tag
  })
}

export const createTags = (
  selectedTags: {
    id: string
    display: string
    needCreation?: boolean
    inputValue?: string
  }[],
  learningSpaceRef: firestore.DocumentReference,
  batch: firestore.WriteBatch
) => {
  const needCreationTags = selectedTags.filter((tag) => tag.needCreation)

  const spaceTagCollection = learningSpaceRef.collection('tags')

  needCreationTags.forEach((tag) => {
    const doc = spaceTagCollection.doc()
    batch.set(doc, {
      display: tag.inputValue
    })

    tag.id = doc.id
    tag.display = tag.inputValue || ''
  })

  return needCreationTags
}

export const getMapOfTags = (
  tags: { id: string; display: string; needCreation?: boolean }[]
) => {
  return tags.reduce<{ [k: string]: boolean }>((mt, tag) => {
    mt[tag.id] = true
    return mt
  }, {})
}

const AddNewLecture: React.FC<IProps> = () => {
  const queryParams = useQueryParams()
  const sectionId = queryParams.get('section')
  const lspace = useLearningSpace()

  const [saving, setSaving] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [brief, setBrief] = useState<string>('')
  const [icon, setIcon] = useState<LectureIconType>('media')
  const [duration, setDuration] = useState<string>('')
  const [intro, setIntro] = useState<string>('')
  const [error, setError] = useState<{ [key: string]: string }>({})
  const db = useFirestore()
  const [selectedTags, setSelectedTags] = useState<
    { id: string; display: string; needCreation?: boolean }[]
  >([])
  const mapTags = useSelector(selectTags)
  const tags = useMemo(
    () => Object.values(mapTags).map((t) => pick(t, ['id', 'display'])),
    [mapTags]
  )

  useLoadTags()
  const history = useHistory()
  const authUser = useUser<firebase.User>()
  const dispatch = useDispatch()

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault()
    setSaving(!saving)
    const lecture = {
      title: _.trim(title),
      brief: brief,
      state: 'PUBLISHED',
      icon: icon,
      durations: fromDurationToSecond(duration),
      role: {
        [authUser.uid]: 'owner'
      },
      tags: {},
      intro: intro,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }
    const _e = validateLecture(lecture)

    if (!_.isEmpty(_e)) {
      setError(_e)
      setSaving(false)
    } else {
      const batch = db.batch()

      const saveTags = reformatTagAuthocompleteValue([...selectedTags])
      const newTags = createTags(saveTags, lspace._meta.ref, batch)

      dispatch(addTags(newTags))

      lecture.tags = getMapOfTags(saveTags)

      const lectureDocRef = lspace._meta.ref.collection('custom_lectures').doc()

      batch.set(lectureDocRef, lecture)

      batch
        .commit()
        .then(() => {
          history.push(
            `/admin/lectures/${lectureDocRef.id}?section=${sectionId}`
          )
        })
        .catch((err) => {
          alert(err.message)
          console.error(err)
        })
        .finally(() => {
          setSaving(false)
        })
    }
  }

  return (
    <>
      <Container maxWidth="md">
        <Box mt={3} minHeight={500}>
          <PageHeaderAndBackBtn
            backTo="/admin/lectures"
            label="Thêm bài học"
            backTooltipLabel="Danh sách bài học"
          />

          <Box>
            <LectureForm
              brief={brief}
              duration={duration}
              title={title}
              error={error}
              handleSave={handleSave}
              icon={icon}
              intro={intro}
              saving={saving}
              setBrief={(brief) => setBrief(brief)}
              setDuration={(duration) => setDuration(duration)}
              setIcon={(icon) => setIcon(icon)}
              setIntro={(intro) => setIntro(intro)}
              setTitle={(title) => setTitle(title)}
              selectedTags={selectedTags}
              tags={tags as any}
              setSelectedTags={setSelectedTags}
              variant="create"
            />
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default AddNewLecture
