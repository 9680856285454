import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { firestore } from 'firebase'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getActiveClasses,
  getAttendanceReportData,
  getCourseStatistic,
  getCourseStatisticDataInClass,
  getOrderedGradableActivities,
  getOrderedClassCourses,
  getOrderedSections,
  getReportGrades,
  IGetAttendanceReportDataResult,
  IGetReportGradesResult,
  getClassStudentModuleGrade
} from 'utils/LearningSpaceClassUtils'
import { useSuspense } from './useSuspense'
import {
  ClassReflectionSubmission,
  CourseActivityType,
  LearningSpaceClass,
  LearningSpaceClassUser,
  LearningSpaceCourseStatisticsType,
  LearningSpaceProgramActivity,
  LearningSpaceProgramSection,
  LSProgramCourseActivity
} from 'types'
import { IFirestoreMetadata } from 'interfaces'
import { useCreatePagination } from 'utils/PaginationUtils'
import {
  useAssocCollectionData,
  useCollectionData,
  useDocumentData,
  useQueryData
} from '.'
import { getCollectionData } from 'utils/FirestoreUtils'
import { useFirestore } from 'reactfire'

export const useAttendanceReportData = (classId: string, limit: number) => {
  const uniqueKey = `useAttendanceReportData:classId:${classId}:limit${limit}`
  const space = useLearningSpace()
  const classRef = useMemo(
    () => space._meta.ref.collection('classes').doc(classId),
    [space._meta.ref, classId]
  )

  const fetcher = useCallback(
    (pageToken: null | string = null) =>
      getAttendanceReportData(classRef, pageToken, limit, ['email', 'asc']),
    [classRef, limit]
  )

  const data = useSuspense<IGetAttendanceReportDataResult[]>(uniqueKey, fetcher)

  return useCreatePagination(data, limit, fetcher)
}

export const useGradeReport = (classId: string, limit: number) => {
  const uniqueKey = `useGradeReport:classId:${classId}:limit${limit}`
  const space = useLearningSpace()

  const classRef = useMemo(
    () => space._meta.ref.collection('classes').doc(classId),
    [space._meta.ref, classId]
  )

  const fetcher = useCallback(
    (pageToken: null | string = null) =>
      getReportGrades(classRef, pageToken, limit, ['email', 'asc']),
    [classRef, limit]
  )

  const data = useSuspense<IGetReportGradesResult[]>(uniqueKey, fetcher)

  return useCreatePagination(data, limit, fetcher)
}

export const useOrderedGradableActivities = (
  programRef: firestore.DocumentReference
) => {
  const uniqueKey = `useOrderedActivities.${programRef.path}`
  return useSuspense<(LearningSpaceProgramActivity & IFirestoreMetadata)[]>(
    uniqueKey,
    () => getOrderedGradableActivities(programRef),
    []
  )
}

export const useOrderedSections = (programRef: firestore.DocumentReference) => {
  const uniqueKey = `useOrderedSections.${programRef.path}`
  return useSuspense<(LearningSpaceProgramSection & IFirestoreMetadata)[]>(
    uniqueKey,
    () => getOrderedSections(programRef),
    []
  )
}

export const useOrderedClassCourses = (
  programRef: firestore.DocumentReference
) => {
  const uniqueKey = `useOrderedClassCourses.${programRef.path}`
  return useSuspense<(LSProgramCourseActivity & IFirestoreMetadata)[]>(
    uniqueKey,
    () => getOrderedClassCourses(programRef),
    []
  )
}

export const useCourseStatistic = (
  learningSpaceRef: firestore.DocumentReference,
  courseId: string
) => {
  const uniqueKey = `useCourseStatistic.${learningSpaceRef.path}.${courseId}`
  return useSuspense<
    (LearningSpaceCourseStatisticsType & IFirestoreMetadata)[]
  >(uniqueKey, () => getCourseStatistic(learningSpaceRef, courseId), [])
}

export const useCourseStatisticDataInClass = (
  learningSpaceRef: firestore.DocumentReference,
  courseId: string | null,
  classId: string | null
) => {
  const uniqueKey = `useCourseStatisticInClass.${learningSpaceRef.path};courseId:${courseId};classId:${classId}`
  return useSuspense<{
    progresses: (LearningSpaceCourseStatisticsType & IFirestoreMetadata)[]
    activities: CourseActivityType[]
  }>(
    uniqueKey,
    () => getCourseStatisticDataInClass(learningSpaceRef, classId, courseId),
    []
  )
}

export const useLoadmoreActiveClasses = (
  spaceRef: firestore.DocumentReference,
  limit: number
) => {
  const uniqueKey = `useLoadmoreActiveClasses.${spaceRef.path};limit:${limit}`

  const fetcher = useCallback(
    async (pageToken: null | string = null) =>
      getActiveClasses(spaceRef, pageToken, limit, [
        'timeTracks.createdAt',
        'desc'
      ]),
    [limit, spaceRef]
  )
  const firstGet = useSuspense<(LearningSpaceClass & IFirestoreMetadata)[]>(
    uniqueKey,
    fetcher
  )

  const [state, setState] = useState<{
    loading: boolean
    data: (LearningSpaceClass & IFirestoreMetadata)[]
    lastId: string | null
  }>({
    data: firstGet,
    lastId: firstGet[firstGet.length - 1]?.id || null,
    loading: false
  })

  const more = useCallback(() => {
    setState((s) => ({ ...s, loading: true }))
    fetcher(state.lastId).then((r) => {
      setState((s) => ({
        ...s,
        data: [...s.data, ...r],
        lastId: r[r.length - 1]?.id || null,
        loading: false
      }))
    })
  }, [state.lastId, fetcher])

  return {
    loading: state.loading,
    more,
    data: state.data,
    canMore: !!state.lastId
  }
}

export const useClassActivity = <T extends Object>(
  classRef: firestore.DocumentReference,
  programId: string,
  activityId: string
) => {
  const ref = classRef
    .collection('programs')
    .doc(programId)
    .collection('activities')
    .doc(activityId)

  return useDocumentData<T>(ref, { idField: 'id' })
}

export const useClassReflectionSubmissions = (
  classRef: firestore.DocumentReference,
  reflectionRef: firestore.DocumentReference
) => {
  const collection = classRef
    .collection('reflection_submissions')
    .where('activityRef', '==', reflectionRef)
    .where('status', '!=', 'DRAFT')
    .orderBy('status', 'desc')
    .orderBy('timeTracks.updatedAt', 'desc')

  return useCollectionData<ClassReflectionSubmission>(collection, {
    idField: 'id'
  })
}

/**
 * Fetch active class users from firestore with suspense
 */
export const useActiveClassUsersWS = (
  classRef: firestore.DocumentReference
) => {
  const collection = classRef.collection('users').where('state', '==', 'ACTIVE')

  return useAssocCollectionData<LearningSpaceClassUser & IFirestoreMetadata>(
    collection,
    'id',
    { idField: 'id', initValue: null }
  )
}

/**
 * Fetch all active class students from firestore without suspense
 */
export const useActiveClassStudents = (
  classRef?: firestore.DocumentReference
) => {
  const collection = classRef
    ?.collection('users')
    .where('roles.student', '==', true)
    .where('state', '==', 'ACTIVE')

  const uniqueId = `useActiveClassStudents:classes:${classRef?.id}`

  const [state, setState] = useState<
    (LearningSpaceClassUser & IFirestoreMetadata)[]
  >([])

  useEffect(() => {
    if (collection) {
      getCollectionData<LearningSpaceClassUser>(collection, { idField: 'id' })
        .then(setState)
        .catch((e) => console.error(e.message))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId])

  return state
}

export const useStudentModuleGradeStatistics = (
  learningSpaceId: string,
  classId: string
) => {
  const db = useFirestore()
  const uniqueId = `useStudentModuleGradeStatistics:learningSpaceId:${learningSpaceId}:classes:${classId}`

  return useQueryData(uniqueId, () =>
    getClassStudentModuleGrade(db, learningSpaceId, classId)
  )
}

export const useClassSections = (
  classInfo: LearningSpaceClass & IFirestoreMetadata
) => {
  const programRef = classInfo._meta.ref
    .collection('programs')
    .doc(classInfo.programId as string)
  const sectionsRef = programRef
    .collection('sections')
    .where('state', '==', 'PUBLISHED')
    .orderBy('ordering', 'asc')
  const sections = useCollectionData<LearningSpaceProgramSection>(sectionsRef, {
    idField: 'id'
  })
  return sections
}

export const useClassSectionsAssoc = (
  classInfo: LearningSpaceClass & IFirestoreMetadata
) => {
  const programRef = classInfo._meta.ref
    .collection('programs')
    .doc(classInfo.programId as string)
  const sectionsRef = programRef
    .collection('sections')
    .where('state', '==', 'PUBLISHED')
    .orderBy('ordering', 'asc')
  const sections = useAssocCollectionData<
    LearningSpaceProgramSection & IFirestoreMetadata
  >(sectionsRef, 'id', {
    idField: 'id'
  })
  return sections
}
