import React from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

interface IProps {
  fields: string[]
  numberRow: number
}

const TableSkeleton: React.FC<IProps> = (props) => {
  const { fields, numberRow } = props
  const renderSkeletonBody = () => {
    let listSkeleton = []
    for (let i = 0; i < numberRow; i++) {
      listSkeleton.push(
        <TableRow key={i}>
          {fields.map((field, index) => (
            <TableCell key={index}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      )
    }
    return listSkeleton
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {fields.map((field, index) => (
                <TableCell key={index} align="left">
                  {field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderSkeletonBody()}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TableSkeleton
