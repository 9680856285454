import XLSX, { Range } from 'xlsx'
import { sheetToObjects } from './Array'

export const downloadExampleFile = async (headers: string[]) => {
  const wb = XLSX.utils.book_new()

  const ws_name = `Học viên thi chứng chỉ`

  /* make worksheet */
  const ws_data = [headers]

  const ws = XLSX.utils.aoa_to_sheet(ws_data)
  XLSX.utils.book_append_sheet(wb, ws, ws_name)
  await XLSX.writeFile(wb, `${ws_name}.xlsx`, {
    type: 'array',
    bookType: 'xlsx'
  })
}

export const exportCsv = async (
  title: string,
  rows: string[][],
  merges?: Range[]
) => {
  const wb = XLSX.utils.book_new()

  /* make worksheet */

  const ws = XLSX.utils.aoa_to_sheet(rows)
  ws['!merges'] = merges
  XLSX.utils.book_append_sheet(wb, ws, title)
  await XLSX.writeFile(wb, `${title}.xlsx`, {
    type: 'array',
    bookType: 'xlsx'
  })
}

export const readCsv = async (
  file: File,
  cb: (data: { [key: string]: any }[]) => void
) => {
  const fileReader = new FileReader()

  fileReader.onload = async (result) => {
    const bstr = result.target?.result
    const workbook = XLSX.read(bstr, { type: 'binary' })
    const wsname = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[wsname]
    const data = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      rawNumbers: true,
      blankrows: true
    })
    await cb(sheetToObjects(data as string[][]))
  }

  fileReader.readAsBinaryString(file)
}
