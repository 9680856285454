import { IFirestoreMetadata } from 'interfaces'
import {
  LearningSpaceProgramActivity,
  LearningSpaceProgramSection
} from 'types'

export function filterActsInProgramSections(
  section: LearningSpaceProgramSection & IFirestoreMetadata,
  activities: (LearningSpaceProgramActivity & IFirestoreMetadata)[]
) {
  return activities.filter((act) => act.sectionRef.id === section.id)
}

export const groupProgramSectionsWithActivities = (
  sections: (LearningSpaceProgramSection & IFirestoreMetadata)[],
  activities: (LearningSpaceProgramActivity & IFirestoreMetadata)[]
) => {
  const tempActivities = activities
  return sections.map((section) => {
    let actsInSection = filterActsInProgramSections(section, tempActivities)
    actsInSection = actsInSection.sort(
      (prev, af) => prev.ordering - af.ordering
    )
    section.activities = actsInSection
    return section
  })
}

export const parseGradeString = (grade: number) => {
  if (grade % 1 === 0) {
    return grade.toString()
  } else {
    return grade.toFixed(1)
  }
}
