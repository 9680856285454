import { createContext, useState, useCallback, useContext } from 'react'
import { Color } from '@material-ui/lab'

import { ToastType } from 'types'

export const SnackbarContext = createContext<{
  toast: ToastType
  showToastMessage: (message: string, severity?: Color, timeout?: number) => void
  closeToast: () => void
}>({
  toast: { message: '', severity: undefined, timeout: 0 },
  showToastMessage: () => {},
  closeToast: () => {}
})

export function useToast() {
  const [toast, setToast] = useState<ToastType>({
    message: '',
    severity: undefined,
    timeout: 0
  })

  const showToastMessage = useCallback(
    (message: string, severity: Color = 'success', timeout = 0) => {
      setToast({ message, severity, timeout  })
    },
    []
  )
  const closeToast = useCallback(() => {
    setToast({ message: '', severity: undefined, timeout: 0 })
  }, [])

  return {
    toast,
    showToastMessage,
    closeToast
  }
}

export const useSnackbar = () => {
  const snackbar = useContext(SnackbarContext)

  return snackbar.showToastMessage
}
