import { configureStore } from '@reduxjs/toolkit'
import ClassSections from 'reducers/redux/programActivities'
import spaceUserRoles from 'reducers/redux/spaceUserRoles'
import classStudents from 'reducers/redux/classStudents'
import tags from './reducers/redux/tags'
// ...

const store = configureStore({
  reducer: {
    programActivities: ClassSections,
    classStudents: classStudents,
    spaceUserRoles: spaceUserRoles,
    tags: tags
  }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
