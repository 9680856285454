import {  Box, CardContent, Container, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import PageHeader from '../../components/PageHeader'
import { useLearningSpace } from '../../contexts/LearningSpaceContext'
import { useCollectionData } from '../../hooks'
import { LearningSpaceCertificateTemplate } from '../../types'
import ButtonLink from '../../components/ButtonLink'
import { Add } from '@material-ui/icons'

interface IProps {}

const Certificate: React.FC<{certificate: LearningSpaceCertificateTemplate}> = ({certificate}) => {
  return <Grid item xs={12}>
        <Paper elevation={0}>
          <CardContent>
            <Box style={{gap: 8}} display="flex" flexDirection="row">
            <img style={{width: 100}} src={certificate.source} alt={certificate.title}  />

            <Typography variant="h6">{certificate.title}</Typography>
          </Box>
          </CardContent>
        </Paper>
</Grid>
}
 
const Certificates: React.FC<{}> = () => {

  const learningSpace  = useLearningSpace()

  const query = learningSpace._meta.ref.collection('certificate_templates').orderBy('timeTracks.createdAt', 'desc')

  const certificates = useCollectionData<LearningSpaceCertificateTemplate>(query, {idField: 'id'})

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box display={"flex"} justifyContent={"flex-end"}>
      <ButtonLink to="/admin/certification-templates/add" startIcon={<Add />} color="primary" > Thêm mẫu chứng chỉ</ButtonLink>
      </Box>
    </Grid>
    {certificates.map((certificate) => <Certificate certificate={certificate} />)}
  </Grid>
}

const CertificateTemplateLists: React.FC<IProps> = () => {

  return (
    <>
      <Container maxWidth="xl">
          <PageHeader title={"Danh sách mẫu chứng chỉ"} />

          <Certificates />
      </Container>
    </>
  )
}

export default CertificateTemplateLists
