import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import UploadFiles from 'components/UploadFiles'
import { downloadExampleFile, readCsv } from 'utils/XLSXUtils'
import TextFieldDebounce from 'components/TextFieldDebounce'
import { isEmpty } from 'lodash'
import CreatableAutocomplete from 'components/CreatableAutocomplete'
import { useExamSessionPublished } from 'utils/ExamUtil'
import { useStateChanged } from 'hooks/useStateChanged'
import ImportDescription from 'components/ImportDescription'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
interface IProps {
  classes: { [key: string]: string }
  setActiveStep: (step: number) => void
  setUploadData: (data: any[]) => void
  step: number
  config: {
    startAt: undefined | Date
    finishAt: undefined | Date
  }
  changeConfig: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void
  examSession: null | string | { inputValue: string }
  onChangeExamSession: (d: string | { inputValue: string }) => void
}

function validateConfig(
  data: { [key: string]: any },
  examSession: null | string | { [key: string]: any }
) {
  const errors: { [key: string]: any } = {}
  const now = new Date()

  if (!examSession) {
    errors.examSession = 'Vui lòng chọn một kỳ thi.'
  } else {
    if (typeof examSession === 'object' && !examSession.inputValue) {
      errors.examSession = `Chọn + Tạo mới nếu chưa có kỳ thi này`
    }
  }

  if (data.finishAt) {
    if (new Date(data.finishAt) < now) {
      errors.finishAt = 'Bài kiểm tra phải kết thúc sau thời gian hiện tại!'
    }
    if (data.startAt) {
      if (data.startAt >= data.finishAt) {
        errors.startAt = 'Thời gian bắt đầu phải trước thời gian kết thúc!'
      }
    }
  }

  return errors
}

const CEImportUsersConfigStep: React.FC<IProps> = ({
  classes,
  setActiveStep,
  setUploadData,
  step,
  config,
  changeConfig,
  examSession,
  onChangeExamSession
}) => {
  const [errors, setErrors] = useState<{ [key: string]: any }>({})
  const examSessions = useExamSessionPublished()
  const space = useLearningSpace()
  const customFields = useMemo(
    () => space.userCustomFields || {},
    [space.userCustomFields]
  )

  const handleDownloadExampleFile = useCallback(async () => {
    await downloadExampleFile([
      'email',
      'name',
      'password',
      ...Object.values(customFields).map((f) => f.key)
    ])
  }, [customFields])

  const handleUploadFile = useCallback(
    async (files: FileList) => {
      const $errs = validateConfig(config, examSession)
      setErrors($errs)

      if (isEmpty($errs) && files && files[0]) {
        await readCsv(files[0], async (data) => {
          setUploadData(data as any[])
          setActiveStep(1)
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, examSession]
  )

  useStateChanged(
    () => setErrors(validateConfig(config, examSession)),
    [config, examSession]
  )

  if (step !== 0) return null

  return (
    <>
      <Grid item xs={12} md={5} lg={4}>
        <CreatableAutocomplete
          error={errors.examSession}
          onSelect={(d) => {
            if (!d) {
              onChangeExamSession(d)
            } else {
              const { id, inputValue } = d
              if (!!id) {
                onChangeExamSession(id)
              } else {
                onChangeExamSession({ inputValue: inputValue as string })
              }
            }
          }}
          options={examSessions.map((e) => ({
            id: e.id as string,
            label: e.name
          }))}
          value={examSession}
          label={'Kỳ thi'}
        />
      </Grid>
      <Box mt={3}>
        <Typography style={{ fontWeight: 'bold' }} variant={'h6'}>
          Cấu hình
        </Typography>
        <Box mt={2}>
          <Grid item xs={12} md={5} lg={4}>
            <Box className={classes.row}>
              <Typography>Bắt đầu</Typography>

              <TextFieldDebounce
                onChange={changeConfig}
                wait={500}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                  type: 'datetime-local',
                  name: 'startAt',
                  defaultValue: config.startAt,
                  style: { minWidth: 200 },
                  error: Boolean(errors.startAt),
                  helperText: errors.startAt
                }}
              />
            </Box>
            <Box className={classes.row}>
              <Typography>Kết thúc</Typography>

              <TextFieldDebounce
                onChange={changeConfig}
                wait={500}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                  type: 'datetime-local',
                  name: 'finishAt',
                  defaultValue: config.finishAt,
                  style: { minWidth: 200 },
                  error: Boolean(errors.finishAt),
                  helperText: errors.finishAt
                }}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box mt={3}>
        <ImportDescription importTypeTitle={'thí sinh thi chứng chỉ'} />
        <Box mt={2}>
          <Button onClick={handleDownloadExampleFile}>
            <Box display={'flex'} alignItems={'center'}>
              <GetApp />
              <Typography style={{ marginLeft: 5 }}>
                Tải xuống file mẫu
              </Typography>
            </Box>
          </Button>
        </Box>
        <Box mt={2}>
          <UploadFiles
            disabled={!isEmpty(errors)}
            onChange={handleUploadFile}
            accept={['.xlsx']}
            buttonTitle={'Tải lên danh sách'}
          />
        </Box>
      </Box>
    </>
  )
}
export default CEImportUsersConfigStep
