import React, { useEffect, useRef } from 'react'

export const useStateChanged = (cb: React.EffectCallback, deps: any[]) => {
  const init = useRef(true)

  useEffect(() => {
    if (init.current) {
      init.current = false
    } else {
      return cb()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
