import { useFirestore } from 'reactfire'
import { useCollectionData } from 'hooks'
import { ExamSession } from 'types'

export const useExamSessionPublished = () => {
  const db = useFirestore()
  const query = db
    .collection('exam_sessions')
    .where('state', '==', 'PUBLISHED')
    .orderBy('timeTracks.createdAt', 'desc')

  return useCollectionData<ExamSession>(query, { idField: 'id' })
}
