import { Theme, createStyles } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    loadingCtn: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(10)
    }
  })
