import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  imageList: {
    width: 'auto',
    maxHeight: 50,
    borderRadius: 5
  },
  imageDialog: {
    width: '100%',
    height: '100%'
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: '#fff'
  }
}))
