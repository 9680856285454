import React from 'react'
import {
  Avatar,
  Box,
  CardContent,
  Divider,
  Paper,
  Typography
} from '@material-ui/core'
import LoadableButton from 'components/LoadableButton'
import { useTheme } from '@material-ui/core/styles'
import images from 'assets/images'
import config from 'configs'

interface IProps {
  user: firebase.User
  learningSpaceId: string
  loggingOut: boolean
  handleLogout: () => void
}

const WrongDomainNotifier: React.FC<IProps> = (props) => {
  const { user, learningSpaceId, loggingOut, handleLogout } = props

  const theme = useTheme()

  return (
    <Box textAlign={'center'}>
      <Box mb={3} textAlign={'center'}>
        <img
          style={{ height: theme.spacing(15) }}
          src={images.LOGO}
          alt={config.app.siteName}
        />
      </Box>
      <Paper elevation={0}>
        <CardContent>
          <Box mb={3}>
            <Typography variant={'h6'}>Thử một tài khoản khác</Typography>
          </Box>
          <Box
            textAlign={'center'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={3}
          >
            <Avatar
              style={{ width: theme.spacing(10), height: theme.spacing(10) }}
              src={user.photoURL || ''}
            />
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'start'}
              ml={2}
            >
              <Typography>{user.displayName}</Typography>
              <Typography>{user.email}</Typography>
            </Box>
          </Box>
          <Typography>
            <b>{user.email}</b> không có quyền truy cập không gian học tập{' '}
            <b>{learningSpaceId}</b>.
          </Typography>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <LoadableButton
            style={{ minWidth: 200 }}
            color={'primary'}
            loading={loggingOut}
            onClick={handleLogout}
          >
            Đăng xuất
          </LoadableButton>
        </CardContent>
      </Paper>
    </Box>
  )
}

export default WrongDomainNotifier
