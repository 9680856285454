import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import PageHeader from 'components/PageHeader'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import ErrorBlock from 'components/ErrorBlock'
import { useAssocReferenceCollectionData, useCollectionData } from 'hooks'
import { LearningSpaceClass, SchemeSectionType, SchemeType } from 'types'
import { IFirestoreMetadata } from 'interfaces'
import CourseCompletionRateComparisonTable from 'widgets/CourseCompletionRateComparisonTable'
import CourseCompletionRateComparisonBarChart from 'widgets/CourseCompletionRateComparisonBarChart'
import ReflectionCompletionRateTables from 'widgets/ReflectionCompletionRateTables'
import ReflectionCompletionRateComparisonBarCharts from 'widgets/ReflectionCompletionRateComparisonBarCharts'
import MyPaper from 'components/MyPaper'
import config from 'configs/class-compare-default.json'
import Loading from 'components/Loading'
import EventParticipationRateTable from 'widgets/EventParticipationRateTable'
import EventParticipationRateBarChart from 'widgets/EventParticipationRateBarChart'
import {
  useCourseStatistics,
  useEventStatistics,
  useReflectionStatistics
} from 'hooks/useClassCompareStatistics'
import { useSchemeSections } from 'hooks/useScheme'

const SUPPORTED_LEARNING_SPACE = config.supported_learning_space
const DEFAULT_SCHEME_ID = config.default_scheme_id

const AdminReportClassCompare: React.FC = () => {
  const learningSpace = useLearningSpace()
  const learningSpaceClassesRef = learningSpace._meta.ref
    .collection('classes')
    .where('status', '==', 'ACTIVE')
    .orderBy('timeTracks.createdAt', 'desc')

  const learningSpaceClasses = useCollectionData<LearningSpaceClass>(
    learningSpaceClassesRef,
    { idField: 'id' }
  )

  const learningSpaceSchemesRef = useMemo(
    () =>
      learningSpace._meta.ref
        .collection('schemes')
        .where('state', '==', 'PUBLISHED')
        .orderBy('timeTracks.createdAt', 'desc'),
    [learningSpace._meta.ref]
  )

  const filterScheme = useCallback(
    (doc) => doc.data()?.state === 'PUBLISHED',
    []
  )

  const { data: schemesObject } = useAssocReferenceCollectionData<SchemeType>(
    learningSpaceSchemesRef,
    {
      refField: 'schemeRef',
      filter: filterScheme
    }
  )

  const schemes = useMemo(() => Object.values(schemesObject), [schemesObject])

  const [chooseClassesDialogOpen, setChooseClassesDialogOpen] = useState(false)
  const [selectedClasses, setSelectedClasses] = useState<{
    [classId: string]: { selected: boolean; className: string; id: string }
  }>({})

  const setDefaultSelectedClasses = useCallback(() => {
    const firstFiveClasses = learningSpaceClasses.slice(0, 5)
    const defaultSelectedClasses: {
      [classId: string]: { selected: boolean; className: string; id: string }
    } = {}
    firstFiveClasses.forEach(
      (classInfo) =>
        (defaultSelectedClasses[classInfo.id as string] = {
          className: classInfo.name,
          selected: true,
          id: classInfo.id as string
        })
    )
    setSelectedClasses(defaultSelectedClasses)
  }, [learningSpaceClasses])

  const [scheme, setScheme] = useState<string>(DEFAULT_SCHEME_ID)

  useEffect(() => {
    setDefaultSelectedClasses()
  }, [setDefaultSelectedClasses])

  const handleSelectClass = (
    event: React.ChangeEvent<HTMLInputElement>,
    classInfo: LearningSpaceClass & IFirestoreMetadata
  ) => {
    setSelectedClasses((prevState) => {
      return {
        ...prevState,
        [classInfo.id as string]: {
          id: classInfo.id as string,
          className: classInfo.name,
          selected: event.target.checked
        }
      }
    })
  }

  const selectedClassesArray = useMemo(
    () => Object.values(selectedClasses).filter((item) => item.selected),
    [selectedClasses]
  )

  if (learningSpace.id !== SUPPORTED_LEARNING_SPACE) {
    return (
      <ErrorBlock
        variant={'403'}
        title={'Không gian học tập hiện tại chưa hỗ trợ tính năng này'}
      />
    )
  }

  return (
    <>
      <Container maxWidth={'xl'}>
        <PageHeader title={'So sánh chỉ số lớp học'} />
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Grid alignItems={'center'} container spacing={2}>
                <Grid item xs={12}>
                  <Box display={'flex'} alignItems={'center'} mt={1}>
                    <Typography style={{ marginRight: 20 }}>
                      Danh sách so sánh
                    </Typography>
                    <Button
                      variant={'outlined'}
                      onClick={() => setChooseClassesDialogOpen(true)}
                    >
                      Đã chọn {selectedClassesArray.length} lớp
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid alignItems={'center'} container spacing={2}>
                <Grid item xs={3}>
                  <Typography>Chương trình học</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Select
                    value={scheme}
                    renderValue={(value) =>
                      schemesObject[value as string]?.name
                    }
                    onChange={(event) =>
                      setScheme(event.target.value as string)
                    }
                    fullWidth
                    variant={'outlined'}
                  >
                    {schemes.map((scheme) => (
                      <MenuItem key={scheme.id} value={scheme.id}>
                        {scheme.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {schemesObject[scheme] && (
          <Suspense fallback={<Loading />}>
            <Statistic
              selectedClasses={selectedClassesArray}
              scheme={schemesObject[scheme]}
            />
          </Suspense>
        )}
      </Container>

      <Dialog
        onClose={() => setChooseClassesDialogOpen(false)}
        maxWidth={'md'}
        fullWidth
        open={chooseClassesDialogOpen}
      >
        <DialogTitle>Chọn lớp để so sánh</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {learningSpaceClasses.map((classInfo) => (
                <FormControlLabel
                  key={classInfo.id}
                  control={
                    <Checkbox
                      checked={
                        selectedClasses[classInfo.id as string]?.selected ===
                        true
                      }
                      onChange={(event) => handleSelectClass(event, classInfo)}
                      name={classInfo.id}
                    />
                  }
                  label={classInfo.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setChooseClassesDialogOpen(false)}
            color={'primary'}
            variant={'contained'}
          >
            Xong
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface StatisticProps {
  scheme: SchemeType & IFirestoreMetadata
  selectedClasses: { selected: boolean; className: string; id: string }[]
}

interface StatisticBodyProps {
  sections: SchemeSectionType[]
  selectedClasses: { selected: boolean; className: string; id: string }[]
}

const Statistic: React.FC<StatisticProps> = ({ scheme, selectedClasses }) => {
  const schemeSections = useSchemeSections(scheme)

  if (schemeSections.length === 0)
    return <Typography>Chương trình chưa có nội dung</Typography>

  return (
    <StatisticsBody
      sections={schemeSections}
      selectedClasses={selectedClasses}
    />
  )
}

const useStatistics = (sections: SchemeSectionType[]) => {
  const sectionIds = useMemo(
    () => sections.map((section) => section.id as string),
    [sections]
  )

  const courseStatistics = useCourseStatistics(sectionIds)
  const eventStatistics = useEventStatistics(sectionIds)
  const reflectionStatistics = useReflectionStatistics(sectionIds)

  return { reflectionStatistics, courseStatistics, eventStatistics }
}

const StatisticsBody: React.FC<StatisticBodyProps> = ({
  selectedClasses,
  sections
}) => {
  const { reflectionStatistics, courseStatistics, eventStatistics } =
    useStatistics(sections)

  return (
    <>
      <MyPaper elevation={0} style={{ padding: 20 }}>
        <Grid spacing={2} container>
          <Grid item lg={6} md={12}>
            <CourseCompletionRateComparisonBarChart
              statistics={courseStatistics}
              selectedClasses={selectedClasses}
            />
          </Grid>
          <ReflectionCompletionRateComparisonBarCharts
            statistics={reflectionStatistics}
            selectedClasses={selectedClasses}
          />
          <Grid lg={3} />
          <Grid item lg={6} md={12}>
            <EventParticipationRateBarChart
              statistics={eventStatistics}
              selectedClasses={selectedClasses}
            />
          </Grid>
        </Grid>
      </MyPaper>
      <Box mt={3}>
        <CourseCompletionRateComparisonTable
          selectedClasses={selectedClasses}
          statistics={courseStatistics}
          schemeSections={sections}
        />
      </Box>
      <ReflectionCompletionRateTables
        selectedClasses={selectedClasses}
        statistics={reflectionStatistics}
        schemeSections={sections}
      />
      <Box mt={3}>
        <EventParticipationRateTable
          selectedClasses={selectedClasses}
          statistics={eventStatistics}
          schemeSections={sections}
        />
      </Box>
    </>
  )
}

export default AdminReportClassCompare
