import { DateTime } from "luxon"
import { LearningSpaceClass } from "types"
import { parseTimeByFormat } from "./DateUtils"

export function validateCourse(
  start: Date | null,
  finish: Date | null,
  klass: LearningSpaceClass,
) {
  const today = DateTime.now().endOf('day')
  const errors: { [key: string]: string } = {}
  if (start) {
    const classStartAt = DateTime.fromJSDate(
      klass.timeTracks.startAt.toDate()
    ).startOf('day')
    if (start.getTime() <= classStartAt.toJSDate().getTime())
      errors.start = 'Ngày bắt đầu không được ở quá khứ!'
  }

  if (finish) {
    if (finish.getTime() <= today.toJSDate().getTime()) {
      errors.finish = `Thời gian kết thúc phải sau ngày ${today.toFormat(
        'dd/MM/yyyy'
      )}!`
    }

    if (start && finish.getTime() <= start.getTime()) {
      errors.finish = `Thời gian kết thúc phải sau thời gian bắt bắt đầu!`
    }

    if (
      klass.timeTracks.finishAt &&
      finish > klass.timeTracks.finishAt.toDate()
    )
      errors.finish = `Kết thúc phải trước ngày ${parseTimeByFormat(
        klass.timeTracks.finishAt.toDate()
      )}!`
  }

  // if (!courseSelected) {
  //   errors.course =
  //     'Bạn bắt buộc phải chọn 1 khóa học để thêm vào chương trình!'
  // } else {
  //   if (
  //     courses.findIndex(
  //       (c) => c.lsCourseRef.id === courseSelected.id && c.state === 'PUBLISHED'
  //     ) !== -1
  //   ) {
  //     errors.course = 'Khóa này mà bạn chọn đã có trong chương trình'
  //   }
  // }

  return errors
}