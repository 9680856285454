import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import {
  Box,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core'
import TableCellTooltip from 'components/TableCellTooltip'
import { createStyles } from '@material-ui/core/styles'
import TableHeaderToolbar from 'components/TableHeaderToolbar'
import { IHeader, ITableRowProps, IProps, IRow } from './index'
import MyPaper from 'components/MyPaper'
import { RemoveCircle } from '@material-ui/icons'
import { exportCsv } from 'utils/XLSXUtils'
import MaybachLink from 'components/MaybachLink'
import { removeUnicode } from 'utils'
import { Skeleton } from '@material-ui/lab'
import CLSX from 'clsx'
import { generateRandomString } from 'utils/stringUtils'

import $ from 'jquery'
import { COLORS } from 'constants/common'

const ROWS_PER_PAGE = 50

const useStyles = makeStyles((theme) =>
  createStyles({
    colSticky: {
      position: 'sticky',
      left: 0,
      width: 200,
      background: 'white'
    },
    col: {},
    root: {
      '& .boxshadow-sticky-column': {
        '& .last-sticky-column': {
          boxShadow: 'inset -5px 0px 5px -5px #ccc'
        }
      },
      '& .maybach-table-root': {
        maxHeight: 550,
        overflowY: 'auto',
        position: 'relative',

        '& thead': {
          position: 'sticky',
          top: 0,
          background: COLORS.WHITE,
          boxShadow: '0px 2px 10px #dcdcdc',
          zIndex: theme.zIndex.modal - 1
        }
      }
    }
  })
)

const DEFAULT_COLUMN_WIDTH = 100
const DEFAULT_COLUMN_MIN_WIDTH = 30

const getColumnWidth = (header: IHeader) => {
  return header.w || DEFAULT_COLUMN_WIDTH
}

const getColumnMinWidth = (header: IHeader) => {
  return header.mw || DEFAULT_COLUMN_MIN_WIDTH
}

const getStickyColumnLeftPositions = (headers: IHeader[]) => {
  const stickyColumnWidths: number[] = [0]
  const stickyIndexByKey: { [key: string]: number | undefined } = {}
  let stickyColumnIndex = 0
  headers.forEach((header) => {
    if (header.sticky) {
      stickyIndexByKey[header.key] = stickyColumnIndex

      const columnWidth = getColumnMinWidth(header)
      const currentWidth = stickyColumnWidths[stickyColumnIndex]
      stickyColumnWidths.push(currentWidth + columnWidth)
      stickyColumnIndex += 1
    }
  })
  return {
    stickyColumnWidths,
    stickyIndexByKey
  }
}

const getStickyColumnStyle = (
  stickyIndexByKey: { [p: string]: number | undefined },
  stickyColumnWidths: number[],
  headerKey: string
) => {
  const currentStickyColumnIndex = stickyIndexByKey[headerKey]

  return {
    position: 'sticky',
    left: currentStickyColumnIndex
      ? stickyColumnWidths[currentStickyColumnIndex]
      : 0,
    background: 'white'
  }
}

const isLastStickyColumn = (
  stickyIndexByKey: { [p: string]: number | undefined },
  stickyColumnWidths: number[],
  headerKey: string
) => {
  const currentStickyColumnIndex = stickyIndexByKey[headerKey]

  if (!currentStickyColumnIndex) return false

  return currentStickyColumnIndex === stickyColumnWidths.length - 2
}

const MaybachTableHeader: React.FC<IProps> = (props) => {
  const { headers } = props

  const classes = useStyles(() => {})

  const { stickyIndexByKey, stickyColumnWidths } =
    getStickyColumnLeftPositions(headers)

  const getCustomStyles = useCallback(
    (header: IHeader) => {
      const stickyStyle = header.sticky
        ? getStickyColumnStyle(stickyIndexByKey, stickyColumnWidths, header.key)
        : {}
      const extraStyle = header.style ? header.style : {}

      return {
        width: getColumnWidth(header),
        minWidth: getColumnMinWidth(header),
        maxWidth: header.maxW,
        ...stickyStyle,
        ...extraStyle
      } as TableCellProps['style']
    },
    [stickyColumnWidths, stickyIndexByKey]
  )

  const getHeaderLabel = (header: IHeader) => {
    return header.label
  }

  const getTableCell = (header: IHeader) =>
    !!header.tooltip ? (
      <TableCellTooltip
        align={header.align}
        key={header.key}
        style={getCustomStyles(header)}
        className={CLSX({
          [classes.col]: true,
          'last-sticky-column': isLastStickyColumn(
            stickyIndexByKey,
            stickyColumnWidths,
            header.key
          )
        })}
        tooltip={header.tooltip}
      >
        {header.bold ? <b>{getHeaderLabel(header)}</b> : getHeaderLabel(header)}
      </TableCellTooltip>
    ) : (
      <TableCell
        align={header.align}
        key={header.key}
        style={getCustomStyles(header)}
        className={CLSX({
          [classes.col]: true,
          'last-sticky-column': isLastStickyColumn(
            stickyIndexByKey,
            stickyColumnWidths,
            header.key
          )
        })}
      >
        {header.bold ? <b>{getHeaderLabel(header)}</b> : getHeaderLabel(header)}
      </TableCell>
    )

  return (
    <TableHead>
      <TableRow>{headers.map((header) => getTableCell(header))}</TableRow>
    </TableHead>
  )
}

const MaybachTableRow: React.FC<ITableRowProps> = (props) => {
  const {
    row,
    headers,
    removable,
    onRemove,
    page,
    index,
    rowsPerPage,
    showIndex
  } = props

  const handleRemove = useCallback(() => {
    if (onRemove) {
      onRemove(page * rowsPerPage + index, row)
    }
  }, [page, rowsPerPage, row, index, onRemove])

  const { stickyIndexByKey, stickyColumnWidths } =
    getStickyColumnLeftPositions(headers)

  const getCustomStyles = useCallback(
    (header: IHeader) => {
      const stickyStyle = header.sticky
        ? getStickyColumnStyle(stickyIndexByKey, stickyColumnWidths, header.key)
        : {}
      const extraStyle = header.style ? header.style : {}

      return {
        width: getColumnWidth(header),
        minWidth: getColumnMinWidth(header),
        maxWidth: header.maxW,
        ...stickyStyle,
        ...extraStyle
      } as TableCellProps['style']
    },
    [stickyColumnWidths, stickyIndexByKey]
  )

  return (
    <TableRow>
      {!!showIndex && <TableCell>{page * rowsPerPage + index + 1}</TableCell>}
      {headers.map((header) =>
        !!row[header.key] ? (
          <TableCell
            align={header.align}
            className={CLSX({
              'last-sticky-column': isLastStickyColumn(
                stickyIndexByKey,
                stickyColumnWidths,
                header.key
              )
            })}
            style={getCustomStyles(header)}
            key={header.key}
          >
            {header.bold ? (
              <b>{row[header.key].label}</b>
            ) : row[header.key].navigateTo ? (
              <MaybachLink
                to={row[header.key].navigateTo || '/'}
                name={row[header.key].label}
              />
            ) : (
              row[header.key].label
            )}
          </TableCell>
        ) : null
      )}
      {removable && (
        <TableCell>
          <Tooltip title={'Xóa'}>
            <IconButton
              onClick={handleRemove}
              color={'secondary'}
              size={'small'}
            >
              <RemoveCircle fontSize={'small'} />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  )
}

const MaybachTableBody: React.FC<IProps> = (props) => {
  const { rows, headers, page = 0, loading } = props

  const rowSkeleton = (
    <TableRow>
      {headers.map((header, key) => (
        <TableCell key={key}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  )

  if (loading)
    return (
      <TableBody>
        {rowSkeleton}
        {rowSkeleton}
        {rowSkeleton}
        {rowSkeleton}
      </TableBody>
    )

  if (rows.length === 0)
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={headers.length + 1} align={'center'}>
            Không có dữ liệu
          </TableCell>
        </TableRow>
      </TableBody>
    )

  return (
    <TableBody>
      {rows.map((row, index) => (
        <MaybachTableRow
          index={index}
          rowsPerPage={props.rowsPerPage || ROWS_PER_PAGE}
          onRemove={props.onRemove}
          showIndex={props.showIndex}
          page={page}
          key={index}
          removable={props.removable}
          row={row}
          headers={headers}
        />
      ))}
    </TableBody>
  )
}

const MaybachTable = React.forwardRef<
  {
    setFilterValues: (fv: { [k: string]: string }) => void
  },
  IProps
>((props, ref) => {
  const [page, setPage] = useState(0)
  const { rowsPerPage = ROWS_PER_PAGE, merges } = props

  const tableId = useMemo(() => generateRandomString(20), [])

  const [searchText, setSearchText] = useState('')
  const [filterValues, setFilterValues] = useState<{ [key: string]: string }>(
    props.defaultFilterValues || {}
  )

  const classes = useStyles(() => {})

  const headers = useMemo(() => {
    const cloneHeaders = [...props.headers]
    if (props.showIndex) {
      cloneHeaders.unshift({ key: 'index', label: '#', w: 30 })
    }
    if (props.removable) {
      cloneHeaders.push({ key: 'feature', label: '', w: 50 })
    }
    return cloneHeaders
  }, [props.headers, props.removable, props.showIndex])

  useImperativeHandle(
    ref,
    () => ({
      setFilterValues(fv) {
        setFilterValues((oldValues) => ({ ...oldValues, ...fv }))
      }
    }),
    []
  )

  const filterRows: (regex: RegExp) => IRow[] = useCallback(
    (regex: RegExp) => {
      if (props.searchFields) {
        return props.rows.filter((row) => {
          if (props.searchFields) {
            // Check if search input is satisfied 1 of the search fields
            const fields = props.searchFields.map((searchField) => {
              return row[searchField]
            })
            const fieldsSatisfied = fields.map((field) => {
              let value = ''

              if (field.label !== null) {
                if (field && field.label.length === undefined)
                  value = field.value || field.label || ''
                else {
                  value = field.value || ''
                }
              } else {
                value = field.value || field.label || ''
              }

              return regex.test(removeUnicode(value))
            })
            let found = false
            fieldsSatisfied.forEach((ar) => (found = found || ar))
            return found
          } else return true
        })
      } else return props.rows
    },
    [props.rows, props.searchFields]
  )

  const searched = useMemo(() => {
    setPage(0)
    const keywords = searchText
      .trim()
      .replace(/(\?|\+|\\)/g, '')
      .split(/\s+/)
    const regex = new RegExp(`(${removeUnicode(keywords.join('|'))})`, 'gmi')
    if (!props.searchFields) return props.rows

    return filterRows(regex)
  }, [searchText, props.searchFields, props.rows, filterRows])

  const filtered = useMemo(() => {
    return searched.filter((row) => {
      const filterKeys = Object.keys(filterValues)
      return filterKeys.every((key) => {
        const cellValue = row[key].value || row[key].label

        if (!filterValues[key]) return true
        return cellValue.indexOf(filterValues[key]) !== -1
      })
    })
  }, [searched, filterValues])

  const paginated = useMemo(() => {
    const from = page * rowsPerPage
    const to = from + rowsPerPage
    return filtered.slice(from, to)
  }, [page, filtered, rowsPerPage])

  const handleExport = useCallback(async () => {
    const aboveHeader = props.rowsAboveHeader ? props.rowsAboveHeader : []

    const headers = props.csvHeader
      ? props.csvHeader
      : props.headers.map((h) => h.label)

    const data: string[][] = [...aboveHeader, headers]

    let exportData = filtered

    if (props.getServerExportData) {
      exportData = await props.getServerExportData()
    }

    exportData.forEach((row) => {
      const csvRow: string[] = []

      if (props.getCsvRow) {
        data.push(props.getCsvRow(row))
      } else {
        props.headers.forEach((col) => {
          let data
          if (!!row[col.key].exportValue) {
            data = row[col.key].exportValue
          } else if (col.key === 'status') {
            data = row[col.key].label.props.children
          } else {
            data = row[col.key].label
          }

          csvRow.push(data)
        })
        data.push(csvRow)
      }
    })

    await exportCsv(
      props.csvTitle || new Date().getTime().toString(),
      data,
      merges
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered, props.csvTitle])

  const count = filtered.length

  return (
    <MyPaper id={tableId} className={classes.root}>
      <Box p={1}>
        <TableContainer>
          <TableHeaderToolbar
            filterValues={filterValues}
            onExport={handleExport}
            exportable={props.exportable}
            searchBox={props.searchBox}
            filters={props.filters}
            leftToolbar={props.leftToolbar}
            onSearch={props.onSearch ? props.onSearch : setSearchText}
            onChange={(key, value) =>
              setFilterValues((f) => ({ ...f, [key]: value }))
            }
            toolbar={props.toolbar}
            onClick={props.onClick}
          />

          <Box
            onScroll={onScrollTable(tableId)}
            className={'maybach-table-root'}
          >
            <Table>
              <MaybachTableHeader {...props} headers={headers} />
              <MaybachTableBody
                {...props}
                headers={headers}
                rows={paginated}
                page={page}
              />
            </Table>
          </Box>
          <Box ml={1} mt={4}>
            {props.caption}
          </Box>
          {props.paginationComponent ? (
            <Box display={'flex'} justifyContent={'flex-end'} mt={1}>
              {props.paginationComponent}
            </Box>
          ) : (
            <TablePagination
              component={'div'}
              count={count}
              onChangePage={(e, newPage) => setPage(newPage)}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} của ${count}`
              }
            />
          )}
        </TableContainer>

        {!!props.bottomToolbar && (
          <Box mt={1} pb={2}>
            {props.bottomToolbar}
          </Box>
        )}
      </Box>
    </MyPaper>
  )
})
export default MaybachTable

function onScrollTable(
  tableId: string
): React.UIEventHandler<HTMLElement> | undefined {
  return (e) => {
    const cells = $(`#${tableId} .last-sticky-column`)

    if (cells[0]) {
      const containerLeft = $(e.target).offset()?.left || 0
      const cell = $(cells[0])
      const cssLeft = cell.css('left').replace('px', '')
      const posLeft = cell.offset()?.left || 0

      if (posLeft - containerLeft === +cssLeft) {
        $(e.target).addClass('boxshadow-sticky-column')
      } else {
        $(e.target).removeClass('boxshadow-sticky-column')
      }
    }
  }
}
