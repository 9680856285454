import { Box } from '@material-ui/core'
import React from 'react'
import FeedbackEndOfClassForm from 'widgets/FeedbackEndOfClassForm'

interface IProps {}

const LearningSpaceClassFeedbacks: React.FC<IProps> = () => {
  return (
    <>
      <Box mb={3}></Box>
      <FeedbackEndOfClassForm />
    </>
  )
}

export default LearningSpaceClassFeedbacks
