import { GeolocationResponseType } from 'types'

export const getIpv4 = async (): Promise<GeolocationResponseType> => {
  try {
    const data = await fetch(
      'https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572'
    )
    return data.json()
  } catch (e) {
    console.error(e)
    return {
      country_code: 'unknown',
      country_name: 'unknown',
      city: 'unknown',
      postal: 'unknown',
      latitude: -1,
      longitude: -1,
      IPv4: 'unknown',
      state: 'unknown'
    }
  }
}

export const getLocation = async (): Promise<{
  latitude: number
  longitude: number
}> => {
  try {
    const position = await new Promise<any>((resolve) => {
      navigator.geolocation.getCurrentPosition(resolve, () => {
        resolve({
          coords: {
            latitude: -1,
            longitude: -1,
            accuracy: 0,
            heading: 0,
            speed: 0,
            altitude: 0,
            altitudeAccuracy: 0
          },
          timestamp: 0
        })
      })
    })
    return {
      longitude: position.coords.longitude,
      latitude: position.coords.latitude
    }
  } catch (e) {
    return {
      longitude: -1,
      latitude: -1
    }
  }
}
