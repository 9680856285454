export function hasRoles(
  givenRoles: { [key: string]: boolean } | undefined,
  requiredRoles: { [key: string]: boolean }
) {
  const didHaveRoles: {
    [key: string]: { expected: boolean; actual: any }
  } = {}
  if (givenRoles) {
    if (givenRoles['admin']) return true

    Object.keys(requiredRoles).forEach((role) => {
      if (requiredRoles[role] === givenRoles[role]) {
        didHaveRoles[role] = {
          expected: requiredRoles[role],
          actual: givenRoles[role]
        }
      }
    })
    return Object.keys(didHaveRoles).length !== 0
  }
  return false
}

export function hasOnlyRole(
  givenRoles: { [key: string]: boolean } | undefined,
  requiredRole: string
) {
  if (givenRoles) {
    const hasRequiredRole = Boolean(givenRoles[requiredRole])
    const givenRolesArray = Object.keys(givenRoles)
    let hasOtherRoles = false
    givenRolesArray.forEach((role) => {
      if (Boolean(givenRoles[role]) && role !== requiredRole)
        hasOtherRoles = true
    })
    return hasRequiredRole && !hasOtherRoles
  }
  return false
}

export function hasAllowedClaims(
  allowedClaims: { [key: string]: boolean },
  userClaims: { [key: string]: boolean }
) {
  let result = false

  const allowedClaimsArray = Object.keys(allowedClaims)

  for (let i = 0; i < allowedClaimsArray.length; i++) {
    if (userClaims[allowedClaimsArray[i]] === true) {
      result = true
      break
    }
  }

  return result
}
