import { LearningSpaceClassCourseType, LearningSpaceCourse } from 'types'

export const validateAddCourseToClassForm = (data: {
  course: LearningSpaceCourse
  classCourses: Array<LearningSpaceClassCourseType>
  startAt: Date | undefined
  expirationDate: Date | undefined
}) => {
  const errors: {
    course?: string
    startAt?: string
    expirationDate?: string
  } = {}

  if (!data.course) {
    errors.course = 'Bạn phải chọn một khóa học'
  }

  let found = false
  data.classCourses.forEach((classCourse) => {
    if (classCourse.id === data.course.id) {
      found = true
    }
  })
  if (found) {
    errors.course = 'Khóa học này đã tồn tại trong lớp'
  }

  if (!data.startAt) {
    errors.startAt = 'Thời gian bắt đầu không được bỏ trống!'
  }

  if (data.expirationDate) {
    if (data.startAt && data.expirationDate <= data.startAt) {
      errors.expirationDate = 'Thời hạn phải sau ngày bắt đầu!'
    }
    if (data.startAt) {
      const defaultExpirationDate = new Date(
        data.startAt.getMonth() >= 10
          ? data.startAt.getFullYear() + 1
          : data.startAt.getFullYear(),
        (data.startAt.getMonth() + 2) % 12,
        data.startAt.getDate()
      )

      if (data.expirationDate > defaultExpirationDate) {
        errors.expirationDate = 'Thời hạn tối đa là 2 tháng'
      }
    }
  } else {
    errors.expirationDate = 'Thời gian hạn không được bỏ trống!'
  }

  return errors
}
