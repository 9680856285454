export default class ValidationError extends Error {
  data: { [k: string]: any } = {}
  constructor(data: { [k: string]: any } = {}) {
    super()
    this.message = 'Invalid Argument'
    this.data = data
  }

  getData = () => {
    return this.data
  }
}
