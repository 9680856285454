import {
  isValidEmail,
  isValidName,
  isValidPassword
} from 'utils/StringFormatter'

export const validateRowImport = (row: { [key: string]: any }) => {
  const fieldInvalids: string[] = []

  if (!row.email || !isValidEmail(row.email)) {
    fieldInvalids.push('Email')
  }
  if (!row.password || !isValidPassword(row.password)) {
    fieldInvalids.push('Password')
  }
  if (!row.name || !isValidName(row.name)) {
    fieldInvalids.push('Name')
  }

  if (fieldInvalids.length === 0) return ''

  return 'Cột ' + fieldInvalids.join(', ') + ' Không đúng định dạng!'
}
