import React from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import { useActiveWhen } from 'hooks/useActiveWhen'
import { ActiveWhenType } from 'types'

export interface MaybachButtonProps extends ButtonProps {
  activeWhen?: ActiveWhenType
  minWidth?: number
}

const MaybachButton: React.FC<MaybachButtonProps> = ({
  activeWhen = [],
  minWidth = 150,
  ...btnProps
}) => {
  const active = useActiveWhen(activeWhen)
  const disabled = !active ? !active : btnProps.disabled

  return <Button {...btnProps} style={btnProps.style || { minWidth: minWidth }} disabled={disabled} />
}

export default MaybachButton
