import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { Tag } from '../../types'

// Define a type for the slice state
interface TagReducer {
  loaded: boolean
  loading: boolean
  tags: { [k: string]: Tag }
}

// Define the initial state using that type
const initialState: TagReducer = {
  loaded: false,
  loading: false,
  tags: {}
}

export const tags = createSlice({
  name: 'tags',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<{ [k: string]: Tag }>) => {
      return { tags: action.payload, loaded: true, loading: false }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) return { ...state, loaded: false, loading: true }
      return { ...state, loaded: true, loading: false }
    },
    addTags: (
      state,
      action: PayloadAction<{ id: string; display: string }[]>
    ) => {
      const tags: { [k: string]: Tag } = { ...state.tags }
      action.payload.forEach((tag) => (tags[tag.id] = tag))

      return { ...state, tags }
    }
  }
})

export const { setTags, setLoading, addTags } = tags.actions

export const selectTagLoadState = (state: RootState) => {
  return {
    loaded: state.tags.loaded,
    loading: state.tags.loading
  }
}

export const selectTags = (state: RootState) => {
  return state.tags.tags
}

export default tags.reducer
