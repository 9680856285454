import React from 'react'
import { useDocumentData } from 'hooks'
import { useParams } from 'react-router-dom'
import { LectureType } from 'types'
import LectureFormEdit from 'components/LectureFormEdit/LectureFormEdit'
import { IFirestoreMetadata } from 'interfaces'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import ErrorBlock from 'components/ErrorBlock'
import { Box, Container } from '@material-ui/core'
import PageHeaderAndBackBtn from 'components/PageHeaderAndBackBtn'

interface IProps {}

const EditLecture: React.FC<IProps> = () => {
  const lspace = useLearningSpace()
  const { lid } = useParams<{ lid: string; id: string }>()
  const docRef = lspace._meta.ref.collection('custom_lectures').doc(lid)
  const lecture = useDocumentData<LectureType & IFirestoreMetadata>(docRef, {
    idField: 'id'
  })

  if (!lecture) return <ErrorBlock variant="404" />
  else {
    return (
      <Container maxWidth="md">
        <Box>
          <Box
            mt={3}
            mb={1}
            display="flex"
            justifyContent={'space-between'}
            alignItems="center"
          >
            <PageHeaderAndBackBtn
              backTo="/admin/lectures"
              backTooltipLabel="Danh sách bài học"
              label={'Sửa bài học'}
            />
          </Box>
          <LectureFormEdit lecture={lecture} />
        </Box>
      </Container>
    )
  }
}

export default EditLecture
