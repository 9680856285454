import firebase from 'firebase/app'

export const getFilePathFromUrl = (url: string) => {
  const removedParam = url.replace(/\?.+/, '')
  const splitUrl = removedParam.split('/')
  const fileName = splitUrl.pop()
  return fileName ? decodeURIComponent(fileName) : null
}

export const deleteFiles = async (
  files: string[],
  storage: firebase.storage.Storage
) => {
  const fbFilesReg = /^https:\/\/firebasestorage\.googleapis\.com/
  const httpReg = /^(http|https):\/\//
  const fbFiles = files.filter((f) => fbFilesReg.test(f))
  const nonHttp = files.filter((f) => !httpReg.test(f))

  const filePaths = fbFiles
    .map(getFilePathFromUrl)
    .filter((p) => !!p) as string[]

  await Promise.all(
    filePaths.concat(nonHttp).map(async (f) => {
      const ref = storage.ref(f)

      try {
        await ref.getDownloadURL()
        await ref.delete()
      } catch (e) {}
    })
  )
}

export const uploadFiles = async (
  data: File[],
  folder: string,
  storage: firebase.storage.Storage
) => {
  return Promise.all(
    data.map(async (file) => uploadFile(file, folder, storage))
  )
}

export const uploadFile = async (
  data: File,
  folder: string,
  storage: firebase.storage.Storage
) => {
  // const dataUrlReg = /^data:/
  // if (typeof data !== 'object') return data
  // if (!dataUrlReg.test(data.dataUrl)) return null

  const storageUrl = `${folder}/${new Date().getTime()}_${data.name}`
  const ref = storage.ref(storageUrl)
  // await ref.putString(data.dataUrl, 'data_url')
  await ref.put(data)
  return {
    publicUrl: await ref.getDownloadURL(),
    storageUrl: storageUrl,
    name: data.name
  }
}
