import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

export interface IData {
  roles: { [k: string]: boolean }
}

const initialState = {}

export const spaceUserRoles = createSlice({
  name: 'spaceUserRoles',
  initialState,
  reducers: {
    setSpaceUserRoles(state, action: PayloadAction<IData>) {
      return action.payload.roles
    }
  }
})

export const { setSpaceUserRoles } = spaceUserRoles.actions

export const getSpaceUserRoles = (state: RootState) => state.spaceUserRoles
export default spaceUserRoles.reducer
