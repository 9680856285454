import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

export interface IClassStudents {
  id: string
}

const initialState = {
  id: ''
}

export const classStudents = createSlice({
  name: 'classStudents',
  initialState,
  reducers: {
    setStudent(state, action: PayloadAction<IClassStudents>) {
      state.id = action.payload.id
    }
  }
})

export const { setStudent } = classStudents.actions

export const getSelectedStudentId = (state: RootState) => state.classStudents.id
export default classStudents.reducer
