import React, { useContext } from 'react'
import SubdomainContext from 'contexts/SubdomainContext'
import LearningSpaceProvider from 'providers/LearningSpaces/LearningSpaceProvider'

interface Iprops {
  fallback: React.ComponentType<{}>
}

const SubdomainBoundary: React.FC<Iprops> = (props) => {
  const { fallback: Component, children } = props

  const context = useContext(SubdomainContext)

  if (!context.domain?.length) {
    return <Component />
  }

  return (
    <>
      <LearningSpaceProvider domain={context.domain}>
        {children}
      </LearningSpaceProvider>
    </>
  )
}

export default SubdomainBoundary
