import { Box, Typography } from '@material-ui/core'
import React from 'react'

interface GadgetProps {
  title: string
  minHeight?: number
  maxWidth?: number | string
  maxHeight?: number
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  toolbar?: React.ComponentType<any> | React.FC<any>
}

const Gadget: React.FC<GadgetProps> = ({
  title,
  minHeight,
  children,
  maxWidth,
  maxHeight,
  heading = 'h5',
  toolbar: Toolbar
}) => {
  return (
    <>
      <Box minHeight={minHeight || undefined}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={heading} style={{ fontWeight: 500 }}>
            {title}
          </Typography>
          <Box>{Toolbar && <Toolbar />}</Box>
        </Box>
        <Box maxHeight={maxHeight} maxWidth={maxWidth} mt={1}>
          {children}
        </Box>
      </Box>
    </>
  )
}

export default Gadget
