import React from 'react'
import {
  Step,
  StepButton,
  StepLabel,
  Stepper as MStepper
} from '@material-ui/core'

interface IProps {
  steps: string[]
  activeStep: number
  clickable?: boolean
}

const Stepper: React.FC<IProps> = ({
  steps,
  activeStep = 0,
  clickable = true
}) => {
  function isStepComplete(step: number) {
    return step < activeStep
  }

  return (
    <MStepper
      style={{ backgroundColor: 'transparent' }}
      nonLinear
      activeStep={activeStep}
    >
      {steps.map((label, index) => {
        const stepProps = {}
        const buttonProps = {}

        return (
          <Step key={label} completed={isStepComplete(index)} {...stepProps}>
            {clickable ? (
              <StepButton {...buttonProps}>{label}</StepButton>
            ) : (
              <StepLabel>{label}</StepLabel>
            )}
          </Step>
        )
      })}
    </MStepper>
  )
}

export default Stepper
