import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useEffect, useState } from 'react'
import { useFunctions } from 'hooks'
import { useLearningSpaceContext } from 'contexts/SubdomainContext'
import { parseStatisticDate } from 'utils/DateUtils'
import { useFirestore } from 'reactfire'
import { endOfYear, startOfYear } from 'date-fns'
import { getCollectionData } from './FirestoreUtils'
import {
  StatisticsSpaceNewStudent,
  StatisticsSpaceStudentStatus,
  StatisticsSpaceTotalUser
} from 'types'
import { firestore } from 'firebase'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import {
  IRange,
  getPreviousRange
} from 'components/BasicDateRangePicker/definedRanges'

interface IDataStatistic {
  enrolledCourseStatistic: {
    dates: string[]
    joinedData: string[]
    activeData: string[]
  }
  userActiveStatistic: number
}
export const useDataStatistic = (
  startDate: MaterialUiPickersDate,
  endDate: MaterialUiPickersDate,
  filterBy: string
): [
  undefined | IDataStatistic,
  boolean,
  React.Dispatch<React.SetStateAction<IDataStatistic | undefined>>
] => {
  const functions = useFunctions()
  const [data, setData] = useState<undefined | IDataStatistic>()
  const [loading, setLoading] = useState<boolean>(false)
  const { domain } = useLearningSpaceContext()
  useEffect(() => {
    setLoading(true)
    if (domain) {
      const callableDataStatisticLearningSpaceUser = functions.httpsCallable(
        'callable-getStatisticStudentByLearningSpace'
      )

      try {
        callableDataStatisticLearningSpaceUser({
          learningSpaceId: domain,
          startDate: parseStatisticDate(startDate),
          endDate: parseStatisticDate(endDate),
          filterBy: filterBy
        }).then((result) => {
          if (result.data) {
            setData(result.data)
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    setLoading(false)
  }, [functions, domain, startDate, endDate, filterBy])

  return [data, loading, setData]
}

export const getFullYearRange = (years: number = 3) => {
  const currentYear = new Date().getFullYear()
  const range = []
  for (let i = 0; i < years; i++) {
    range.push(currentYear - i)
  }

  return range
}

export const useDataNewStudents = (year: number) => {
  const [loading, setLoading] = useState(true)
  const [currentData, setCurrentData] = useState<number[]>([])
  const db = useFirestore()
  const learningSpace = useLearningSpace()
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const startOfRange = startOfYear(new Date(year, 0))
      const endOfRange = endOfYear(startOfRange)
      await getDataNewStudentByMonth(
        db,
        learningSpace.id,
        startOfRange,
        endOfRange
      ).then((data) => setCurrentData(formatMonthsData(data)))
    })().finally(() => setLoading(false))
  }, [db, year, learningSpace])

  return {
    currentData,
    loading
  }
}

const getDataNewStudentByMonth = async (
  db: firestore.Firestore,
  learningSpaceId: string,
  startOfRange: Date,
  endOfRange: Date
) => {
  const query = db
    .collection('statistics/spaces/new_students')
    .where('range', '==', 'month')
    .where('learningSpaceId', '==', learningSpaceId)
    .where('timeTracks.startOfRange', '>=', startOfRange)
    .where('timeTracks.startOfRange', '<', endOfRange)

  return getCollectionData<StatisticsSpaceNewStudent>(query, {
    idField: 'id'
  })
}

const formatMonthsData = (monthsData: StatisticsSpaceNewStudent[]) => {
  const data: { [key: string]: number } = {}

  monthsData.forEach((d) => {
    const key = d.timeTracks.startOfRange.toDate().getMonth()
    if (!data[key]) data[key] = 0

    data[key] += d.total || 0
  })

  const formattedData = []
  for (let month = 0; month < 12; month++) {
    formattedData.push(data[month] || 0)
  }

  return formattedData
}

export const useDataTotalStudent = (range: IRange) => {
  const [loading, setLoading] = useState(true)
  const [currentData, setCurrentData] = useState<number | null>(null)
  const [previousData, setPreviousData] = useState<number | null>(null)
  const db = useFirestore()
  const learningSpace = useLearningSpace()
  useEffect(() => {
    ;(async () => {
      const startOfRange = range.range().startDate
      const endOfRange = range.range().endDate
      await getDataTotalStudentByRange(db, startOfRange, endOfRange).then(
        (data) => {
          setCurrentData(data[0]?.statistics[learningSpace.id] || null)
        }
      )
      const priviousRange = getPreviousRange(range)
      if (priviousRange) {
        await getDataTotalStudentByRange(
          db,
          priviousRange.startDate,
          priviousRange.endDate
        ).then((data) =>
          setPreviousData(data[0]?.statistics[learningSpace.id] || null)
        )
      }
    })().finally(() => setLoading(false))
  }, [db, learningSpace, range])

  return {
    currentData,
    previousData,
    loading
  }
}

const getDataTotalStudentByRange = async (
  db: firestore.Firestore,
  startOfRange: Date,
  endOfRange: Date
) => {
  const query = db
    .collection('statistics/spaces/total_users')
    .where('timeTracks.startOfRange', '==', startOfRange)
    .where('timeTracks.endOfRange', '==', endOfRange)

  return await getCollectionData<StatisticsSpaceTotalUser>(query, {
    idField: 'id'
  })
}

export const useDataStudentStatus = (range: IRange) => {
  const [loading, setLoading] = useState(true)
  const [
    currentData,
    setCurrentData
  ] = useState<StatisticsSpaceStudentStatus | null>(null)
  const [
    previousData,
    setPreviousData
  ] = useState<StatisticsSpaceStudentStatus | null>(null)
  const db = useFirestore()
  const learningSpace = useLearningSpace()
  useEffect(() => {
    ;(async () => {
      const startOfRange = range.range().startDate
      const endOfRange = range.range().endDate
      await getDataStudentStatusByRange(db, startOfRange, endOfRange).then(
        (data) => {
          setCurrentData(data[0] || null)
        }
      )
      const priviousRange = getPreviousRange(range)
      if (priviousRange) {
        await getDataStudentStatusByRange(
          db,
          priviousRange.startDate,
          priviousRange.endDate
        ).then((data) => setPreviousData(data[0] || null))
      }
    })().finally(() => setLoading(false))
  }, [db, learningSpace, range])

  return {
    currentData,
    previousData,
    loading
  }
}

const getDataStudentStatusByRange = async (
  db: firestore.Firestore,
  startOfRange: Date,
  endOfRange: Date
) => {
  const query = db
    .collection('statistics/spaces/user_learning_statuses')
    .where('timeTracks.startOfRange', '==', startOfRange)
    .where('timeTracks.endOfRange', '==', endOfRange)

  return await getCollectionData<StatisticsSpaceStudentStatus>(query, {
    idField: 'id'
  })
}

export const getLabels = (dates: string[], filterBy: string) => {
  switch (filterBy) {
    case 'day':
      return dates.map((dateString) => {
        const date = new Date(parseDateFormat(dateString))
        if (date.getDate() === 1) {
          return date.getDate() + '-' + (date.getMonth() + 1)
        } else if (date.getDate() % 2 !== 0) {
          return date.getDate()
        }
        return ''
      })
    case 'week':
      return dates.map((dateString, index) => {
        if (dateString.indexOf('*') !== -1) {
          const dateSplit = dateString.split('*')
          const startDateString = dateSplit[0]
          const endDateString = dateSplit[1]
          const startDate = new Date(parseDateFormat(startDateString))
          const endDate = new Date(parseDateFormat(endDateString))
          if (index === dates.length - 1) {
            return (
              startDate.getDate() +
              '/' +
              (startDate.getMonth() + 1) +
              ' → ' +
              endDate.getDate() +
              '/' +
              (endDate.getMonth() + 1)
            )
          } else {
            return startDate.getDate() + '/' + (startDate.getMonth() + 1)
          }
        }
        return ''
      })
  }
}

export const getTitle = (
  dates: string[],
  filterBy: string,
  tooltipItem: any[]
) => {
  switch (filterBy) {
    case 'day':
      return dates[tooltipItem[0].index]
    case 'week':
      return dates[tooltipItem[0].index].replace('*', ' đến ')
  }
}

const parseDateFormat = (date: string) => {
  const dateStringSplit = date.split('-')
  return (
    dateStringSplit[2] + '-' + dateStringSplit[1] + '-' + dateStringSplit[0]
  )
}
