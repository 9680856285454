import { CourseQuizType, EnrolledQuizType, QuestionType } from 'types'
import { orderBy } from 'lodash'

export const recordResultOnToVietnamese = {
  HIGHEST: 'điểm cao nhất',
  LAST: 'điểm làm bài lần cuối cùng',
  FIRST: 'điểm làm bài lần đầu tiên'
}

export const quizStatusToVietnamese = {
  PASSED: 'Hoàn thành',
  FAILED: 'Chưa vượt qua',
  IN_COMPLETE: 'Chưa hoàn thành',
  EXPIRED: 'Quá hạn'
}

export function catchEventCloseWindow(event: any) {
  event.preventDefault()
  return (event.returnValue = 'Bạn có chắc là muốn thoát?')
}

export function checkQuizzesCompleted(
  quizzes: CourseQuizType[],
  results: { [key: string]: EnrolledQuizType }
) {
  let cloneQuizzes: CourseQuizType[] = quizzes
  cloneQuizzes = cloneQuizzes.map((quiz) => {
    const result = results[quiz.id] as EnrolledQuizType
    quiz.completed =
      result && result.state === 'PASSED' ? 'completed' : 'in_complete'
    return quiz
  })

  return cloneQuizzes
}

export function filterChildQuestions(questions: QuestionType[]) {
  const childQuestions: QuestionType[] = []
  for (let i = 0; i < questions.length; i++) {
    if (!!questions[i].parentRef) {
      childQuestions.push(questions.splice(i, 1)[0])
      i--
    }
  }

  return childQuestions
}

export function groupQuestions(questions: QuestionType[]) {
  let index = 0
  const childQuestions = filterChildQuestions(questions)
  const mapIndex = (question: QuestionType) => {
    if (question.type !== 'BLANK') {
      question.index = index
      index++
    }
    return question
  }
  return questions.map((question) => {
    mapIndex(question)
    const ownedQuestions = childQuestions.filter(
      (q) => q.parentRef?.id === question.id
    )
    question.childQuestions = orderBy(ownedQuestions, ['ordering']).map(
      mapIndex
    )
    return question
  })
}
