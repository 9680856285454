import React, { useCallback, useMemo } from 'react'
import { ClassReflectionSubmitRateStatistics, SchemeSectionType } from 'types'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import Gadget from 'components/Gadget'
import MaybachTable, { IHeader, IRow } from 'components/MaybachTable'
import { Box, Typography } from '@material-ui/core'
import { useReflectionTemplates } from 'hooks/useReflectionTemplate'

interface Props {
  statistics: ClassReflectionSubmitRateStatistics[]
  schemeSections: SchemeSectionType[]
  selectedClasses: { selected: boolean; className: string; id: string }[]
}

const ReflectionCompletionRateTables: React.FC<Props> = (props) => {
  const learningSpace = useLearningSpace()
  const { selectedClasses, schemeSections, statistics } = props
  const reflectionTemplates = useReflectionTemplates()

  const tableHeaders = useMemo(() => {
    const result: IHeader[] = [
      { key: 'className', label: 'Lớp' },
      { key: 'average', label: 'Trung bình', bold: true }
    ]
    schemeSections.forEach((section) => {
      result.push({ key: section.id as string, label: section.title })
    })
    return result
  }, [schemeSections])

  const tableDataByReflectionId = useCallback(
    (reflectionId: string) => {
      const result: IRow[] = []
      selectedClasses.forEach((classInfo) => {
        const classStatistics = statistics.filter(
          (statistic) =>
            statistic.classId === classInfo.id &&
            statistic.learningSpaceId === learningSpace.id &&
            statistic.reflectionId === reflectionId
        )
        const classSectionReflectionCompletionRate: {
          [sectionId: string]: string
        } = {}

        let totalSubmittedStudents = 0
        let totalStudents = 0
        classStatistics.forEach((statistic) => {
          totalSubmittedStudents += statistic.totalSubmittedStudents
          totalStudents += statistic.totalStudents
          classSectionReflectionCompletionRate[statistic.sectionId] = `${(
            statistic.submitRate * 100
          ).toFixed(0)}%`
        })

        const row: IRow = { className: { label: classInfo.className } }
        schemeSections.forEach((section) => {
          row[section.id as string] = {
            label:
              classSectionReflectionCompletionRate[section.id as string] ||
              'N/A'
          }
        })
        const average = totalSubmittedStudents / totalStudents
        row['average'] = {
          label: average ? `${(average * 100).toFixed(0)}%` : 'N/A'
        }
        result.push(row)
      })
      return result
    },
    [learningSpace.id, schemeSections, selectedClasses, statistics]
  )

  return (
    <>
      {reflectionTemplates.map((template) => (
        <Box key={template.id} mt={3}>
          <Gadget title={`Tỉ lệ hoàn thành reflection ${template.title}`}>
            <MaybachTable
              exportable
              headers={tableHeaders}
              rows={tableDataByReflectionId(template.id)}
              showIndex
              csvTitle={`Tỉ lệ hoàn thành reflection`}
              caption={
                <>
                  <Typography>
                    Tỉ lệ hoàn thành reflection {template.title} được tính theo{' '}
                    <br />
                  </Typography>
                  <ul>
                    <li>
                      Reflection: Số học viên đã hoàn thành reflection / Số học
                      viên trong lớp
                    </li>
                    <li>
                      Mục: Tổng tỉ lệ hoàn thành của tất cả các reflection trong
                      mục / Số reflection trong mục
                    </li>
                    <li>
                      <b>Trung bình</b>: Tổng tỉ lệ hoàn thành của tất cả các
                      mục trong lớp / Số mục trong lớp
                    </li>
                  </ul>
                </>
              }
            />
          </Gadget>
        </Box>
      ))}
    </>
  )
}

export default ReflectionCompletionRateTables
