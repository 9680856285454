import React, { Fragment, Suspense, useContext, useMemo, useState } from 'react'
import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@material-ui/core'
import PageHeader from 'components/PageHeader'
import TableSkeleton from 'components/TableSkeleton'
import MaybachTable, { IHeader, IRow } from 'components/MaybachTable'
import { useFirestore } from 'reactfire'
import { useCollectionData } from 'hooks'
import { BugReports } from 'types'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { useStyles } from './style'
import ErrorBlock from 'components/ErrorBlock'
import RoleContext from 'contexts/RoleContext'
import { hasRoles } from 'utils/AuthorizationUtils'

const parseStatus = (status: string, classes: any) => {
  switch (status) {
    case 'OPEN':
      return <Chip label={status} className={classes.chipSuccess} />
    case 'CLOSED':
      return <Chip label={status} color="default" />
    case 'RESOLVED':
      return <Chip label={status} color="primary" />
  }
}

const IHeaderBugReport: IHeader[] = [
  { key: 'title', label: 'Tiêu đề', bold: true },
  { key: 'description', label: 'Mô tả lỗi' },
  { key: 'attachment', label: 'Đính kèm' },
  { key: 'reporterName', label: 'Người gửi' },
  { key: 'status', label: 'Trạng thái', w: 80, align: 'center' },
  { key: 'action', label: '', w: 50, align: 'center' }
]

const LearningSpaceBugReportsBody: React.FC = () => {
  const classes = useStyles()
  const db = useFirestore()
  const space = useLearningSpace()
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState<string>('')

  const handleClickOpen = (item: string) => {
    setOpen(true)
    setImage(item)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const issueRef = db
    .collection('issues')
    .where('spaceRef', '==', space._meta.ref)
    .orderBy('timeTracks.createdAt', 'desc')

  const issues = useCollectionData<BugReports>(issueRef, {
    idField: 'id'
  })

  const rows: IRow[] = useMemo(
    () =>
      issues.map((issue) => ({
        title: { label: issue.title, value: issue.title },
        description: { label: issue.description },
        attachment: {
          label: (
            <Grid container spacing={1}>
              {issue.attachment.map((item, key) => (
                <Grid item>
                  <Button
                    style={{ padding: 3 }}
                    onClick={() => {
                      handleClickOpen(item)
                    }}
                  >
                    <img
                      src={item}
                      alt={item}
                      key={key}
                      className={classes.imageList}
                    />
                  </Button>
                </Grid>
              ))}
            </Grid>
          )
        },
        reporterName: { label: issue.reporterName, value: issue.reporterName },
        status: { label: parseStatus(issue.status, classes) },
        action: {
          label: (
            // <Button color="primary" id={issue.id ?? ''}>
            //   Edit
            // </Button>
            <></>
          )
        }
      })),

    [classes, issues]
  )

  return (
    <>
      <MaybachTable
        headers={IHeaderBugReport}
        rows={rows}
        rowsPerPage={10}
        showIndex
        searchBox
        searchFields={['reporterName', 'title']}
      />
      <Dialog
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <img src={image} alt={image} className={classes.imageDialog} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const LearningSpaceBugReports: React.FC = () => {
  const userContext = useContext(RoleContext)
  const userRoles = userContext.roles
  const isIssueAdmin = hasRoles(userRoles, {
    issueAdmin: true
  })

  if (!isIssueAdmin) return <ErrorBlock variant="403" />

  return (
    <Container maxWidth="xl">
      <PageHeader title="Báo lỗi" />
      <Suspense
        fallback={
          <TableSkeleton
            fields={[
              '#',
              'Tiêu đề',
              'Mô tả lỗi',
              'Đính kèm',
              'Người gửi',
              'Trạng thái',
              ''
            ]}
            numberRow={10}
          />
        }
      >
        <LearningSpaceBugReportsBody />
      </Suspense>
    </Container>
  )
}

export default LearningSpaceBugReports
