import { firestore } from 'firebase'
import { IFirestoreMetadata } from 'interfaces'
import {
  LearningSpaceClass,
  LearningSpaceCourse,
  LearningSpaceType
} from 'types'
import { getCollectionData, getDocument } from './FirestoreUtils'
import {
  getActiveUsersByRole,
  getAllPublishedEventsInRange
} from './LearningSpaceClassUtils'

export function validateLearningSpace(input: any): { [key: string]: string } {
  let error: { [key: string]: string } = {}
  if (!input.subdomain) {
    error.subdomain = 'Không được bỏ trống!.'
  }
  return error
}

export async function getTeachingHoursStatistics(
  range: {
    startDate: Date
    endDate: Date
  },
  space: LearningSpaceType & IFirestoreMetadata
) {
  const results: {
    id: string
    email: string
    name: string
    totalTeachingHours: number
    className: string
    classId: string
    mode: 'ONLINE' | 'OFFLINE'
  }[] = []

  const classCol = space._meta.ref.collection('classes')
  const classQuery = classCol
    .where('status', 'in', ['ACTIVE'])
    .where('timeTracks.finishAt', '>=', range.startDate)
  const activeClasses = await getCollectionData<LearningSpaceClass>(
    classQuery,
    { idField: 'id' }
  )

  await Promise.all(
    activeClasses.map(async (klass) => {
      const teachingHours: { [k: string]: any } = {}
      const events = await getAllPublishedEventsInRange(klass, range)
      const instructors = await getActiveUsersByRole(
        klass._meta.ref,
        'instructor'
      )

      events.forEach((event) => {
        if (event.instructorsInCharge) {
          const countTeachingHours = (id: string) => {
            const key = event.mode + '@' + id
            if (!teachingHours[key]) teachingHours[key] = 0
            teachingHours[key] += event.duration || 0
          }

          event.instructorsInCharge.map(countTeachingHours)
        }
      })

      instructors.forEach((instructor) => {
        const id = instructor._meta.ref.id
        const online = 'ONLINE@' + id
        const offline = 'OFFLINE@' + id
        const data = {
          classId: klass._meta.ref.id,
          className: klass.name,
          email: instructor.email,
          name: instructor.name,
          id: id
        }
        if (!!teachingHours[online]) {
          results.push({
            ...data,
            totalTeachingHours: teachingHours[online],
            mode: 'ONLINE'
          })
        }
        if (!!teachingHours[offline]) {
          results.push({
            ...data,
            totalTeachingHours: teachingHours[offline],
            mode: 'OFFLINE'
          })
        }
      })
    })
  )

  return results
}

export const getCourse = async (
  spaceRef: firestore.DocumentReference,
  id: string
) => {
  const ref = spaceRef.collection('courses').doc(id)

  return getDocument<LearningSpaceCourse>(ref, { idField: 'id' })
}
