import React from 'react'
import { Typography, Divider } from '@material-ui/core'
import { styles } from './styles'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

interface IProps {
  title: any
  showDivider?: boolean
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  paddingTop?: number
  marginTop?: number
}

const PageHeader: React.FC<IProps> = (props) => {
  const {
    title,
    showDivider = true,
    heading = 'h5',
    paddingTop = 0,
    marginTop = 3
  } = props
  const classes = useStyles(() => {})

  const theme = useTheme()

  const PaddingTop = theme.spacing(paddingTop)
  const MarginTop = theme.spacing(marginTop)

  return (
    <>
      <div
        style={{ padding: PaddingTop, marginTop: MarginTop }}
        className={classes.container}
      >
        <Typography variant={heading} component="h1" gutterBottom>
          <b>{title}</b>
        </Typography>
        {showDivider && <Divider />}
      </div>
    </>
  )
}

export default PageHeader
