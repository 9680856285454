import { OrderStatusType } from 'types'
import { Theme } from '@material-ui/core/styles'

export function parseOrderStatus(status: OrderStatusType) {
  switch (status) {
    case 'WAITING_FOR_PAYMENT':
      return 'Chờ thanh toán'
    case 'PAID':
      return 'Đã thanh toán'
    case 'CANCELED':
      return 'Đã hủy'
  }
}

export function parseOrderStatusColor(status: OrderStatusType, theme: Theme) {
  switch (status) {
    case 'WAITING_FOR_PAYMENT':
      return theme.palette.warning.main
    case 'PAID':
      return theme.palette.success.main
    case 'CANCELED':
      return theme.palette.error.main
  }
}
