import {
  Box,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import InputLabel from 'components/InputLabel'
import UploadFiles from 'components/UploadFiles'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PreviewCertificateTemplate from 'widgets/PreviewCertificateTemplate'
import LoadableButton from '../../components/LoadableButton'
import { useLearningSpace } from '../../contexts/LearningSpaceContext'
import { useStorage } from 'reactfire'
import { uploadFile } from '../../utils/StorageUtils'
import { LearningSpaceCertificateTemplate } from '../../types'
import { omit } from 'lodash'
import { firestore } from 'firebase'
import { useSnackbar } from '../../contexts/SnackbarContext'
import { useHistory } from 'react-router-dom'
import PageHeaderAndBackBtn from 'components/PageHeaderAndBackBtn'

interface IProps {}

const FieldStyleInputs: React.FC<{ control: any; name: string }> = ({
  control,
  name
}) => {
  return (
    <Box display={'flex'} style={{ gap: 4 }} flexDirection={'row'} mt={1}>
      <Controller
        name={name + 'Color'}
        control={control}
        render={(field) => (
          <TextField
            {...field}
            type="color"
            variant="outlined"
            // onChange={}
            fullWidth
            size="small"
          ></TextField>
        )}
      />
      <Controller
        name={name + 'FontSize'}
        control={control}
        render={(field) => (
          <TextField
            {...field}
            type="number"
            variant="outlined"
            // onChange={}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">PX</InputAdornment>
            }}
            size="small"
          ></TextField>
        )}
      />
    </Box>
  )
}

const CertificateTemplateAdd: React.FC<IProps> = () => {
  const [file, setFile] = useState<null | File>(null)
  const [imageData, setImageData] = useState<string>('')

  const [saving, setSaving] = useState(false)

  const toast = useSnackbar()

  const history = useHistory()

  const learningSpace = useLearningSpace()
  const storage = useStorage()

  const { control, getValues, watch, setValue } = useForm({
    defaultValues: {
      width: 1280,
      height: 720,
      title: 'Học viện Agile chứng nhận',
      titleColor: '#231F20',
      titleFontSize: 16,
      subtitle: 'bạn đã hoàn thành khoá học',
      subtitleColor: '#231F20',
      subtitleFontSize: 16,
      nameColor: '#231F20',
      nameFontSize: 24,
      programColor: '#231F20',
      programFontSize: 24,
      certDateColor: '#231F20',
      certDateFontSize: 16,
      certBoxMarginTop: 100,
      certName: ''
    }
  })

  const handleSaveCertificateTemplate = useCallback(async () => {
    if (file && getValues('certName') && !saving) {
      setSaving(true)

      try {
        setSaving(true)
        const certificateRef = learningSpace._meta.ref
          .collection('certificate_templates')
          .doc()

        const backgroundStorageFolder =
          learningSpace._meta.ref.path +
          '/certificate_templates/' +
          certificateRef.id

        const url = await uploadFile(file, backgroundStorageFolder, storage)

        const ratio = getValues('height') / getValues('width')

        const htmlTemplate = `
      <div  style="width: ${
        getValues('width') + 'px'
      }; position: 'relative'; maxWidth: ${getValues('width') + 'px'}" >
          <img
            style="width: ${getValues('width') + 'px'}; aspectRatio: ${
          '1/' + ratio.toString()
        }"
            src="${url.publicUrl}"
            alt="cert image"
          />
          <div
          style="
            position: absolute;
            text-align: center;
            top: ${getValues('certBoxMarginTop') + 'px'};
            left: 0;
            right: 0;
            width: 50%;
            margin: auto
          "
          className="cert-info-box" 
      >
      <p style="color: ${getValues('titleColor')}; font-size: ${
          getValues('titleFontSize') + 'px'
        }" className="cert-info-title">{{title}}</p>
        <p style="color: ${getValues('nameColor')}; font-size: ${
          getValues('nameFontSize') + 'px'
        }; margin: 0" className="cert-info-student">
          <b>{{name}}</b>
        </p>
        <p  style="color: ${getValues('subtitleColor')}; font-size: ${
          getValues('subtitleFontSize') + 'px'
        }" className="cert-info-subtitle">{{sub_title}}</p>
        <p style="color: ${getValues('programColor')}; font-size: ${
          getValues('programFontSize') + 'px'
        }" className="cert-info-program-name">
          <b>{{source_title}}</b>
        </p>
        <p style="color: ${getValues('certDateColor')}; font-size: ${
          getValues('certDateFontSize') + 'px'
        }" className="cert-info-cert-date">{{date}}</p>
        </div>
      </div>
      `

        const certData: Omit<LearningSpaceCertificateTemplate, 'id'> = {
          title: getValues('certName'),
          certHtml: htmlTemplate,
          data: {
            ...(omit(getValues(), 'certName') as any)
          },
          ordering: new Date().getTime(),
          pdfHeight: getValues('height') + 20 + 'px',
          pdfWidth: getValues('width') + 20 + 'px',
          state: 'PUBLISHED',
          source: url.publicUrl,
          timeTracks: {
            createdAt: firestore.Timestamp.now()
          }
        }
        await certificateRef.set(certData, { merge: true })
        toast('Thêm mẫu chứng chỉ thành công!')
        history.push('/admin/certification-templates')
      } catch (e) {
        console.error(e)
        toast('Có lỗi xảy ra!', 'error')
      }
    }
  }, [
    file,
    getValues,
    saving,
    learningSpace._meta.ref,
    storage,
    toast,
    history
  ])

  useEffect(() => {
    if (file) {
      var reader = new FileReader()
      reader.onload = function () {
        var dataURL = reader.result as string

        var img = new Image()

        img.onload = function () {
          setValue('width', img.width)
          setValue('height', img.height)
        }

        img.src = dataURL

        setImageData(dataURL)
      }
      reader.readAsDataURL(file)
    }
  }, [file, setValue])

  return (
    <Container maxWidth="xl">
      <PageHeaderAndBackBtn
        label={'Thêm mẫu chứng chỉ'}
        backTo={`/admin/certification-templates`}
        backTooltipLabel={'Danh sách mẫu chứng chỉ'}
        showDivider
        style={{ marginTop: 10, paddingBottom: 10 }}
        heading="h5"
      />

      <Grid container spacing={2}>
        <Grid item md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Tên mẫu chứng chỉ</InputLabel>
              <Controller
                name="certName"
                control={control}
                render={(field) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    size="small"
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Ảnh nền</InputLabel>
              <UploadFiles
                onChange={(files) => {
                  if (files[0] && files[0] instanceof File) {
                    setFile(files[0])
                  }
                }}
              ></UploadFiles>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Chiều dài</InputLabel>
              <Controller
                name="height"
                control={control}
                render={(field) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    // onChange={}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">PX</InputAdornment>
                      )
                    }}
                  ></TextField>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel>Chiều rộng</InputLabel>
              <Controller
                name="width"
                control={control}
                render={(field) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    // onChange={}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">PX</InputAdornment>
                      )
                    }}
                  ></TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Tiêu đề</InputLabel>
              <Controller
                name="title"
                control={control}
                render={(field) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    // onChange={}
                    fullWidth
                    size="small"
                  ></TextField>
                )}
              />

              <FieldStyleInputs name="title" control={control} />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Tên học viên</InputLabel>

              <FieldStyleInputs name="name" control={control} />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Phụ đề</InputLabel>
              <Controller
                name="subtitle"
                control={control}
                render={(field) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    // onChange={}
                    fullWidth
                    size="small"
                  ></TextField>
                )}
              />
              <FieldStyleInputs name="subtitle" control={control} />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Tên chương trình</InputLabel>

              <FieldStyleInputs name="program" control={control} />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Thời gian chứng nhận</InputLabel>

              <FieldStyleInputs name="certDate" control={control} />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Vị trí nội dung</InputLabel>
              <Controller
                name="certBoxMarginTop"
                control={control}
                render={(field) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    // onChange={}
                    fullWidth
                    type="number"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">PX</InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          Phía trên
                        </InputAdornment>
                      )
                    }}
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadableButton
                disabled={!file || !watch('certName')}
                onClick={handleSaveCertificateTemplate}
                loading={saving}
                variant="contained"
                color="primary"
              >
                Lưu mẫu chứng chi
              </LoadableButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8}>
          {!!imageData && (
            <PreviewCertificateTemplate
              title={watch('title')}
              titleColor={watch('titleColor')}
              titleFontSize={watch('titleFontSize')}
              subtitle={watch('subtitle')}
              imageUrl={imageData}
              width={watch('width')}
              height={watch('height')}
              certBoxMT={watch('certBoxMarginTop')}
              subtitleColor={watch('subtitleColor')}
              subtitleFontSize={watch('subtitleFontSize')}
              nameColor={watch('nameColor')}
              nameFontSize={watch('nameFontSize')}
              programColor={watch('programColor')}
              programFontSize={watch('programFontSize')}
              certDateColor={watch('certDateColor')}
              certDateFontSize={watch('certDateFontSize')}
            />
          )}

          {!imageData && (
            <Typography align="center" variant="h6">
              Vui lòng tải lên ảnh nền của chứng chỉ
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default CertificateTemplateAdd
