import { useCallback, useEffect, useState } from 'react'

export const getLastId = (
  list: { id?: string }[],
  limit: number,
  pageTokenField: string = 'id'
): string | null => {
  if (list.length < limit) return null

  if (list.length === 0 || !list[list.length - 1]) return null

  return (list[list.length - 1] as any)[pageTokenField] || null
}

export const useCreatePagination = <T>(
  initData: T[],
  limit: number,
  fetcher: (pageToken: null | string) => Promise<T[]>,
  options: {
    pakeTokenField?: string
  } = {
    pakeTokenField: 'id'
  }
) => {
  const getInitStateData = useCallback(
    () => ({
      loading: false,
      data: initData,
      lastId: getLastId(initData, limit, options.pakeTokenField),
      showData: initData.slice(0, limit),
      page: 0
    }),
    [initData, limit, options.pakeTokenField]
  )

  const [state, setState] = useState<{
    loading: boolean
    lastId: string | null
    data: T[]
    showData: T[]
    page: number
  }>(getInitStateData())

  useEffect(() => setState(getInitStateData), [getInitStateData])

  const next = useCallback(() => {
    const nextPage = state.page + 1
    const from = nextPage * limit
    const to = from + limit

    const cacheData = state.data.slice(from, to)
    if (cacheData.length === limit) {
      setState((s) => ({ ...s, page: nextPage, showData: cacheData }))

      return
    }

    if (state.lastId) {
      fetcher(state.lastId).then((r) => {
        if (r?.length) {
          setState((s) => {
            const newData = [...s.data, ...r]

            const showData = newData.slice(from, to)
            return {
              ...s,
              page: nextPage,
              showData: showData,
              data: newData,
              lastId: getLastId(r, limit, options.pakeTokenField)
            }
          })
        } else {
          setState((s) => {
            const newState = {
              ...s,
              lastId: null
            }

            if (cacheData.length) {
              newState.showData = cacheData
              newState.page = nextPage
            }

            return newState
          })
        }
      })
    } else {
      setState((s) => {
        const newState = {
          ...s,
          lastId: null
        }

        if (cacheData.length) {
          newState.showData = cacheData
          newState.page = nextPage
        }

        return newState
      })
    }
  }, [
    state.data,
    state.page,
    limit,
    state.lastId,
    fetcher,
    options.pakeTokenField
  ])

  const previous = useCallback(() => {
    const nextPage = state.page - 1
    const from = nextPage * limit
    const to = from + limit

    const cacheData = state.data.slice(from, to)
    if (cacheData.length) {
      setState((s) => ({ ...s, page: nextPage, showData: cacheData }))
      return
    }
  }, [state.data, state.page, limit])

  return {
    loading: state.loading,
    data: state.showData,
    next,
    previous,
    canPrevious: state.page > 0 && !state.loading,
    canNext:
      (!!state.lastId || state.data.length > (state.page + 1) * limit) &&
      !state.loading
  }
}
