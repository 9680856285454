import React, { useState } from 'react'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { useHistory, useParams } from 'react-router-dom'
import { useDocumentData } from 'hooks'
import { LearningSpaceClass, LSProgramSurveyActivity } from 'types'
import ErrorBlock from 'components/ErrorBlock'
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { IFirestoreMetadata } from 'interfaces'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'contexts/SnackbarContext'
import LoadableButton from 'components/LoadableButton'
import { parseTimeByFormat, validateSurveyLink } from 'utils'
import { firestore } from 'firebase'
import firebase from 'firebase'

interface IBodyProps {
  classData: LearningSpaceClass & IFirestoreMetadata
  surveyLinkId: string
}

const LSClassDetailStudyEditSurveyLinkBody: React.FC<IBodyProps> = (props) => {
  const { classData, surveyLinkId } = props

  const surveyLinkData = useDocumentData<LSProgramSurveyActivity>(
    classData._meta.ref
      .collection('programs')
      .doc(classData.programId as string)
      .collection('activities')
      .doc(surveyLinkId),
    { idField: 'id' }
  )

  if (!surveyLinkData) {
    return <ErrorBlock variant={'404'} />
  }

  return (
    <LSClassDetailStudyEditSurveyLinkBodyBody
      surveyLinkData={surveyLinkData}
      classId={classData.id as string}
    />
  )
}

interface IBodyBodyProps {
  surveyLinkData: LSProgramSurveyActivity & IFirestoreMetadata
  classId: string
}

const LSClassDetailStudyEditSurveyLinkBodyBody: React.FC<IBodyBodyProps> = (
  props
) => {
  const { surveyLinkData, classId } = props
  const toast = useSnackbar()
  const history = useHistory()

  const [surveyLink, setSurveyLink] = useState<string>(surveyLinkData.data.link)
  const [googleFormId, setGoogleFormId] = useState<string>(surveyLinkData.data.formId || '')

  const [title, setTitle] = useState<string>(surveyLinkData.title)
  const [description, setDescription] = useState<string>(
    surveyLinkData.description
  )
  const [isRequired, setIsRequired] = useState<boolean>(
    surveyLinkData.isRequired
  )
  const [startAt, setStartAt] = useState<string>(
    parseTimeByFormat(
      (surveyLinkData.timeTracks.startAt as firestore.Timestamp).toDate(),
      'YYYY-MM-dd'
    ) as string
  )
  const [finishAt, setFinishAt] = useState<string>(
    parseTimeByFormat(
      (surveyLinkData.timeTracks.finishAt as firestore.Timestamp).toDate(),
      'YYYY-MM-dd'
    ) as string
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const onSurveyLinkTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSurveyLink(event.target.value)
  }

  const onTitleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTitle(event.target.value)
  }

  const onSurveyFormIdChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setGoogleFormId(event.target.value)
  }


  const onStartAtChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setStartAt(event.target.value)
  }
  const onFinishAtChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFinishAt(event.target.value)
  }

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRequired(event.target.checked)
  }

  const addSurveyLink = async () => {
    const $errors = validateSurveyLink(
      surveyLink,
      googleFormId,
      title,
      description,
      startAt,
      finishAt
    )
    if (isEmpty($errors)) {
      try {
        setLoading(true)

        await surveyLinkData._meta.ref.set(
          {
            data: { link: surveyLink, formId: googleFormId },
            title,
            isRequired,
            description,
            timeTracks: {
              ...surveyLinkData.timeTracks,
              startAt: firebase.firestore.Timestamp.fromDate(new Date(startAt)),
              finishAt: firebase.firestore.Timestamp.fromDate(
                new Date(finishAt)
              )
            }
          },
          { merge: true }
        )

        setErrors({})
        setLoading(false)
        toast('Sửa bài khảo sát thành công!')
        history.push(`/admin/classes/${classId}/study`)
      } catch (e) {
        console.error(e)
        setLoading(false)
        setErrors({})
        toast('Xảy ra lỗi không xác định', 'error')
      }
    } else {
      setErrors($errors)
    }
  }

  return (
    <>
      <Box mt={2}>
        <Typography variant={'h6'}>Sửa bài khảo sát</Typography>
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequired}
              color={'primary'}
              onChange={onCheckboxChange}
            />
          }
          label={'Bắt buộc'}
        />
        <Box mt={1} mb={2}>
          <Grid container>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box mb={1}>
                  <TextField
                    fullWidth
                    helperText={errors.title}
                    error={Boolean(errors.title)}
                    onChange={onTitleTextChange}
                    value={title}
                    variant={'outlined'}
                    placeholder={'Nhập tiêu đề...'}
                  />
                </Box>
                <TextField
                  helperText={errors.surveyLink}
                  error={Boolean(errors.surveyLink)}
                  onChange={onSurveyLinkTextChange}
                  value={surveyLink}
                  variant={'outlined'}
                  placeholder={'Nhập liên kết...'}
                />
                <Box mt={1}>
                  <TextField
                    fullWidth
                    helperText={errors.googleFormId}
                    error={Boolean(errors.googleFormId)}
                    onChange={onSurveyFormIdChange}
                    value={googleFormId}
                    variant={'outlined'}
                    placeholder={'Nhập google form id'}
                  />
                </Box>
                <Box mb={2} mt={2} display="flex">
                  <TextField
                    id="date-start"
                    label="Thời gian bắt đầu"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    fullWidth
                    style={{ marginRight: 8 }}
                    value={startAt}
                    onChange={onStartAtChange}
                    error={Boolean(errors.startAt)}
                    helperText={errors.startAt}
                  />
                  <TextField
                    id="date-finish"
                    label="Thời gian kết thúc"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    fullWidth
                    style={{ marginLeft: 8 }}
                    value={finishAt}
                    onChange={onFinishAtChange}
                    error={Boolean(errors.finishAt)}
                    helperText={errors.finishAt}
                  />
                </Box>
                <TextField
                  label="Mô tả"
                  multiline
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  rows={4}
                  fullWidth
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  error={Boolean(errors.description)}
                  helperText={errors.description}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <LoadableButton
          loading={loading}
          onClick={addSurveyLink}
          variant={'contained'}
          color={'primary'}
          disabled={
            surveyLink === '' ||
            title === '' ||
            description === '' ||
            startAt === '' ||
            finishAt === ''
          }
        >
          Lưu
        </LoadableButton>
      </Box>
    </>
  )
}

const LSClassDetailStudyEditSurvey: React.FC = () => {
  const learningSpace = useLearningSpace()
  const { id, surveyLinkId } = useParams<{
    id: string
    surveyLinkId: string
  }>()

  const classData = useDocumentData<LearningSpaceClass>(
    learningSpace._meta.ref.collection('classes').doc(id),
    { idField: 'id' }
  )

  if (!learningSpace || !classData) return <ErrorBlock variant="404" />
  return (
    <Container>
      <LSClassDetailStudyEditSurveyLinkBody
        classData={classData}
        surveyLinkId={surveyLinkId}
      />
    </Container>
  )
}

export default LSClassDetailStudyEditSurvey
