import { validateUrl } from './ValidateUrl'

export function validateSurveyLink(
  surveyLink: string,
  googleFormId: string,
  title: string,
  description: string,
  startAt: string,
  finishAt: string
) {
  let errors: { [key: string]: string } = {}

  if (!googleFormId) {
    errors.googleFormId = 'Không được bỏ trống'
  }

  if (!surveyLink) {
    errors.surveyLink = 'Không được bỏ trống'
  } else {
    if (!validateUrl(surveyLink)) {
      errors.surveyLink = 'Liên kết không hợp lệ'
    }
  }
  if (!title) {
    errors.title = 'Không được bỏ trống'
  }
  if (!description) {
    errors.description = 'Không được bỏ trống'
  }
  if (!startAt) {
    errors.startAt = 'Không được bỏ trống'
  }
  if (!finishAt) {
    errors.finishAt = 'Không được bỏ trống'
  }
  if (new Date(startAt) >= new Date(finishAt)) {
    errors.finishAt = 'Ngày kết thúc phải sau ngày bắt đầu'
  }
  return errors
}
