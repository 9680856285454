import * as rf from 'reactfire'
import { IFirestoreMetadata } from 'interfaces'
import { useReducer, useEffect, Dispatch, useState } from 'react'
import { metaRef } from './firestoreHooks'
import _ from 'lodash'

declare type IQueryPaginationAction = {
  query?: firebase.firestore.Query
  task?: 'next' | undefined | 'previous'
  type: 'onChange' | 'clearTask' | 'init'
}
declare type IQueryPagination = {
  query: firebase.firestore.Query
  task: 'next' | undefined | 'previous'
}

interface IPagination {
  items: IFirestoreMetadata[] | undefined
  lastVisible: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null
  firstVisible: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null
  hasNext: boolean
  hasPrevious: boolean
  currentPage: number
}

const CACHED_QUERY: firebase.firestore.Query[] = []

const getIndexQueryCached = (query: firebase.firestore.Query): number => {
  const index = _.findIndex(CACHED_QUERY, (cachedQuery) =>
    cachedQuery.isEqual(query)
  )

  if (index > -1) {
    return index
  }

  return CACHED_QUERY.push(query) - 1
}

const valueInitPaginationReducer = {
  items: undefined,
  lastVisible: null,
  firstVisible: null,
  hasNext: false,
  hasPrevious: false,
  currentPage: 0
}

function paginationReducer<T>(
  state: IPagination,
  actions: {
    value: {
      items: Array<T & IFirestoreMetadata> | undefined
      lastVisible: firebase.firestore.DocumentSnapshot | null
      firstVisible: firebase.firestore.DocumentSnapshot | null
      hasNext: boolean
      hasPrevious: boolean
      currentPage: number
    }
  }
) {
  return { ...actions.value }
}

const queryReducer = (
  state: IQueryPagination,
  action: IQueryPaginationAction
) => {
  if (action.type === 'clearTask') {
    return { ...state, task: undefined }
  }
  if (action.type === 'onChange' && action.query) {
    return { task: action.task, query: action.query }
  }
  return { ...state }
}

function convertDataQuerySnapshot<T>(
  querySnapshot: firebase.firestore.QuerySnapshot,
  options: rf.ReactFireOptions<T[]> = { idField: 'id' }
) {
  const data: Array<T & IFirestoreMetadata> = []
  querySnapshot.forEach((doc) => {
    data.push(
      metaRef(
        {
          ...doc.data(),
          ...(options?.idField ? { [options.idField]: doc.id } : null)
        },
        doc.ref
      ) as T & IFirestoreMetadata
    )
  })
  return data
}

export function useQueryPagination(
  queryInput: firebase.firestore.Query,
  limit: number
): [IQueryPagination, Dispatch<IQueryPaginationAction>] {
  const [query, dispatchQuery] = useReducer(queryReducer, {
    query: queryInput.limit(limit),
    task: undefined
  })

  const queryInputIndex = getIndexQueryCached(queryInput)

  useEffect(() => {
    dispatchQuery({
      query: queryInput.limit(limit),
      task: undefined,
      type: 'onChange'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInputIndex, limit])

  return [query, dispatchQuery]
}

function getCurrentPage(
  prePage: number,
  task: undefined | 'next' | 'previous'
): number {
  switch (task) {
    case 'next':
      return prePage + 1
    case 'previous':
      return prePage - 1
    default:
      return 0
  }
}

const hasPreviousPage = async (
  queryInput: firebase.firestore.Query,
  firstVisible: firebase.firestore.DocumentSnapshot | undefined
) => {
  if (firstVisible) {
    const previous = await queryInput
      .endBefore(firstVisible)
      .limitToLast(1)
      .get()
    return !!previous.docs.length
  }
  return false
}

const hasNextPage = async (
  queryInput: firebase.firestore.Query,
  lastVisible: firebase.firestore.DocumentSnapshot | undefined
) => {
  if (lastVisible) {
    const next = await queryInput.startAfter(lastVisible).limit(1).get()
    return !!next.docs.length
  }
  return false
}

export async function processDataRawPagination<T>(
  snapshots: firebase.firestore.QuerySnapshot,
  oldPagination: IPagination,
  queryInput: firebase.firestore.Query,
  task: undefined | 'next' | 'previous'
) {
  const items = convertDataQuerySnapshot<T>(snapshots)
  const currentPage = getCurrentPage(oldPagination.currentPage, task)

  const lasVisible = _.last(snapshots.docs)
  const firstVisible = _.first(snapshots.docs)
  const hasNext = await hasNextPage(queryInput, lasVisible)
  const hasPrevious = await hasPreviousPage(queryInput, firstVisible)

  return {
    items: items,
    firstVisible: snapshots.docs[0],
    lastVisible: snapshots.docs[snapshots.docs.length - 1],
    hasPrevious: hasPrevious,
    hasNext: hasNext,
    currentPage: currentPage
  }
}

export function usePagination<T>(
  queryInput: firebase.firestore.Query,
  limit: number
): {
  items: Array<T & IFirestoreMetadata>
  hasPrevious: boolean
  hasNext: boolean
  previous: () => void
  next: () => void
  currentPage: number
  loading: boolean
} {
  const [pagination, dispatchPagination] = useReducer(
    paginationReducer,
    valueInitPaginationReducer
  )

  const [loading, setLoading] = useState(true)

  const [query, setQuery] = useQueryPagination(queryInput, limit)

  const queryIndex = getIndexQueryCached(query.query)
  useEffect(() => {
    setLoading(true)
    const subscriber = query.query.onSnapshot(
      async (snapshots) => {
        const paginationData = await processDataRawPagination<T>(
          snapshots,
          pagination,
          queryInput,
          query.task
        )

        dispatchPagination({ value: paginationData })
        setLoading(false)
      },
      (e) => {
        console.error(e)
        setLoading(false)
      }
    )

    return () => {
      subscriber()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryIndex])

  function next() {
    if (pagination.hasNext) {
      setQuery({
        query: queryInput.startAfter(pagination.lastVisible).limit(limit),
        task: 'next',
        type: 'onChange'
      })
    }
  }

  function previous() {
    if (pagination.hasPrevious) {
      setQuery({
        query: queryInput.endBefore(pagination.firstVisible).limitToLast(limit),
        task: 'previous',
        type: 'onChange'
      })
    }
  }

  return {
    items: (pagination.items as Array<T & IFirestoreMetadata>) || [],
    hasPrevious: pagination.hasPrevious,
    hasNext: pagination.hasNext,
    previous: previous,
    next: next,
    currentPage: pagination.currentPage,
    loading: loading
  }
}
