import React, { useCallback, useContext, useState } from 'react'
import ConfirmationDialog from 'components/ConfirmationDialog'

interface IProps {}

const ConfirmContext = React.createContext<
  (model: {
    title?: string
    description: string
    content?: string
    confirmationText?: string
    cancellationText?: string
  }) => Promise<any>
>(() => Promise.resolve(false))

export const useConfirm = () => useContext(ConfirmContext)

const DEFAULT_OPTIONS = {
  title: 'Are you sure?',
  description: '',
  content: null,
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {}
}

const buildOptions = (defaultOptions: any, options: any) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {})
  }
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps ||
      DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {})
  }
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps ||
      DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {})
  }

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps
  }
}

const ConfirmProvider: React.FC<IProps> = ({ children }) => {
  const [options, setOptions] = useState<any>({ ...DEFAULT_OPTIONS })
  const [resolveReject, setResolveReject] = useState<(() => void)[]>([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback(
    (options: {
      title?: string
      description: string
      content?: string
      confirmationText?: string
      cancellationText?: string
    }) => {
      return new Promise((resolve, reject) => {
        setOptions(buildOptions({}, options))
        setResolveReject([resolve, reject])
      })
    },
    []
  )

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    reject()
    handleClose()
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    resolve()
    handleClose()
  }, [resolve, handleClose])

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>

      <ConfirmationDialog
        onConfirm={handleConfirm}
        onClose={handleCancel}
        onCancel={handleCancel}
        open={resolveReject.length === 2}
        options={options}
      />
    </>
  )
}

export default ConfirmProvider
