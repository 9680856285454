import { IFirestoreMetadata } from 'interfaces'
import React, { useContext } from 'react'
import {  LearningSpaceCourse } from 'types'

const SpaceCourseContext = React.createContext<
  ((LearningSpaceCourse) & IFirestoreMetadata) | undefined
>(undefined)

export const useSpaceCourse = () => {
  return useContext(SpaceCourseContext) as (LearningSpaceCourse) &
    IFirestoreMetadata
}

export default SpaceCourseContext
