import { Box, InputLabel, TextField, Typography } from '@material-ui/core'
import ErrorBlock from 'components/ErrorBlock'
import Gadget from 'components/Gadget'
import LoadableButton from 'components/LoadableButton'
import { useLearningSpaceClass } from 'contexts/LearningSpaceClassContext'
import firebase from 'firebase'
import { useDocumentData, useQueryParams } from 'hooks'
import { useClassSections, useClassSectionsAssoc } from 'hooks/Classes'
import { useSchemeSectionsAssoc } from 'hooks/useScheme'
import { IFirestoreMetadata } from 'interfaces'
import { get } from 'lodash'
import React, { Fragment, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useFirestore } from 'reactfire'
import {
  LearningSpaceClass,
  LearningSpaceProgramSection,
  SchemeType
} from 'types'
import { setHours } from 'date-fns'
import { useHistory } from 'react-router-dom'

interface BodyProps {
  schemeInfo: SchemeType & IFirestoreMetadata
  classInfo: LearningSpaceClass & IFirestoreMetadata
}

interface IFormInput {
  [id: string]: {
    startAt: Date
    endAt: Date
  }
}

const getTimeTrackDate = (day: Date | string) => {
  return setHours(new Date(day), 0)
}

const LSClassFromSchemeConfigureBody: React.FC<BodyProps> = (props) => {
  const { classInfo, schemeInfo } = props
  const [saving, setSaving] = useState(false)
  const db = useFirestore()
  const classSections = useClassSections(classInfo)
  const classSectionsAssoc = useClassSectionsAssoc(classInfo)
  const schemeSectionsAssoc = useSchemeSectionsAssoc(schemeInfo)
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<IFormInput>()
  const history = useHistory()

  const navigateToClassDetail = () => {
    const redirectUrl = `/admin/classes/${classInfo._meta.ref.id}/study`
    history.push(redirectUrl)
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      setSaving(true)
      const batch = db.batch()
      const configuredSectionIds = Object.keys(data)
      configuredSectionIds.forEach((sectionId) => {
        const classSectionData = classSectionsAssoc[sectionId]
        if (classSectionData) {
          batch.set(
            classSectionData._meta.ref,
            {
              timeTracks: {
                ...classSectionData.timeTracks,
                startAt: firebase.firestore.Timestamp.fromDate(
                  getTimeTrackDate(data[sectionId].startAt)
                ),
                endAt: firebase.firestore.Timestamp.fromDate(
                  getTimeTrackDate(data[sectionId].endAt)
                )
              }
            },
            { merge: true }
          )
        }
      })

      await batch.commit()
      navigateToClassDetail()
    } catch (e) {
      console.error(e)
    } finally {
      setSaving(false)
    }
  }
  const needConfigureSections = useMemo(() => {
    const result: (LearningSpaceProgramSection & IFirestoreMetadata)[] = []
    classSections.forEach((section) => {
      if (
        get(
          schemeSectionsAssoc[section.id as string],
          'requirements.configureLearningSchedule',
          false
        )
      ) {
        result.push(section)
      }
    })
    return result
  }, [classSections, schemeSectionsAssoc])

  return (
    <>
      <Box mb={1}>
        <Typography color="secondary" variant="caption">
          Bạn cần hoàn thiện thông tin cho các mục dưới đây:
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {needConfigureSections.map((section) => {
          return (
            <Fragment key={section.id}>
              <Typography>
                <b>{section.title}</b>
              </Typography>
              <Box mt={2} mb={5} display={'flex'} alignItems={'center'}>
                <Box>
                  <Controller
                    rules={{ required: 'Bạn phải nhập thông tin này' }}
                    name={section.id + '.startAt'}
                    control={control}
                    render={({ ...field }) => (
                      <>
                        <InputLabel>Thời gian bắt đầu</InputLabel>
                        <TextField
                          variant="outlined"
                          size="small"
                          {...field}
                          type={'date'}
                          error={Boolean(
                            get(
                              errors,
                              `${section.id as string}.startAt.message`
                            )
                          )}
                          helperText={get(
                            errors,
                            `${section.id as string}.startAt.message`
                          )}
                        />
                      </>
                    )}
                  />
                </Box>
                <Box ml={3}>
                  <Controller
                    rules={{
                      required: 'Bạn phải nhập thông tin này',
                      validate: {
                        minDate: (endDate) =>
                          new Date(endDate) >
                          new Date(watch(section.id as string).startAt)
                            ? true
                            : 'Ngày kết thúc phải sau ngày bắt đầu'
                      }
                    }}
                    name={section.id + '.endAt'}
                    control={control}
                    render={({ ...field }) => (
                      <>
                        <InputLabel>Thời gian kết thúc</InputLabel>
                        <TextField
                          {...field}
                          variant="outlined"
                          size="small"
                          type={'date'}
                          error={Boolean(
                            get(errors, `${section.id as string}.endAt.message`)
                          )}
                          helperText={get(
                            errors,
                            `${section.id as string}.endAt.message`
                          )}
                        />
                      </>
                    )}
                  />
                </Box>
              </Box>
            </Fragment>
          )
        })}

        <LoadableButton
          variant="contained"
          color="primary"
          type="submit"
          loading={saving}
        >
          Hoàn tất
        </LoadableButton>
      </form>
    </>
  )
}

const LSClassFromSchemeConfigure: React.FC = () => {
  const classInfo = useLearningSpaceClass()
  const query = useQueryParams()
  const schemeId = query.get('schemeId')
  const db = useFirestore()
  const schemeInfo = useDocumentData<SchemeType>(
    db.collection('schemes').doc(schemeId as string)
  )

  if (!schemeInfo || !classInfo || schemeInfo.state !== 'PUBLISHED')
    return <ErrorBlock variant="404" />

  return (
    <>
      <Gadget title={`Hoàn tất cài đặt lớp`}>
        <LSClassFromSchemeConfigureBody
          classInfo={classInfo}
          schemeInfo={schemeInfo}
        />
      </Gadget>
    </>
  )
}

export default LSClassFromSchemeConfigure
