import { useCollectionData, useAssocCollectionData } from 'hooks/firestoreHooks'
import { SchemeSectionType, SchemeType } from 'types'
import { IFirestoreMetadata } from 'interfaces'

export const useSchemeSections = (scheme: SchemeType & IFirestoreMetadata) => {
  const schemeSectionsRef = scheme._meta.ref
    .collection('sections')
    .where('state', '==', 'PUBLISHED')
    .orderBy('ordering')
  return useCollectionData<SchemeSectionType>(schemeSectionsRef, {
    idField: 'id'
  })
}

export const useSchemeSectionsAssoc = (
  scheme: SchemeType & IFirestoreMetadata
) => {
  const schemeSectionsRef = scheme._meta.ref
    .collection('sections')
    .where('state', '==', 'PUBLISHED')
    .orderBy('ordering')
  return useAssocCollectionData<SchemeSectionType & IFirestoreMetadata>(
    schemeSectionsRef,
    'id',
    {
      idField: 'id'
    }
  )
}
