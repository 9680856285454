import { useFirestore } from 'reactfire'
import {
  ClassCourseCompleteRateStatistics,
  ClassEventParticipationRate,
  ClassReflectionSubmitRateStatistics
} from 'types'
import { useEffect, useState } from 'react'
import { IFirestoreMetadata } from 'interfaces'
import { getCollectionData } from 'utils/FirestoreUtils'
import { splitItems } from 'utils'

const MAX_QUERY_IN_VALUE = 10
const splitSectionIds = (sectionIds: string[]) =>
  splitItems<string>(sectionIds, MAX_QUERY_IN_VALUE)

export const useReflectionStatistics = (sectionIds: string[]) => {
  const db = useFirestore()

  const [result, setResult] = useState<
    (ClassReflectionSubmitRateStatistics & IFirestoreMetadata)[]
  >([])

  useEffect(() => {
    ;(async () => {
      const splitedSectionIds = splitSectionIds(sectionIds)
      let data: (ClassReflectionSubmitRateStatistics & IFirestoreMetadata)[] =
        []
      for (const sectionIds of splitedSectionIds) {
        const reflectionStatisticsRef = db
          .collection('/statistics/general/class_reflection_submit_rate')
          .where('sectionId', 'in', sectionIds)

        const statisticData =
          await getCollectionData<ClassReflectionSubmitRateStatistics>(
            reflectionStatisticsRef
          )
        data = [...data, ...statisticData]
      }
      setResult(data)
    })()
  }, [db, sectionIds])

  return result
}

export const useCourseStatistics = (sectionIds: string[]) => {
  const db = useFirestore()
  const [result, setResult] = useState<
    (ClassCourseCompleteRateStatistics & IFirestoreMetadata)[]
  >([])

  useEffect(() => {
    ;(async () => {
      const splitedSectionIds = splitSectionIds(sectionIds)
      let data: (ClassCourseCompleteRateStatistics & IFirestoreMetadata)[] = []
      for (const sectionIds of splitedSectionIds) {
        const courseStatisticsRef = db
          .collection('/statistics/general/class_course_complete_rate')
          .where('sectionId', 'in', sectionIds)

        const statisticData =
          await getCollectionData<ClassCourseCompleteRateStatistics>(
            courseStatisticsRef
          )
        data = [...data, ...statisticData]
      }
      setResult(data)
    })()
  }, [db, sectionIds])

  return result
}

export const useEventStatistics = (sectionIds: string[]) => {
  const db = useFirestore()
  const [result, setResult] = useState<
    (ClassEventParticipationRate & IFirestoreMetadata)[]
  >([])
  useEffect(() => {
    ;(async () => {
      const splitedSectionIds = splitSectionIds(sectionIds)
      let data: (ClassEventParticipationRate & IFirestoreMetadata)[] = []
      for (const sectionIds of splitedSectionIds) {
        const eventStatisticsRef = db
          .collection('/statistics/general/class_event_participation_rate')
          .where('sectionId', 'in', sectionIds)

        const statisticData =
          await getCollectionData<ClassEventParticipationRate>(
            eventStatisticsRef
          )
        data = [...data, ...statisticData]
      }
      setResult(data)
    })()
  }, [db, sectionIds])

  return result
}
