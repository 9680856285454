import app from './app.json'
import lecture from './lecture.config.json'

export declare type ConfigType = {
  app: {
    siteName: string
    siteSlogan: string
    siteDesc: string
    companyName: string
    appGooglePlayUrl?: string
    appAppStoreUrl?: string
    privacyPolicy?: string
  }
  lecture: {
    activities: string[]
  }
}

const config: ConfigType = {
  app: app,
  lecture: lecture
}

export default config
