import React, { useContext } from 'react'

export function parseSubdomain(): string | undefined {
  const regexParse = new RegExp(/^([a-z-0-9]{2,63})(\.([a-z]+)){2}$/)
  const urlParts = regexParse.exec(window.location.hostname)

  if (urlParts) return urlParts[1]
  return undefined
}

export function parseBaseDomain(): string {
  const regexParse = new RegExp('^[a-z-0-9]{2,63}.[a-z.]{2,5}$')
  let baseDomain = regexParse.exec(window.location.hostname)
  if (baseDomain) {
    if (window.location.port) {
      return baseDomain[0] + ':' + window.location.port
    }
    return baseDomain[0]
  }
  return ''
}

export function parseDomain(subdomain: string): string {
  let domain =
    window.location.protocol + '//' + subdomain + '.' + parseBaseDomain()
  return domain
}

const SubdomainContext = React.createContext<{ domain?: string }>({})

export const useLearningSpaceContext = () => {
  return useContext(SubdomainContext)
}

export default SubdomainContext
