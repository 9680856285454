import React from 'react'

import { Link } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

interface IProps {
  name: string
  to: string
}

const MaybachLink: React.FC<IProps> = (props) => {
  const { name, to } = props

  const history = useHistory()

  const navigateTo = (route: string) => {
    history.push(route)
  }

  return (
    <>
      <Link onClick={() => navigateTo(to)} style={{ cursor: 'pointer' }}>
        {name}
      </Link>
    </>
  )
}

export default MaybachLink
