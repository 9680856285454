import React from 'react'
import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { styles } from './styles'

const useStyle = makeStyles(styles)

interface IProps {
  size?: CircularProgressProps['size']
  style?: BoxProps['style']
}

const Loading: React.FC<IProps> = ({ size, style }) => {
  const classes = useStyle()
  return (
    <Box style={style} className={classes.loadingCtn}>
      <CircularProgress size={size} />
    </Box>
  )
}

export default Loading
