import React, { useMemo } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import { debounce } from 'lodash'

interface IProps {
  wait?: number
  textFieldProps?: TextFieldProps
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
}

const TextFieldDebounce: React.FC<IProps> = (props) => {
  const { wait = 300, textFieldProps = {}, onChange, onClick } = props

  const debounceOnChange = useMemo(
    () =>
      debounce((value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(value, e)
        }
      }, wait),
    [wait, onChange]
  )

  return (
    <TextField
      {...textFieldProps}
      onClick={onClick}
      onChange={(event) => {
        event.persist()
        debounceOnChange(event.target.value, event as any)
      }}
    />
  )
}

export default TextFieldDebounce
