import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { styles } from './styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

interface IProps {
  importTypeTitle: string
  type?: 'import-users' | 'import-class-people' | 'import-question-bank'
}

const FIELDS = {
  'import-users': [
    {
      f: 'email',
      d: 'email của học viên'
    },
    {
      f: 'name',
      d: 'tên của học viên'
    },
    {
      f: 'password',
      d: 'mật khẩu gồm 8 ký tự trở lên'
    },
    {
      f: 'gender',
      d: '(Không bắt buộc) chỉ được phép sử dụng "MALE" hoặc "FEMALE"'
    },
    {
      f: 'birthday',
      d: '(Không bắt buộc) ngày sinh nhật bắt buộc phải theo cú pháp ngày/tháng/năm (ví dụ: 22/11/2001)'
    }
  ],
  'import-class-people': [
    {
      f: 'email',
      d: 'email của học viên'
    },
    {
      f: 'name',
      d: 'tên của học viên'
    },
    {
      f: 'password',
      d: 'mật khẩu gồm 8 ký tự trở lên'
    },
    {
      f: 'role',
      d: 'Chức vụ của người dùng trong lớp: ( STUDENT hoặc ASSISTANT hoặc INSTRUCTOR )'
    }
  ],
  'import-question-bank': [
    {
      f: 'type',
      d: 'loại câu Q: question hoặc A: answer'
    },
    {
      f: 'content',
      d: 'nội dung của câu'
    },
    {
      f: 'bloom_ladder',
      d: 'thang nhận thức bloom: CREATING, EVALUATING, ANALYZING, APPLYING, UNDERSTANDING, REMEMBERING (Không bắt buộc khi loại câu là A: answer)'
    },
    {
      f: 'question_type',
      d: 'loại câu hỏi: SINGLE, MULTIPLE (Không bắt buộc khi loại câu là A: answer)'
    },
    {
      f: 'correct',
      d: 'trạng thái câu trả lời: TRUE hoặc FALSE (Không bắt buộc khi loại câu là Q: question)'
    },
    {
      f: 'helper_text',
      d: 'phản hồi (Không bắt buộc khi loại câu là Q: question)'
    }
  ]
}

const ImportDescription: React.FC<IProps> = (props) => {
  const classes = useStyles(() => {})

  const { type = 'import-users' } = props

  const fields = FIELDS[type]

  return (
    <>
      <Typography className={classes.typography}>
        Cung cấp danh sách {props.importTypeTitle} bằng cách tải lên file excel
        gồm các trường sau:
      </Typography>
      <Box ml={1}>
        {fields.map((field) => (
          <Typography key={field.f} className={classes.typography}>
            - <b>{field.f}</b>: {field.d}
          </Typography>
        ))}
      </Box>
      <Typography className={classes.typography}>
        {type !== 'import-question-bank' && (
          <i>
            Chú ý: <b>name</b> và <b>password</b> được dùng để tạo tài khoản cho
            học viên trong trường hợp học viên chưa có tài khoản, nếu học viên
            đã có tài khoản thì hệ thống sẽ không tạo tài khoản mới, tên và mật
            khẩu của học viên sẽ được cập nhật lại theo thông tin trong danh
            sách nhập. Nếu học viên đã có tài khoản nhưng chưa tham gia không
            gian không gian học tập này thì học viên sẽ tự động được thêm vào
            không gian học tập.
          </i>
        )}
      </Typography>
    </>
  )
}

export default ImportDescription
