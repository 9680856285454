export const STATE_OF_RECORD = {
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED'
}

export const COLORS = {
  BASIC_BLUE: '#B4DFF8',
  PURPLE: '#483392',
  WHITE: '#FFF',
  BLACK_80: 'rgba(1, 1, 1, 0.8)',
  BLACK: '#000',
  ORANGE: '#f37029'
}

export const TITLE_SIDEBAR = {
  HOME: 'Trang chủ',
  STUDENT: 'Học viên',
  COURSE: 'Khóa học',
  CERTIFICATION: 'Chứng chỉ',
  CLASS: 'Lớp học',
  REPORT: 'Báo cáo',
  EXPLORE: 'Khám phá',
  CUSTOM_COURSE: 'Danh sách khoá học',
  BUG_REPORT: 'Báo lỗi',
  LECTURE: 'Bài học',
  QUESTION_BANK: 'Ngân hàng câu hỏi',
  CERTIFICATION_TEMPLATES: 'Chứng chỉ khóa học',
  REPORT_CLASS: 'Báo cáo lớp học',
  STUDENT_GRADE_REPORT: 'Báo cáo học viên',
  STATISTIC_TEACHING_HOUR: 'Thống kê giờ dạy',
  WORKFLOW_REPORT: 'Báo cáo quy trình',
  COMPARE_CLASSES: 'So sánh chỉ số lớp học'
}

export const TITLE = {
  MY_ORDERS: 'Đơn hàng của tôi',
  CERTIFICATE: 'Chứng chỉ',
  PERSONAL_INFO: 'Trang cá nhân',
  ADD_STUDENT: 'Thêm học viên',
  IMPORT_CERTIFIED: 'Cấp chứng chỉ',
  ADD_CERTIFICATION_TEMPLATE: 'Thêm mẫu chứng chỉ',
  CREATE_EXAM: 'Tạo kỳ thi',
  CREATE_CLASS: 'Thêm mới lớp học',
  ADD_QUIZ: 'Thêm bài kiểm tra',
  ADD_LECTURE: 'Thêm bài học',
  EDIT_LECTURE: 'Sửa bài học',
  CREATE_COURSE: 'Thêm khóa học',
  ADD_QUESTION: 'Thêm câu hỏi',
  EDIT_QUESTION: 'Sửa câu hỏi',
  IMPORT_QUESTION: 'Thêm ngân hàng câu hỏi'
}

export const LEARNING_SPACE = {
  KY_NANG_SONG: 'kynangsong',
  NAL: 'nal'
}

export const SUBJECTS = [
  {
    id: 'math',
    label: 'Môn toán'
  },
  {
    id: 'english',
    label: 'Môn tiếng anh'
  },
  {
    id: 'MAMNON',
    label: 'Mầm non'
  },
  {
    id: 'TIEUHOC',
    label: 'Tiểu Học'
  },
  {
    id: 'THCS',
    label: 'THCS'
  },
  {
    id: 'KNS-TIEUHOC',
    label: 'Kỹ năng sống Tiểu Học'
  },
  {
    id: 'KNS-THCS',
    label: 'Kỹ năng sống THCS'
  },
  {
    id: 'STEAM-MN',
    label: 'STEAM Mầm non'
  },
  {
    id: 'KNS-MN',
    label: 'Kỹ năng sống Mầm non'
  }
]

export const BLOCKS = [
  {
    id: 'steam',
    label: 'STEAM'
  },
  {
    id: 'kns',
    label: 'Kỹ năng sống'
  },
  {
    id: '0',
    label: 'Mầm non'
  },
  {
    id: '1-3',
    label: '1 - 3 tuổi'
  },
  {
    id: '3-5',
    label: '3 - 5 tuổi'
  },
  {
    id: '3-4',
    label: '3 - 4 tuổi'
  },
  {
    id: '4-5',
    label: '4 - 5 tuổi'
  },
  {
    id: '5-6',
    label: '5 - 6 tuổi'
  },
  {
    id: '8-10',
    label: '8 - 10 tuổi'
  },
  {
    id: 'grade_1',
    label: 'Lớp 1'
  },
  {
    id: 'grade_2',
    label: 'Lớp 2'
  },
  {
    id: 'grade_3',
    label: 'Lớp 3'
  },
  {
    id: 'grade_4',
    label: 'Lớp 4'
  },
  {
    id: 'grade_5',
    label: 'Lớp 5'
  },
  {
    id: 'grade_6',
    label: 'Lớp 6'
  },
  {
    id: 'grade_7',
    label: 'Lớp 7'
  },
  {
    id: 'grade_8',
    label: 'Lớp 8'
  },
  {
    id: 'grade_9',
    label: 'Lớp 9'
  }
]
