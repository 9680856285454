import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import InputLabel from 'components/InputLabel'
import LoadableButton from 'components/LoadableButton'
import Loading from 'components/Loading'
import { useLearningSpaceClass } from 'contexts/LearningSpaceClassContext'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { useSnackbar } from 'contexts/SnackbarContext'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useFirestore } from 'reactfire'
import { LearningSpaceClassFeedbackEndOfClass } from 'types'
import { timestampNow } from 'utils'
import { getDocument } from 'utils/FirestoreUtils'

interface IProps {}

declare type FormData = Pick<
  LearningSpaceClassFeedbackEndOfClass,
  'statistics' | 'studentFeedback' | 'businessFeedback'
>

const FeedbackEndOfClassForm: React.FC<{
  feedback: LearningSpaceClassFeedbackEndOfClass | null
}> = ({ feedback }) => {
  const form = useForm<FormData>({
    defaultValues: {
      businessFeedback: {
        feedback: feedback?.businessFeedback?.feedback || '',
        npsIndex: feedback?.businessFeedback?.npsIndex || 0,
        overallFeedback: feedback?.businessFeedback?.overallFeedback || ''
      },

      studentFeedback: feedback?.studentFeedback || '',
      statistics: {
        aboutCourses: feedback?.statistics?.aboutCourses || 0,
        aboutInstructors: feedback?.statistics?.aboutInstructors || 0,
        aboutOrganization: feedback?.statistics?.aboutOrganization || 0,
        aboutPrograms: feedback?.statistics?.aboutPrograms || 0,
        npsIndex: (feedback?.statistics?.npsIndex || 0) * 100
      }
    },
    mode: 'all'
  })

  const errors = form.formState.errors

  const handleSubmit = useSaveEndOfClassFeedback(feedback)

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item md={4} xs={12}>
            <InputLabel>Chỉ số NPS</InputLabel>
            <Controller
              name="statistics.npsIndex"
              rules={{
                max: {
                  value: 100,
                  message: 'Chỉ số nps không thể lớn hơn 100%'
                }
              }}
              control={form.control}
              render={(field) => (
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  fullWidth
                  variant="outlined"
                  error={!!errors.statistics?.npsIndex?.message}
                  helperText={errors.statistics?.npsIndex?.message}
                  size="small"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={4} xs={12}>
            <InputLabel>Chỉ số NPS doanh nghiệp</InputLabel>
            <Controller
              name="businessFeedback.npsIndex"
              rules={{ max: { value: 500, message: 'Tối da 500 ký tự!' } }}
              control={form.control}
              render={(field) => (
                <TextField
                  fullWidth
                  error={!!errors.businessFeedback?.npsIndex?.message}
                  helperText={errors.businessFeedback?.npsIndex?.message}
                  variant="outlined"
                  size="small"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={4} xs={12}>
            <InputLabel>Phản hồi doanh nghiệp</InputLabel>
            <Controller
              rules={{ max: { value: 500, message: 'Tối da 500 ký tự!' } }}
              name="businessFeedback.feedback"
              control={form.control}
              render={(field) => (
                <TextField
                  multiline
                  rows={3}
                  error={!!errors.businessFeedback?.feedback?.message}
                  helperText={errors.businessFeedback?.feedback?.message}
                  fullWidth
                  variant="outlined"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={7} xs={12}>
            <InputLabel>Đánh giá chung</InputLabel>
            <Controller
              rules={{ max: { value: 500, message: 'Tối da 500 ký tự!' } }}
              name="businessFeedback.overallFeedback"
              control={form.control}
              render={(field) => (
                <TextField
                  multiline
                  rows={3}
                  error={!!errors.businessFeedback?.overallFeedback?.message}
                  helperText={errors.businessFeedback?.overallFeedback?.message}
                  fullWidth
                  variant="outlined"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={4} xs={12}>
            <InputLabel>Về khóa học</InputLabel>
            <Controller
              name="statistics.aboutCourses"
              control={form.control}
              render={(field) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={4} xs={12}>
            <InputLabel>Về tổ chức</InputLabel>
            <Controller
              name="statistics.aboutOrganization"
              control={form.control}
              render={(field) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={4} xs={12}>
            <InputLabel>Về chương trình</InputLabel>
            <Controller
              name="statistics.aboutPrograms"
              control={form.control}
              render={(field) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={4} xs={12}>
            <InputLabel>Về giảng viên</InputLabel>
            <Controller
              name="statistics.aboutInstructors"
              control={form.control}
              render={(field) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={7} xs={12}>
            <InputLabel>Đánh giá của học viên</InputLabel>
            <Controller
              name="studentFeedback"
              rules={{ max: { value: 500, message: 'Tối da 500 ký tự!' } }}
              control={form.control}
              render={(field) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  error={!!errors.studentFeedback?.message}
                  helperText={errors.studentFeedback?.message}
                  size="small"
                  multiline
                  rows={5}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LoadableButton
            disabled={!form.formState.isDirty}
            type="submit"
            loading={form.formState.isSubmitting}
            variant="contained"
            color="primary"
          >
            Lưu
          </LoadableButton>
        </Grid>
      </Grid>
    </form>
  )
}

const FeedbackEndOfClass: React.FC<IProps> = () => {
  const feedback = useEndOfClassFeedback()

  return (
    <>
      <ExpansionPanel elevation={0} defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography>
            <b>Phản hồi cuối chương trình</b>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {feedback === undefined ? (
            <Loading />
          ) : (
            <FeedbackEndOfClassForm feedback={feedback} />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

export default FeedbackEndOfClass

const useEndOfClassFeedback = () => {
  const db = useFirestore()

  const space = useLearningSpace()

  const klass = useLearningSpaceClass()

  const [feedback, setFeedback] = useState<
    null | LearningSpaceClassFeedbackEndOfClass | undefined
  >(undefined)

  const toast = useSnackbar()

  useEffect(() => {
    if (klass) {
      const query = space._meta.ref
        .collection('end_of_class_feedbacks')
        .doc(klass.id)

      getDocument<LearningSpaceClassFeedbackEndOfClass>(query)
        .then((data) => {
          setFeedback(data || null)
        })
        .catch((error) => {
          console.error(error)
          toast('Đã có lỗi xảy ra!', 'error')
        })
    }
  }, [db, space, klass, toast])

  return feedback
}

const useSaveEndOfClassFeedback = (
  feedback: LearningSpaceClassFeedbackEndOfClass | null
) => {
  const space = useLearningSpace()

  const klass = useLearningSpaceClass()

  const toast = useSnackbar()

  return useCallback(
    async (data: FormData) => {
      if (klass) {
        try {
          const ref = space._meta.ref
            .collection('end_of_class_feedbacks')
            .doc(klass.id)
          const newFeedback: Omit<LearningSpaceClassFeedbackEndOfClass, 'id'> =
            {
              businessFeedback: data.businessFeedback,
              classId: klass._meta.ref.id,
              finishAt: klass.timeTracks.finishAt,
              startAt: klass.timeTracks.startAt,
              learningSpaceId: space._meta.ref.id,
              name: klass.name,
              studentFeedback: data.studentFeedback,
              timeTracks: {
                createdAt: timestampNow()
              },
              statistics: {
                ...data.statistics,
                instructors: {},
                npsIndex: +(data.statistics.npsIndex || 0) / 100,
                aboutCourses: +data.statistics.aboutCourses,
                aboutInstructors: +data.statistics.aboutInstructors,
                aboutOrganization: +data.statistics.aboutOrganization,
                aboutPrograms: +data.statistics.aboutPrograms
              }
            }

          if (feedback) {
            newFeedback.timeTracks.updatedAt = timestampNow()
          }

          await ref.set(newFeedback, { merge: true })

          toast('Đã cập nhật phản hồi cuối chương trình!')
        } catch (e) {
          console.error(e)
          toast('Đã có lỗi xảy ra!', 'error')
        }
      }
    },
    [space, klass, toast, feedback]
  )
}
