/**
 * Since Cloud Firestore provides limited support for logical OR queries,
 * this function will help to solve that
 *
 * @example
 * const studentEnrolledPrograms: LearningSpaceProgramsType[] = useCollectionData(
 *    onAirProgramsQuery.where(`students.${user.uid}`, '==', true),
 *    { idField: 'id' }
 * )
 * const teacherPrograms: LearningSpaceProgramsType[] = useCollectionData(
 *    onAirProgramsQuery.where(`roles.teachers.${user.uid}`, '==', true),
 *    { idField: 'id' }
 * )
 *
 * const deduplicated = mergeCollectionDataById(
 *    studentEnrolledPrograms, teacherPrograms
 * ) as LearningSpaceProgramsType[]
 *
 * @param collections - collections returned by `useCollectionData`
 * @returns the deduplicated collection
 */
export function mergeCollectionDataById(...collections: any[]): Array<any> {
  const allCollection = collections.reduce((acc: any, cur: any) => {
    return acc.concat(cur)
  }, [])

  // This is where the deduplication happens
  const collectionMap = new Map(allCollection.map((c: any) => [c.id, c]))

  return Array.from(collectionMap.values())
}

export function sheetToObjects(
  rows: string[][],
  options: { withLineNumber?: boolean } = {}
) {
  const { withLineNumber = true } = options
  const result: { [key: string]: any }[] = []
  const headers = rows.splice(0, 1)[0]

  rows.forEach((row, index) => {
    const rowObj: { [key: string]: any } = withLineNumber
      ? {
          line: index + 2
        }
      : {}
    headers.forEach((col, index) => {
      rowObj[String(col).trim()] = row[index] ? String(row[index]).trim() : ''
    })

    result.push(rowObj)
  })
  return result
}

export const convertToMapObject = (
  array: { [key: string]: any }[],
  keyField: string
) => {
  const temp: { [key: string]: { [key: string]: any } } = {}

  array.forEach((item) => {
    temp[item[keyField]] = item
  })

  return temp
}

export const splitItems = <T>(items: T[], perStack: number): T[][] => {
  const results: T[][] = []
  const cloneItems = [...items]

  while (cloneItems.length > 0) {
    results.push(cloneItems.splice(0, perStack))
  }

  return results
}
