import { Theme, createStyles } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    editor: {
      position: 'relative',
      // display: 'flex',
      // flexDirection: 'row',

      '& .react-mde': {
        width: '100%'
      },

      '& textarea.mde-text': {
        fontSize: theme.typography.body1.fontSize,
        fontFamily: "'Lora', serif",
        backgroundColor: 'transparent'
      },

      '& .upload-image': {
        position: 'absolute',
        right: 0
      },

      '& img': {
        maxWidth: '100%'
      }
    },
    error: {
      color: 'red'
    }
  })
