import * as rf from 'reactfire'
import {
  IFirestoreMetadata,
  IUseAssocReferenceCollectionDataResult,
  IUseReferenceCollectionDataOptions,
  IUseReferenceCollectionDataResult
} from 'interfaces'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getAll } from 'utils/FirestoreUtils'
import { logDebug } from 'utils/logUtils'
import { firestore } from 'firebase/app'
import { useFirestore } from 'reactfire'

const CACHED_QUERIES: firestore.Query[] = []

const getQueryIndex = (query: firestore.Query) => {
  for (let i = 0; i < CACHED_QUERIES.length; i++) {
    const cachedQuery = CACHED_QUERIES[i]
    if (cachedQuery && cachedQuery.isEqual(query)) {
      return i
    }
  }

  CACHED_QUERIES.push(query)

  return CACHED_QUERIES.length - 1
}

export function metaRef(
  input: object,
  ref: firebase.firestore.DocumentReference
): IFirestoreMetadata {
  return { ...input, _meta: { ref: ref } }
}

/**
 * listen to a document
 *
 * @param ref
 * @param options
 */
export function useDocument<T>(
  ref: firebase.firestore.DocumentReference,
  options: rf.ReactFireOptions<T>
) {
  return rf.useFirestoreDoc<T>(ref, options)
}

/**
 * listen to a document
 * return document data attached _meta.ref (DocumentReference)
 * @param ref
 * @param options
 */
export function useDocumentData<T extends object>(
  ref: firebase.firestore.DocumentReference,
  options?: rf.ReactFireOptions<T>
): (T & IFirestoreMetadata) | undefined {
  const { idField } = options ? options : { idField: undefined }

  const data = rf.useFirestoreDocData<T>(ref, options)

  return useMemo(() => {
    if (_.isEmpty(idField ? _.omit(data, idField) : data)) {
      return undefined
    }
    return metaRef(data, ref) as T & IFirestoreMetadata
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, idField])
}

export function useDocumentDataOne<T extends object>(
  ref: firebase.firestore.DocumentReference,
  options?: rf.ReactFireOptions<T>
) {
  const { idField } = options ? options : { idField: undefined }

  const data = rf.useFirestoreDocDataOnce<T>(ref, options)
  if (_.isEmpty(idField ? _.omit(data, idField) : data)) {
    return undefined
  }

  return metaRef(data, ref) as T & IFirestoreMetadata
}

/**
 * listen to a collection's document filtered by query
 * @param query
 * @param options
 */
export function useCollection<T>(
  query: firebase.firestore.Query,
  options: rf.ReactFireOptions<Array<T>>
) {
  return rf.useFirestoreCollection<T>(query, options)
}

/**
 * listen to a collection's documents filtered by query
 * return option attached _meta.ref: (DocumentReference)
 * @param query
 * @param options
 */
export function useCollectionData<T extends object>(
  query: firebase.firestore.Query,
  options?: rf.ReactFireOptions<T[]>
): Array<T & IFirestoreMetadata> {
  const snapshot = rf.useFirestoreCollection(
    query,
    options
  ) as firebase.firestore.QuerySnapshot

  return useMemo(() => {
    return snapshot.docs.map(
      (doc) =>
        metaRef(
          {
            ...doc.data(),
            ...(options?.idField ? { [options.idField]: doc.id } : null)
          },
          doc.ref
        ) as T & IFirestoreMetadata
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot])
}

export const useAssocCollectionData = <T extends IFirestoreMetadata>(
  query: firebase.firestore.Query,
  assocField: string,
  options?: rf.ReactFireOptions<T[]> & { initValue?: any }
) => {
  const idField = options?.idField || assocField
  const initVaue =
    typeof options?.initValue === 'undefined' ? {} : options.initValue
  const snapshots = rf.useFirestoreCollection(
    query,
    options
  ) as firebase.firestore.QuerySnapshot

  const [dataAssoc, setDataAssoc] = useState<{ [key: string]: T }>(initVaue)

  useEffect(() => {
    const data: { [key: string]: T } = {}

    snapshots.forEach((snapshot) => {
      const doc = { [idField]: snapshot.id, ...snapshot.data() } as {
        [key: string]: any
      }

      if (doc[assocField]) {
        data[doc[assocField]] = metaRef(doc, snapshot.ref) as T &
          IFirestoreMetadata
      }
    })
    setDataAssoc(data)
  }, [assocField, idField, snapshots])

  return dataAssoc
}

export const useReferenceCollectionData = <T extends Object>(
  query: firestore.Query,
  options: IUseReferenceCollectionDataOptions<T>
): IUseReferenceCollectionDataResult<T> => {
  const { refField, filter } = options
  const [result, setResult] = useState<(T & IFirestoreMetadata)[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const queryIndex = getQueryIndex(query)

  const queryData = useCallback(async () => {
    setError(undefined)
    setLoading(true)

    const relationSnapshots = await query.get()
    const refs = relationSnapshots.docs.map((s) => s.data()[refField])

    const docs = await getAll(refs)
    const filtered = filter ? docs.filter(filter) : docs

    setResult(
      filtered.map(
        (doc) =>
          metaRef({ id: doc.id, ...doc.data() }, doc.ref) as T &
            IFirestoreMetadata
      )
    )

    setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryIndex, filter, refField])

  const forceReload = useCallback(() => {
    queryData()
      .then(() => logDebug(`Fetched!`))
      .catch((e) => setError(e.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData])

  useEffect(() => {
    queryData()
      .then(() => logDebug(`Fetched!`))
      .catch((e) => setError(e.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData])

  return { data: result, loading, error, forceReload }
}

export const useAssocReferenceCollectionData = <T extends Object>(
  query: firestore.Query,
  options: IUseReferenceCollectionDataOptions<T>
): IUseAssocReferenceCollectionDataResult<T> => {
  const { refField, filter } = options
  const [result, setResult] = useState<{
    [key: string]: T & IFirestoreMetadata
  }>({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const queryIndex = getQueryIndex(query)

  const queryData = useCallback(async () => {
    setError(undefined)
    setLoading(true)

    const relationSnapshots = await query.get()
    const refs = relationSnapshots.docs.map((s) => s.data()[refField])
    const docs = await getAll(refs)
    const filtered = filter ? docs.filter(filter) : docs

    const data: { [key: string]: T & IFirestoreMetadata } = {}
    filtered.forEach((doc) => {
      data[doc.id] = metaRef({ id: doc.id, ...doc.data() }, doc.ref) as T &
        IFirestoreMetadata
    })

    setResult(data)
    setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryIndex, filter, refField])

  const forceReload = useCallback(() => {
    queryData()
      .then(() => logDebug(`Fetched!`))
      .catch((e) => setError(e.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData])

  useEffect(() => {
    queryData()
      .then(() => logDebug(`Fetched!`))
      .catch((e) => setError(e.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData])

  return { data: result, loading, error, forceReload }
}

export const useReferenceDocumentDataOnce = <T extends Object>(
  ref: firestore.DocumentReference,
  options: { refField: string }
) => {
  const { refField } = options
  const [result, setResult] = useState<(T & IFirestoreMetadata) | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<undefined | Error>()

  const db = useFirestore()

  const queryData = useCallback(async () => {
    try {
      setError(undefined)
      setLoading(true)
      const docRef = db.doc(ref.path)
      const documentData = await docRef.get()
      if (!documentData || !documentData.exists)
        setError(new Error('not-found'))

      const referenceRef = documentData.data()?.[
        refField
      ] as firestore.DocumentReference
      const referenceDoc = await referenceRef.get()

      setResult(
        metaRef(
          { id: referenceDoc.id, ...referenceDoc.data() },
          referenceDoc.ref
        ) as T & IFirestoreMetadata
      )
    } catch (e: any) {
      setError(e)
    }

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.path, refField])

  const forceReload = useCallback(() => {
    queryData()
      .then(() => logDebug(`Fetched!`))
      .catch((e) => setError(e.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData])

  useEffect(() => {
    queryData()
      .then(() => logDebug(`Fetched!`))
      .catch((e) => setError(e.message))
  }, [queryData])

  return {
    data: result,
    loading: loading,
    error: error,
    forceReload: forceReload
  }
}

export declare type IDataOnceState<T> = {
  data: T[]
  loading: boolean
  error: Error | null
}
/*
 * Fetch the firestore data without suspense
 */
export const useQueryData = <T extends Object>(
  uniqueId: string,
  fetcher: () => Promise<T[]>
) => {
  const [state, setState] = useState<IDataOnceState<T>>({
    data: [],
    loading: true,
    error: null
  })

  useEffect(() => {
    setState({ loading: true, data: [], error: null })
    fetcher()
      .then((r) => {
        setState({ loading: false, data: r, error: null })
      })
      .catch((e) => {
        console.error(e.message)
        setState({ loading: false, data: [], error: e })
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId])

  return state
}
