import React from 'react'
import config from 'configs'
import { makeStyles } from '@material-ui/core/styles'
import { styles } from './styles'
import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  Theme,
  Link
} from '@material-ui/core'

const useStyles = makeStyles(styles)

interface IProps {}

const Footer: React.FC<IProps> = () => {
  const classes = useStyles(() => {})

  const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const showBugReportDialog = () => {
    // @ts-ignore
    window.showCollectorDialog()
  }

  if (isDownSm) {
    return (
      <>
        <div className={classes.footer}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={12}>
              <Box textAlign="center">
                <Link target="_blank" rel="noopener noreferrer" href={'/'}>
                  Trang chủ
                </Link>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box textAlign="center">
                <Link target="_blank" rel="noopener noreferrer" href={'/'}>
                  Điều khoản dịch vụ
                </Link>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box textAlign="center">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={config.app.privacyPolicy}
                >
                  Chính sách bảo mật
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="center">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'/change-logs'}
                >
                  Nhật ký thay đổi
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="center">
                <Link
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={showBugReportDialog}
                >
                  Báo lỗi{' '}
                  <span aria-label={''} aria-labelledby={''} role={'img'}>
                    🐞
                  </span>
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} className={classes.siteInfo}>
            <Box textAlign="center" mt={2}>
              <Typography variant="body2">
                <strong>
                  {config.app.siteName}
                  {config.app.siteSlogan && (
                    <span> - {config.app.siteSlogan}</span>
                  )}
                </strong>
              </Typography>
              <em>{`Copyright©${new Date().getFullYear()} - ` + config.app.companyName}</em>
            </Box>
          </Grid>
        </div>
      </>
    )
  }

  return (
    <div className={classes.footer}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className={classes.siteInfo}>
          <Typography variant="body2">
            <strong>
              {config.app.siteName}
              {config.app.siteSlogan && <span> - {config.app.siteSlogan}</span>}
            </strong>
          </Typography>
          <em>{`Copyright©${new Date().getFullYear()} - ` + config.app.companyName}</em>
        </Grid>
        {/* <Grid item xs={12} sm={6} className={classes.mobileApp}>
          <Typography variant="body2">
            <strong>Tải ứng dụng trên điện thoại</strong>
          </Typography>
          <Box mt={1}>
            <a href={config.app.appGooglePlayUrl}>
              <img src={images.GOOGLE_PLAY} alt="Google Play" />
            </a>
            <a href={config.app.appAppStoreUrl}>
              <img src={images.APPLE_STORE} alt="Apple Store" />
            </a>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Box mr={3}>
              <Link target="_blank" rel="noopener noreferrer" href={'/'}>
                Trang chủ
              </Link>
            </Box>
            <Box mr={3}>
              <Link target="_blank" rel="noopener noreferrer" href={'/'}>
                Điều khoản dịch vụ
              </Link>
            </Box>
            <Box mr={3}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={config.app.privacyPolicy}
              >
                Chính sách bảo mật
              </Link>
            </Box>
            <Box mr={3}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={'/change-logs'}
              >
                Nhật ký thay đổi
              </Link>
            </Box>
            <Box>
              <Link
                style={{ cursor: 'pointer', color: 'red' }}
                onClick={showBugReportDialog}
              >
                Báo lỗi{' '}
                <span aria-label={''} aria-labelledby={''} role={'img'}>
                  🐞
                </span>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer
