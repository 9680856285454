import { CssBaseline, LinearProgress, ThemeProvider } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import firebaseConfig from 'configs/firebase-config.json'
import sentryConfig from 'configs/sentry-config.json'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { FirebaseAppProvider } from 'reactfire'
import store from 'store'
import MaybachTheme from 'themes/Maybach'
import App from './App'
import * as serviceWorker from './serviceWorker'

const app = document.getElementById('root')
app?.setAttribute('notranslate', 'true')

Sentry.init({
  dsn: sentryConfig['maybach-dsn'],
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={MaybachTheme}>
        <CssBaseline />
        <React.Suspense fallback={<LinearProgress />}>
          <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <App />
          </FirebaseAppProvider>
        </React.Suspense>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  app
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
