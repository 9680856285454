import React from 'react'
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Box
} from '@material-ui/core'
import lectureIcons from 'configs/lectureIconsConfig'
import MarkdownEditor from 'components/MarkdownEditor'
import LoadableButton from 'components/LoadableButton'
import { LectureIconType, Tag } from 'types'
import { IFirestoreMetadata } from 'interfaces'
import AddableTagsAutoComplete from '../../widgets/AddableTagsAutoComplete'

interface IProps {
  error: { [key: string]: string }
  title: string
  setIcon: (icon: LectureIconType) => void
  setTitle: (title: string) => void
  setDuration: (duration: string) => void
  icon: LectureIconType
  duration: string
  brief: string
  setBrief: (brief: string) => void
  intro: string
  setIntro: (intro: string) => void
  saving: boolean
  handleSave: (event: React.FormEvent) => void
  buttonSubmitTitle?: string
  variant?: 'create' | 'edit'
  selectedTags: { id: string; display: string }[]
  tags: (Tag & IFirestoreMetadata)[]
  setSelectedTags: (tags: { id: string; display: string }[]) => void
}

const getTitleButtonSubmit = (
  variant: 'create' | 'edit',
  buttonSubmitTitle?: string
) => {
  if (!buttonSubmitTitle) {
    switch (variant) {
      case 'create':
        return 'Tiếp tục'
      case 'edit':
        return 'Cập nhật'
    }
  }
  return buttonSubmitTitle
}

export const getRadioOptionLabel = (key: string) => {
  switch (key) {
    case 'document':
      return 'Tài liệu'
    case 'media':
      return 'Video/hình ảnh'
    case 'practice':
      return 'Thực hành'
    default:
      return ''
  }
}

const LectureForm: React.FC<IProps> = (props) => {
  const {
    error,
    icon,
    setIcon,
    title,
    setTitle,
    duration,
    setDuration,
    setBrief,
    brief,
    intro,
    setIntro,
    saving,
    handleSave,
    buttonSubmitTitle,
    selectedTags,
    tags,
    setSelectedTags,
    variant = 'create'
  } = props

  return (
    <Box mb={5}>
      <form
        onSubmit={handleSave}
        id="add-lecture-frm"
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12} md={12}>
          <Box mt={2}>
            <TextField
              required
              id="lecture-title"
              name="lecture-title"
              label="Tiêu đề"
              autoFocus
              fullWidth
              value={title}
              variant="outlined"
              error={error.title !== undefined}
              helperText={error.title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2}>
            <TextField
              label="Thời lượng"
              id="lecture-duration"
              name="lecture-duration"
              variant="outlined"
              helperText="Ví dụ: 3:19"
              defaultValue={duration}
              size="small"
              onChange={(e) => setDuration(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <FormControl component="fieldset" error={error.icon !== undefined}>
              <FormLabel component="legend">Biểu tượng</FormLabel>
              <RadioGroup
                aria-label="icon"
                name="icon"
                row
                value={icon}
                onChange={(e) => setIcon(e.target.value as LectureIconType)}
              >
                {Object.keys(lectureIcons).map((keyName) => (
                  <FormControlLabel
                    value={keyName}
                    control={<Radio />}
                    label={getRadioOptionLabel(keyName)}
                  />
                ))}
              </RadioGroup>
              {error.icon && <FormHelperText>{error.icon}</FormHelperText>}
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <TextField
              label="Tóm tắt"
              id="lecture-desc"
              name="lecture-desc"
              variant="outlined"
              size="small"
              multiline
              rows={3}
              fullWidth
              defaultValue={brief}
              onChange={(e) => setBrief(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <FormLabel component="legend">Giới thiệu</FormLabel>
            <Box mt={2}>
              <MarkdownEditor
                value={intro}
                onChange={setIntro}
                minEditorHeight={200}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AddableTagsAutoComplete
            selectedTags={selectedTags}
            onChangeSelectedTags={setSelectedTags}
            tags={tags}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <LoadableButton
              type="submit"
              color="primary"
              variant="contained"
              loading={saving}
            >
              {getTitleButtonSubmit(variant, buttonSubmitTitle)}
            </LoadableButton>
          </Box>
        </Grid>
      </form>
    </Box>
  )
}

export default LectureForm
