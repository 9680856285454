import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import TextFieldDebounce from 'components/TextFieldDebounce'
import { GetApp, Search } from '@material-ui/icons'
import { createStyles, Theme } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filters: {
      '& :last-child': {
        marginRight: 0
      }
    },
    filter: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    select: {
      minWidth: 150
    },
    exportBtn: {
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.success.main,
      color: '#fff',
      minWidth: 150,
      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    }
  })
)

interface IProps {
  searchBox?: boolean
  onSearch?: (text: string) => void
  filters?: {
    [key: string]: {
      data: { id: string; label: string }[]
      options?: {
        autocomplete?: boolean
      }
      label?: string
    }
  }
  onChange?: (key: string, value: string) => void
  onClick?: () => void
  exportable?: boolean
  onExport?: () => void
  toolbar?: any
  leftToolbar?: any
  filterValues: { [k: string]: string }
}

const TableHeaderToolbar: React.FC<IProps> = ({
  searchBox = false,
  onSearch,
  filters = {},
  onChange,
  exportable,
  onExport,
  toolbar,
  onClick,
  filterValues,
  leftToolbar
}) => {
  const filterKeys = useMemo(() => Object.keys(filters), [filters])
  const classes = useStyles(() => {})
  const getOptions = useCallback(
    (key: string) => {
      const filter = filters[key]
      return filter.options
    },
    [filters]
  )

  const getLabel = useCallback((key: string) => filters[key].label, [filters])

  const getData = useCallback(
    (key: string) => filters[key].data || [],
    [filters]
  )

  const isAutocomplete = useCallback(
    (key: string) => getOptions(key)?.autocomplete === true,
    [getOptions]
  )

  const handleChangeAutocomplete = useCallback(
    (name: string) => (event: React.ChangeEvent<any>, value: any) => {
      if (value && typeof value === 'object' && value.id) {
        onChange && onChange(name, value.id)
      } else {
        onChange && onChange(name, '')
      }
    },
    [onChange]
  )
  const handleChangeSelect = useCallback(
    (event: React.ChangeEvent<any>) =>
      onChange && onChange(event.target.name, event.target.value),
    [onChange]
  )

  return (
    <Box display={'flex'} alignItems={'center'} p={2} minHeight={30}>
      <Box maxWidth={300} minWidth={150}>
        {searchBox && (
          <TextFieldDebounce
            textFieldProps={{
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              InputProps: {
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <Search fontSize={'small'} />
                  </InputAdornment>
                )
              }
            }}
            onChange={onSearch}
            onClick={onClick}
            wait={500}
          />
        )}
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent="space-between"
        width={'100%'}
        pl={2}
      >
        <Box> {!!leftToolbar && leftToolbar}</Box>
        <Box display={'flex'} alignItems="center">
          {exportable && (
            <Button
              className={classes.exportBtn}
              onClick={onExport}
              variant={'contained'}
            >
              <GetApp fontSize={'small'} /> Tải xuống (XLSX)
            </Button>
          )}
          {filterKeys.map((key) => (
            <Box key={key} className={classes.filters}>
              {isAutocomplete(key) ? (
                <Autocomplete
                  onChange={handleChangeAutocomplete(key)}
                  id={key}
                  options={getData(key)}
                  getOptionLabel={(option) => option.label}
                  className={classes.select}
                  noOptionsText={'Không có dữ liệu'}
                  size={'small'}
                  renderInput={(params) => (
                    <TextField
                      placeholder={getLabel(key)}
                      {...params}
                      value={filterValues[key] as any}
                      size={'small'}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <Box key={key} className={classes.filter}>
                  <FormControl size={'small'}>
                    <Select
                      className={classes.select}
                      labelId={key}
                      id={key}
                      variant={'outlined'}
                      name={key}
                      defaultValue={''}
                      onChange={handleChangeSelect}
                      displayEmpty
                    >
                      <MenuItem value="">{getLabel(key)}</MenuItem>
                      {getData(key).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
          ))}
          {!!toolbar && toolbar}
        </Box>
      </Box>
    </Box>
  )
}

export default TableHeaderToolbar
