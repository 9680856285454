import {
  SectionType,
  SectionLectureType,
  LectureType,
  ActivityType,
  OrderedLectureType,
  SectionQuizType,
  CourseActivityType,
  LearningSpaceCourseStatisticsType
} from 'types'
import _, { orderBy } from 'lodash'
import { firestore } from 'firebase'
import { getCollectionData } from './FirestoreUtils'
import { getCourse as getLearningSpaceCourse } from './LearningSpaceUtils'
import { IFirestoreMetadata } from 'interfaces'
import { getPaginationStudents } from './LearningSpaceClassUserUtils'
import { splitItems } from './Array'

/**
 * Get section's id from lecture's sectionRef
 * return null if sectionRef was missed
 * @param lecture
 */
export function getSectionId(lecture: SectionLectureType): string | null {
  if (!lecture.sectionRef) {
    return null
  }
  let arr: string[] = []
  if (typeof lecture.sectionRef === 'object') {
    arr = _.trim(lecture.sectionRef.path, '/').split('/')
  } else if (typeof lecture.sectionRef === 'string') {
    arr = _.trim(lecture.sectionRef, '/').split('/')
  }
  return arr[arr.length - 1]
}

/**
 * Group lectures follow sections keep ordering of both two inputs data
 *
 * @param sections
 * @param lectures
 */
export function groupingLecturesBySection(
  sections: SectionType[],
  lectures: SectionLectureType[]
): Array<SectionType> {
  for (let index = 0; index < sections.length; ++index) {
    let temp: SectionLectureType[] = []
    sections[index].lectures = []

    lectures.forEach((lecture) => {
      if (sections[index].id === getSectionId(lecture)) {
        sections[index].lectures?.push(lecture)
      } else {
        temp.push(lecture)
      }
    })

    lectures = temp //optimize
  }

  return sections
}

export const mergeActivities = (
  lectures: SectionLectureType[],
  quizzes: SectionQuizType[]
) => {
  const activities: CourseActivityType[] = []

  const putTypeField = (type: CourseActivityType['type']) => (act: any) => {
    act.type = type
    return act
  }

  activities.push(...lectures.map(putTypeField('LECTURE')))
  activities.push(...quizzes.map(putTypeField('QUIZ')))
  return activities
}

export const mergeActivitiesAndSortBySection = (
  lectures: SectionLectureType[],
  quizzes: SectionQuizType[],
  sections: SectionType[]
) => {
  const groupSections = groupSectionItems(sections, lectures, quizzes)

  const sortedActivities: CourseActivityType[] = []

  groupSections.forEach((section) => {
    sortedActivities.push(...orderBy(section.activities, ['ordering']))
  })

  return sortedActivities
}

export function getSectionLectureSectionId(
  sectionLecture: CourseActivityType
): string | null {
  if (!sectionLecture.sectionRef) {
    return null
  }
  let arr: string[] = []
  if (typeof sectionLecture.sectionRef === 'object') {
    arr = _.trim(sectionLecture.sectionRef.path, '/').split('/')
  } else if (typeof sectionLecture.sectionRef === 'string') {
    arr = _.trim(sectionLecture.sectionRef, '/').split('/')
  }
  return arr[arr.length - 1]
}

export function groupSectionItems(
  sections: SectionType[],
  lectures: SectionLectureType[],
  quizzes: SectionQuizType[]
): Array<SectionType> {
  let activities = mergeActivities(lectures, quizzes)
  for (let index = 0; index < sections.length; ++index) {
    let groupedActivities: CourseActivityType[] = []
    sections[index].activities = []

    activities.forEach((lecture) => {
      if (sections[index].id === getSectionLectureSectionId(lecture)) {
        sections[index].activities?.push(lecture)
      } else {
        groupedActivities.push(lecture)
      }
    })

    activities = orderBy(groupedActivities, ['ordering']) //optimize
    sections[index].activities = orderBy(sections[index].activities, [
      'ordering'
    ])
  }

  return orderBy(sections, ['ordering'])
}

/**
 * order lecture activities by activity.ordering field
 * return lecture with ordered activity
 *
 * @param lecture
 */
export function orderActivities(lecture: LectureType): OrderedLectureType {
  const { activities, ...rest } = lecture

  let temp: ActivityType[] = []

  if (activities) {
    temp = Object.values(activities)
    temp.sort((a, b) => {
      const aOrdering = a.ordering ? a.ordering : 0
      const bOrdering = b.ordering ? b.ordering : 0
      return aOrdering - bOrdering
    })
  }

  return { activities: temp, ...rest } as OrderedLectureType
}

export function getLessonTabLabel(type: string): string {
  switch (type) {
    case 'video':
      return 'Video'
    case 'audio':
      return 'Bài Nghe'
    case 'reading':
      return 'Bài Đọc'
    case 'review_question':
      return 'Ôn tập'
    case 'practice':
      return 'Luyện Tập'
    case 'url':
      return 'Liên kết'
    case 'files_list':
      return 'Tài liệu'
    case 'scorm':
      return 'Scorm'
  }

  return ''
}

export const getPublishedSections = async (
  courseRef: firestore.DocumentReference
) => {
  const collection = courseRef.collection('sections')
  const query = collection
    .where('state', '==', 'PUBLISHED')
    .orderBy('ordering', 'asc')

  return getCollectionData<SectionType>(query, { idField: 'id' })
}

export const getPublishedLectures = async (
  courseRef: firestore.DocumentReference
) => {
  const collection = courseRef.collection('lectures')
  const query = collection.where('state', '==', 'PUBLISHED')

  return getCollectionData<SectionLectureType>(query, { idField: 'id' })
}

export const getPublishedQuizzes = async (
  courseRef: firestore.DocumentReference
) => {
  const collection = courseRef.collection('quizzes')
  const query = collection.where('state', '==', 'PUBLISHED')
  return getCollectionData<SectionQuizType>(query, { idField: 'id' })
}

export const getClassCourseCourseActivities = async (
  learningSpaceRef: firestore.DocumentReference,
  classId: string | null,
  courseId: string | null
) => {
  const data: CourseActivityType[] = []
  if (!courseId || !classId) return data

  const lsCourse = await getLearningSpaceCourse(learningSpaceRef, courseId)
  const courseRef = lsCourse?.courseRef

  if (!courseRef) return data

  const [sections, lectures, quizzes] = await Promise.all([
    getPublishedSections(courseRef),
    getPublishedLectures(courseRef),
    getPublishedQuizzes(courseRef)
  ])

  return mergeActivitiesAndSortBySection(lectures, quizzes, sections)
}

export const getStudentCourseStatisticDataInClass = async (
  learningSpaceRef: firestore.DocumentReference,
  classId: string | null,
  courseId: string | null,
  pageToken: null | string,
  limit: number
) => {
  if (limit > 1000) throw new Error('Limit maximum is 1000 documents')

  const data: (LearningSpaceCourseStatisticsType & IFirestoreMetadata)[] = []
  if (!courseId || !classId) return data

  const lsCourse = await getLearningSpaceCourse(learningSpaceRef, courseId)
  const classRef = learningSpaceRef.collection('classes').doc(classId)
  const courseRef = lsCourse?.courseRef

  if (!courseRef) return data

  const activeStudents = await getPaginationStudents(
    classRef,
    pageToken,
    limit,
    ['email', 'asc']
  )
  const userIds = activeStudents.map((u) => u.id)

  const baseQuery = learningSpaceRef
    .collection('courses')
    .doc(courseId)
    .collection('statistics')
    .where('classId', '==', classId)
    .orderBy('email')

  await Promise.all(
    splitItems(userIds, 10).map(async (splitIds) => {
      const result = await getCollectionData<LearningSpaceCourseStatisticsType>(
        baseQuery.where('studentId', 'in', splitIds),
        {
          idField: 'id'
        }
      )
      data.push(...result)
    })
  )

  return data
}
