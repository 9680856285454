import React, { useMemo } from 'react'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import MaybachTable, { IHeader, IRow } from 'components/MaybachTable'
import Gadget from 'components/Gadget'
import { ClassEventParticipationRate, SchemeSectionType } from 'types'
import { Typography } from '@material-ui/core'

interface Props {
  statistics: ClassEventParticipationRate[]
  schemeSections: SchemeSectionType[]
  selectedClasses: { selected: boolean; className: string; id: string }[]
}

const EventParticipationRateTable: React.FC<Props> = (props) => {
  const learningSpace = useLearningSpace()
  const { selectedClasses, statistics, schemeSections } = props

  const tableHeaders = useMemo(() => {
    const result: IHeader[] = [
      { key: 'className', label: 'Lớp' },
      { key: 'average', label: 'Trung bình', bold: true }
    ]
    schemeSections.forEach((section) => {
      result.push({ key: section.id as string, label: section.title })
    })
    return result
  }, [schemeSections])

  const tableData = useMemo(() => {
    const result: IRow[] = []
    selectedClasses.forEach((classInfo) => {
      const classStatistics = statistics.filter(
        (statistic) =>
          statistic.classId === classInfo.id &&
          statistic.learningSpaceId === learningSpace.id
      )
      const classSectionParticipationCompletionRate: {
        [sectionId: string]: string
      } = {}

      let totalParticipated = 0
      let totalStudents = 0
      classStatistics.forEach((statistic) => {
        totalParticipated += statistic.totalParticipations
        totalStudents += statistic.maximumParticipations
        classSectionParticipationCompletionRate[statistic.sectionId] = `${(
          statistic.participationRate * 100
        ).toFixed(0)}%`
      })

      const row: IRow = { className: { label: classInfo.className } }
      schemeSections.forEach((section) => {
        row[section.id as string] = {
          label:
            classSectionParticipationCompletionRate[section.id as string] ||
            'N/A'
        }
      })
      const average = totalParticipated / totalStudents
      row['average'] = {
        label: average ? `${(average * 100).toFixed(0)}%` : 'N/A'
      }
      result.push(row)
    })
    return result
  }, [learningSpace.id, schemeSections, selectedClasses, statistics])

  return (
    <>
      <Gadget title={'Tỉ lệ tham gia sự kiện'}>
        <MaybachTable
          exportable
          headers={tableHeaders}
          rows={tableData}
          showIndex
          csvTitle={'Tỉ lệ tham gia sự kiện'}
          caption={
            <>
              <Typography>
                Tỉ lệ tham gia sự kiện được tính theo <br />
              </Typography>
              <ul>
                <li>
                  Sự kiện: Số học viên đã tham gia sự kiện{' '}
                  <i>(Đúng giờ hoặc Muộn)</i> / Số học viên trong lớp
                </li>
                <li>
                  Mục: Tổng tỉ lệ hoàn thành của tất cả các sự kiện trong mục /
                  Số sự kiện trong mục
                </li>
                <li>
                  <b>Trung bình</b>: Tổng tỉ lệ hoàn thành của tất cả các mục
                  trong lớp / Số mục trong lớp
                </li>
              </ul>
            </>
          }
        />
      </Gadget>
    </>
  )
}

export default EventParticipationRateTable
