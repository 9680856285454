import { User } from 'firebase'
import { useMemo } from 'react'
import { useIdTokenResult, useUser } from 'reactfire'
import { useMySpaceRoles } from 'providers/role/RoleContextProvider'

export function hasAnyRoles(
  roles: { [key: string]: boolean },
  ...requireRoles: string[]
) {
  let hasPermission = false

  requireRoles.forEach((role) => {
    if (roles[role] === true) {
      hasPermission = true
    }
  })

  return hasPermission
}

export const useAuthorization = (...requiredRoles: string[]) => {
  const user = useUser<User>()
  const spaceRoles = useMySpaceRoles()
  const { claims } = useIdTokenResult(user)

  return useMemo(
    () =>
      hasAnyRoles(spaceRoles, ...requiredRoles, 'admin') ||
      hasAnyRoles(claims, ...requiredRoles, 'admin'),
    [claims, requiredRoles, spaceRoles]
  )
}
