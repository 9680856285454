import {makeStyles, createStyles, Theme} from '@material-ui/core'
import Color from 'color'


export default makeStyles((theme: Theme) => createStyles({
    certBox: {
        '&:hover': {
            border: `1px solid ${theme.palette.primary.light}`,
            backgroundColor: Color(theme.palette.primary.main).lighten(0.9).hex().toString(),
            transition: '0.3s'

        },
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer'
       
    },
    selectedCert: {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    certName: {
        fontWeight: 500
    }
}))