import { Box, Button } from '@material-ui/core'
import { Publish } from '@material-ui/icons'
import React, { useCallback } from 'react'

interface IProps {
  component?: React.ComponentType | React.FunctionComponent<any>
  accept?: string[]
  onChange: (files: FileList) => void
  onError?: (error: { title: string; code: string }) => void
  maxSize?: number
  multiple?: boolean
  id?: string
  disabled?: boolean
  buttonTitle?: string
  showListFile?: boolean
}

const notInArray = (array: string[] | undefined, el: string) => {
  return array && array.indexOf(el) === -1
}

const UploadFiles: React.FC<IProps> = ({
  component: Component,
  accept,
  onChange: change,
  onError: error,
  maxSize = 5 * 1024,
  multiple = true,
  id = 'upload-file',
  disabled = false,
  buttonTitle = 'Tải lên'
}) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files

      if (!files) return false
      let err: { title: string; code: string } | undefined = undefined

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileType = `.${file.name.split('.').pop()}`

        if (notInArray(accept, fileType)) {
          err = { title: 'Không đúng định dạng!', code: 'invalid-format' }
          break
        }

        if (file.size > maxSize * 1024) {
          err = {
            title: 'Dung lượng tập tin quá lớn!',
            code: 'file-too-large'
          }
          break
        }
      }

      if (err) {
        if (error) error(err)

        return
      }

      change(files)
    },
    [accept, change, error, maxSize]
  )

  return (
    <>
      <Box>
        <input
          type="file"
          onChange={onChange}
          multiple={multiple}
          id={id}
          hidden
          disabled={disabled}
          accept={accept?.join(',') || undefined}
        />
        <label style={{ cursor: 'pointer' }} htmlFor={id}>
          {Component ? (
            <Component />
          ) : (
            <Button
              color={'primary'}
              disabled={disabled}
              variant="outlined"
              component="span"
            >
              <Publish /> <Box ml={1}>{buttonTitle}</Box>
            </Button>
          )}
        </label>
      </Box>
    </>
  )
}

export default UploadFiles
