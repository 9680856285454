import { Theme } from '@material-ui/core'
import { EnrolledCourseStatusType } from 'types'

export function parseLearningCourseStatus(status: EnrolledCourseStatusType) {
  switch (status) {
    case 'IN PROGRESS':
      return 'Đang học'
    case 'COMPLETED':
    case 'FINISHED':
      return 'Hoàn thành'
    case 'CANCELED':
      return 'Hủy'
    case 'DROP OUT':
      return 'Đình chỉ'
    case 'FAILED':
      return 'Trượt'
  }
}

export function parseLearningCourseStatusColor(
  status: EnrolledCourseStatusType,
  theme: Theme
) {
  switch (status) {
    case 'IN PROGRESS':
      return 'initial'
    case 'COMPLETED':
    case 'FINISHED':
      return theme.palette.success.main
    case 'CANCELED':
      return theme.palette.error.main
    case 'DROP OUT':
      return theme.palette.error.main
    case 'FAILED':
      return theme.palette.error.main
  }
}
