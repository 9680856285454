import React from 'react'
import { ButtonProps, useTheme, CircularProgress } from '@material-ui/core'
import MaybachButton from 'components/MaybachButton'
import { ActiveWhenType } from 'types'
import { useActiveWhen } from 'hooks/useActiveWhen'

interface IProps extends ButtonProps {
  loading: boolean
  forceLabel?: boolean
  activeWhen?: ActiveWhenType
}

const LoadableButton: React.FC<IProps> = (props) => {
  const theme = useTheme()
  let {
    loading,
    forceLabel,
    startIcon,
    disabled,
    activeWhen = [],
    ...rest
  } = props

  const active = useActiveWhen(activeWhen)

  if (!startIcon && loading) {
    startIcon = <CircularProgress size={theme.typography.fontSize} />
  }

  const label = forceLabel && loading ? 'Loading ...' : props.children

  return loading ? (
    <MaybachButton disabled startIcon={startIcon} {...rest}>
      {label}
    </MaybachButton>
  ) : (
    <MaybachButton
      disabled={!active || disabled}
      startIcon={startIcon}
      {...rest}
    >
      {label}
    </MaybachButton>
  )
}

export default LoadableButton
