import React, { useMemo } from 'react'
import { Box, Button } from '@material-ui/core'
import { Theme, useTheme } from '@material-ui/core/styles'
import { CheckCircle, Error } from '@material-ui/icons'
import { useParams } from 'react-router'
import { exportCsv } from 'utils/XLSXUtils'
import MaybachTable, { IHeader } from 'components/MaybachTable'
import { parseTimeByFormat } from 'utils'
import { useConfirm } from 'providers/ConfirmProvider/ConfirmProvider'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { useHistory } from 'react-router-dom'

interface IProps {
  step: number
  result: { [key: string]: any }[] | undefined
}

const ROWS_PER_PAGE = 100

function getStatusMessage(status: any) {
  switch (status) {
    case 'ALREADY_HAVE_EXAM':
      return 'Hiện có bài thi chưa hoàn thành'
    case 'EMAIL_INVALID':
      return 'Email không đúng định dạng'
    case 'ERROR':
      return 'Thông tin email, tên hoặc mật khẩu chưa đúng định dạng'
    case 'ALREADY_IN_SPACE':
    case 'ALREADY_IN_AUTH':
      return 'Đã có tài khoản từ trước nên mật khẩu của học sẽ không được ghi đè'
    case 'SUCCESS':
      return 'Thành công'
    default:
      return ''
  }
}

function getStatusIcon(status: any, theme: Theme) {
  switch (status) {
    case 'INVALID':
    case 'EMAIL_INVALID':
    case 'ALREADY_HAVE_EXAM':
    case 'ERROR':
      return (
        <Error style={{ color: theme.palette.error.main }} fontSize={'small'} />
      )
    case 'ALREADY_IN_SPACE':
    case 'ALREADY_IN_AUTH':
    case 'SUCCESS':
      return (
        <CheckCircle
          style={{ color: theme.palette.success.main }}
          fontSize={'small'}
        />
      )

    default:
      return null
  }
}

const hidePasswordStatus = [
  'ALREADY_HAVE_EXAM',
  'ALREADY_IN_SPACE',
  'ALREADY_IN_AUTH'
]

const TABLE_HEADER: IHeader[] = [
  { key: 'icon', label: '', w: 15 },
  { key: 'notice', label: 'Chú ý', w: 200, mw: 150 },
  { key: 'email', label: 'Email' },
  { key: 'name', label: 'Tên', mw: 150 },
  { key: 'password', label: 'Mật khẩu' },
  { key: 'pointToPass', label: 'Điểm vượt qua (%)' },
  { key: 'timeLimit', label: 'Thời gian' },
  { key: 'startAt', label: 'Bắt đầu' },
  { key: 'finishAt', label: 'Kết thúc' }
]

const CertificationExamImportCompleteStep: React.FC<IProps> = ({
  step,
  result
}) => {
  const { id } = useParams<{ id: string }>()

  const history = useHistory()

  const confirm = useConfirm()

  const space = useLearningSpace()

  const customFields = useMemo(
    () => Object.values(space.userCustomFields || {}),
    [space.userCustomFields]
  )

  const exportResultToCsv = async () => {
    const data = [
      [
        'dòng',
        'email',
        'tên',
        ...customFields.map((field) => field.display),
        'mật khẩu',
        'kỳ thi',
        'điểm vượt qua (%)',
        'giới hạn làm bài (phút)',
        'bắt đầu',
        'kết thúc',
        'chú ý',
        'link dự thi'
      ]
    ]

    if (result) {
      result.forEach((row: any) => {
        const errorStatus = [
          'ERROR',
          'INVALID',
          'ALREADY_HAVE_EXAM',
          'EMAIL_INVALID'
        ]

        const attendUrl = errorStatus.find((s) => s === row.status)
          ? ''
          : `${window.location.origin}/certification-exam/${row.id}`

        data.push([
          row.line,
          row.email,
          row.name,
          ...customFields.map((field) => row[field.key]),
          row.password,
          row.examSession,
          row.pointToPass ? row.pointToPass * 100 : '',
          row.timeLimit,
          parseTimeByFormat(row.startAt, 'hh:mm dd/MM/YYYY') || '',
          parseTimeByFormat(row.finishAt, 'hh:mm dd/MM/YYYY') || '',
          getStatusMessage(row.status),
          attendUrl
        ])
      })
    }

    await exportCsv('Danh sách dự thi', data)
  }

  const handleComplete = async () => {
    try {
      await confirm({
        title: 'Tải xuống danh sách dự thi',
        description:
          'Tệp này chứa tất cả thông tin dự thi của danh sách thí sinh bạn vừa nhập, trong đó có đường dẫn vào làm bài.' +
          ' Bạn có muốn tải xuống ngay? Nếu không bạn có thể lọc danh sách thí sinh theo kỳ thi và chọn TẢI XUỐNG (ĐỊNH DẠNG CSV) ',
        confirmationText: 'Tải xuống',
        cancellationText: 'Bỏ qua'
      })

      await exportResultToCsv()
    } catch (e) {}
    history.push(`/admin/certification-exams/${id}`)
  }

  const theme = useTheme()

  const tableRows = useMemo(
    () =>
      result?.map((row: any) => ({
        email: {
          label: row.email
        },
        icon: { label: getStatusIcon(row.status, theme) },
        name: {
          label: row.name
        },
        password: {
          label: hidePasswordStatus.find((s) => s === row.status)
            ? ''
            : row.password
        },
        pointToPass: {
          label: row.pointToPass ? row.pointToPass * 100 : ''
        },
        timeLimit: {
          label: row.timeLimit
        },
        startAt: {
          label: parseTimeByFormat(row.startAt, 'hh:mm dd/MM/YYYY') || ''
        },
        finishAt: {
          label: parseTimeByFormat(row.finishAt, 'hh:mm dd/MM/YYYY') || ''
        },
        notice: {
          label: getStatusMessage(row.status)
        },
        ...customFields.reduce<{ [k: string]: any }>((fields, field) => {
          fields[field.key] = { label: row[field.key] }

          return fields
        }, {})
      })) || [],
    [customFields, result, theme]
  )

  if (step !== 2) return null

  return (
    <MaybachTable
      showIndex
      headers={TABLE_HEADER.concat(
        customFields.map((field) => ({ label: field.display, key: field.key }))
      )}
      rows={tableRows}
      rowsPerPage={ROWS_PER_PAGE}
      bottomToolbar={
        <Box display={'flex'} justifyContent={'flex-end'} p={2}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleComplete}
            style={{ minWidth: 150 }}
          >
            Xong
          </Button>
        </Box>
      }
    />
  )
}

export default CertificationExamImportCompleteStep
