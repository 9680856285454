export const PATTERNS = {
  NAME: /^.{1,32}$/,
  PASSWORD: /^.{8,}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /(02[1-9]|03|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
  URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  CHECKLIST_TASK_DURATION: /^(\dw\s)?([1-7]d\s)?([1-24]h\s)?$/g,
  COURSE_LECTURE_PATH: /courses\/[A-Za-z0-9]+\/lectures\/[A-Za-z0-9]+$/g
}

export const formatDuration = (seconds: any, surfix?: string): string => {
  if (typeof seconds === 'string') {
    seconds = parseInt(seconds)
  }

  let result = []
  let hours = ~~(seconds / 3600)
  if (hours) result.push(hours)
  seconds %= 3600
  result.push(~~(seconds / 60))
  seconds %= 60
  result.push(seconds)

  return surfix ? result.join(':') + surfix : result.join(':')
}

export const convertDurationHours = (seconds: number) => {
  let minutes = ~~(seconds / 60)
  const hours = ~~(minutes / 60)

  if (hours) {
    minutes = minutes % 60
  }

  return hours ? hours + 'h ' + minutes : minutes + 'm'
}
export const timeApartFromNow = (date: Date) => {
  const now = new Date()

  const apart = Math.abs((now.getTime() - date.getTime()) / 1000)

  const minutes = Math.floor(apart / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years >= 1) return years + 'y'
  if (months >= 1) return months + 'M'
  if (days >= 1) return days + 'd'
  if (hours >= 1) return hours + 'h'
  if (minutes >= 1) return minutes + 'm'

  return 'now'
}

export const fromDurationToSecond = (duration: string): number => {
  if (!duration) {
    return 0
  }

  duration = duration.replace(/([^0-9:]*)/g, '') //clean it
  const split = duration.split(/:/).reverse()
  let seconds = 0
  for (let ii = 0; ii < split.length; ++ii) {
    seconds += parseInt(split[ii]) * Math.pow(60, ii)
  }

  if (!seconds) return 0

  return seconds
}

export function removeUnicode(str: string) {
  let strClone = str
  strClone = strClone.toLowerCase()
  strClone = strClone.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  strClone = strClone.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  strClone = strClone.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  strClone = strClone.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  strClone = strClone.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  strClone = strClone.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  strClone = strClone.replace(/đ/g, 'd')
  //   str = str.replace(
  //     /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g,
  //     '-'
  //   )
  strClone = strClone.replace(/-+-/g, '-') //thay thế 2- thành 1-
  strClone = strClone.replace(/^-+|-+$/g, '')
  return strClone
}

export function isValidEmail(str: string): boolean {
  return PATTERNS.EMAIL.test(String(str))
}

export function isValidPhone(phone: string): boolean {
  return PATTERNS.PHONE.test(phone)
}

export function isValidName(name: string): boolean {
  return PATTERNS.NAME.test(name)
}
export function isValidPassword(password: string): boolean {
  return PATTERNS.PASSWORD.test(password)
}

export function convertToSlug(text: string) {
  const encodedText = removeUnicode(text).trim()
  return encodedText
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/[^\w-]+/g, '')
}

export function isCourseLecturePath(text: string) {
  return new RegExp(PATTERNS.COURSE_LECTURE_PATH).test(text)
}
