import { firestore } from 'firebase'
import { IFirestoreMetadata } from 'interfaces'
import { metaRef } from 'hooks'
import { STATE_OF_RECORD } from 'constants/common'

export const getAll = async (refs: firestore.DocumentReference[]) =>
  Promise.all(refs.map(async (ref) => await ref.get()))

export const getAllData = async <T extends Object>(
  refs: firestore.DocumentReference[]
) => {
  const docsData = await getAll(refs)
  return docsData.map((doc) => ({ id: doc.id, ...doc.data() } as unknown as T))
}

export const getQueryPath = (query: any) => query?.Ff.un.tt

export const getDocument = async <T extends object>(
  ref: firestore.DocumentReference,
  options?: { idField?: string }
): Promise<(T & IFirestoreMetadata) | undefined> => {
  const snapshot = await ref.get()
  if (!snapshot.exists) return undefined

  return metaRef(
    {
      ...snapshot.data(),
      ...(options?.idField ? { [options.idField]: snapshot.id } : null)
    },
    snapshot.ref
  ) as T & IFirestoreMetadata
}
export const getCollectionData = async <T>(
  ref: firestore.Query,
  options?: { idField?: string }
): Promise<(T & IFirestoreMetadata)[]> => {
  const snapshot = await ref.get()
  const result: Array<T & IFirestoreMetadata> = []
  snapshot.forEach((doc) => {
    result.push(
      metaRef(
        {
          ...doc.data(),
          ...(options?.idField ? { [options.idField]: doc.id } : null)
        },
        doc.ref
      ) as T & IFirestoreMetadata
    )
  })

  return result
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IGetReferenceCollectionDataOptions<T extends Object> {
  refField: string
  // Filter must be useCallback
  filter?: (
    result: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => boolean
}

interface IGetReferenceCollectionDataResult<T extends Object> {
  data: (T & IFirestoreMetadata)[]
}

export const getAllDocs = async (refs: firestore.DocumentReference[]) =>
  Promise.all(refs.map(async (ref) => await ref.get()))

export const getReferenceCollectionData = async <T extends Object>(
  parentCollectionRef: firestore.Query,
  options: IGetReferenceCollectionDataOptions<T>
): Promise<IGetReferenceCollectionDataResult<T>> => {
  const { refField, filter } = options
  let result: (T & IFirestoreMetadata)[] = []

  const relationSnapshots = await parentCollectionRef.get()
  const refs = relationSnapshots.docs.map((s) => s.data()[refField])
  const docs = await getAllDocs(refs)
  const filtered = filter ? docs.filter(filter) : docs

  result = filtered.map(
    (doc) =>
      metaRef({ id: doc.id, ...doc.data() }, doc.ref) as T & IFirestoreMetadata
  )

  return { data: result }
}

export const getCollectionAssoc = async <T extends object>(
  ref: firestore.CollectionReference | firestore.Query,
  assocField: string,
  options?: { idField?: string }
): Promise<{ [key: string]: T & IFirestoreMetadata }> => {
  const idField = options?.idField || 'id'
  const result: { [key: string]: T & IFirestoreMetadata } = {}
  const snapshot = await ref.get()
  snapshot.forEach((doc) => {
    const data = {
      ...doc.data(),
      ...(idField ? { [idField]: doc.id } : null)
    }

    if (data[assocField]) {
      result[data[assocField]] = metaRef(data, doc.ref) as T &
        IFirestoreMetadata
    }
  })

  return result
}

export const publicQuery = (query: firestore.Query) => {
  return query.where('state', '==', 'PUBLIC')
}
export const getPublishedQuery = (query: firestore.Query) => {
  return query.where('state', '==', STATE_OF_RECORD.PUBLISHED)
}
