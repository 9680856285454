import React from 'react'

export const Login = React.lazy(() => import('./Login'))
export const MyCourseDetail = React.lazy(() => import('./MyCourseDetail'))
export const StudentDashboard = React.lazy(() => import('./StudentDashboard'))
export const MyCourses = React.lazy(() => import('./MyCourses'))
export const Administration = React.lazy(() => import('./Administration'))
export const Students = React.lazy(() => import('./Students'))
export const AddNewStudent = React.lazy(() => import('./AddNewStudent'))
export const AddNewQuestionBank = React.lazy(
  () => import('./AddNewQuestionBank')
)
export const Classroom = React.lazy(() => import('./Classroom'))
export const ClassroomLessonDetail = React.lazy(
  () => import('./ClassroomLessonDetail')
)
export const ClassroomQuizDetail = React.lazy(
  () => import('./ClassroomQuizDetail')
)
export const ExecuteQuiz = React.lazy(() => import('./ExecuteQuiz'))
export const LearningSpaceCourses = React.lazy(
  () => import('./LearningSpaceCourses')
)
export const LearningSpaceCourseDetail = React.lazy(
  () => import('./LearningSpaceCourseDetail')
)
export const LearningSpaceCourseProgress = React.lazy(
  () => import('./LearningSpaceCourseProgress')
)
export const LearningSpaceCourseQuizzes = React.lazy(
  () => import('./LearningSpaceCourseQuizzes')
)
export const AddStudentIntoCourse = React.lazy(
  () => import('./AddStudentIntoCourse')
)

export const ClassroomQuizReview = React.lazy(
  () => import('./ClassroomQuizReview')
)

export const AdminExploreCourses = React.lazy(
  () => import('./AdminExploreCourses')
)

export const LearningSpaceCourseDetailContent = React.lazy(
  () => import('./LearningSpaceCourseDetailContent')
)

export const LearningSpaceCourseDetailStudents = React.lazy(
  () => import('./LearningSpaceCourseDetailStudents')
)
export const AdminDashboard = React.lazy(() => import('./AdminDashboard'))
export const PersonalInfo = React.lazy(() => import('./PersonalInfo'))
export const PersonalInfoDashboard = React.lazy(
  () => import('./PersonalInfoDashboard')
)

export const LearningSpaceCoursesExplore = React.lazy(
  () => import('./LearningSpaceCoursesExplore')
)

export const LearningSpaceBugReports = React.lazy(
  () => import('./LearningSpaceBugReports')
)
export const ChangeLogs = React.lazy(() => import('./ChangeLogs'))

export const LearningSpaceStudentDetail = React.lazy(
  () => import('./LearningSpaceStudentDetail')
)
export const RegisterLearningSpace = React.lazy(
  () => import('./RegisterLearningSpace')
)
export const CompleteRegistration = React.lazy(
  () => import('./CompleteRegistration')
)
export const LearningSpaceSettings = React.lazy(
  () => import('./LearningSpaceSettings')
)

export const LearningSpaceReport = React.lazy(
  () => import('./LearningSpaceReport')
)
export const LearningSpaceClasses = React.lazy(
  () => import('./LearningSpaceClasses')
)
export const LearningSpaceClassesCreate = React.lazy(
  () => import('./LearningSpaceClassesCreate')
)

export const LearningSpaceClassDetail = React.lazy(
  () => import('./LearningSpaceClassDetail')
)

export const LearningSpaceClassDetailPeople = React.lazy(
  () => import('./LearningSpaceClassDetailPeople')
)

export const LearningSpaceClassDetailCourses = React.lazy(
  () => import('./LearningSpaceClassDetailCourses')
)
export const LearningSpaceClassDetailStudentsAdd = React.lazy(
  () => import('./LearningSpaceClassDetailStudentsAdd')
)

export const LearningSpaceClassDetailCoursesAdd = React.lazy(
  () => import('./LearningSpaceClassDetailCoursesAdd')
)

export const CourseCertificate = React.lazy(() => import('./CourseCertificate'))

export const CertificateGallery = React.lazy(
  () => import('./CertificateGallery')
)

export const OrderDashboard = React.lazy(() => import('./OrderDashboard'))

export const ClassDetailArea = React.lazy(() => import('./ClassDetailArea'))
export const ClassDetailGeneralInfo = React.lazy(
  () => import('./ClassDetailGeneralInfo')
)
export const ClassDetailReport = React.lazy(() => import('./ClassDetailReport'))
export const ClassDetailPeople = React.lazy(() => import('./ClassDetailPeople'))
export const ClassEventAttendance = React.lazy(
  () => import('./ClassEventAttendance')
)
export const LearningSpaceClassEdit = React.lazy(
  () => import('./LearningSpaceClassEdit')
)

export const ClassDetailStudy = React.lazy(() => import('./ClassDetailStudy'))

export const ClassDetailStudyArea = React.lazy(
  () => import('./ClassDetailStudyArea')
)

export const ClassDetailStudyAssignment = React.lazy(
  () => import('./ClassDetailStudyAssignment')
)

export const RedirectToClassroomFromClass = React.lazy(
  () => import('./RedirectToClassroomFromClass')
)

export const ClassDetailStudyAssignmentReview = React.lazy(
  () => import('./ClassDetailStudyAssignmentReview')
)

export const ClassDetailStudyQuizReview = React.lazy(
  () => import('./ClassDetailStudyQuizReview')
)

export const LearningSpaceClassDetailStudy = React.lazy(
  () => import('./LearningSpaceClassDetailStudy')
)

export const LSClassDetailStudyAddCourse = React.lazy(
  () => import('./LSClassDetailStudyAddCourse')
)

export const LSClassDetailStudyAddLesson = React.lazy(
  () => import('./LSClassDetailStudyAddLesson')
)

export const LSClassDetailStudyAddExternalLink = React.lazy(
  () => import('./LSClassDetailStudyAddExternalLink')
)

export const LSClassDetailStudyAddAttachment = React.lazy(
  () => import('./LSClassDetailStudyAddAttachment')
)

export const LSClassDetailStudyAddAssignment = React.lazy(
  () => import('./LSClassDetailStudyAddAssignment')
)

export const LSClassDetailStudyEditAssignment = React.lazy(
  () => import('./LSClassDetailStudyEditAssignment')
)

export const LSClassDetailStudyEditExternalLink = React.lazy(
  () => import('./LSClassDetailStudyEditExternalLink')
)

export const LSProgramAssignmentEdit = React.lazy(
  () => import('./LSProgramAttachmentEdit')
)

export const Survey = React.lazy(() => import('./Survey'))

export const ClassProgramCourseDetail = React.lazy(
  () => import('./ClassProgramCourseDetail')
)

export const ClassDetailGradeActivities = React.lazy(
  () => import('./ClassDetailGradeActivities')
)
export const ClassDetailStudyAssignmentGrade = React.lazy(
  () => import('./ClassDetailStudyAssignmentGrade')
)
export const LearningSpaceClassAddEvent = React.lazy(
  () => import('./LearningSpaceClassAddEvent')
)

export const LearningSpaceClassAddQuiz = React.lazy(
  () => import('./LearningSpaceClassAddQuiz')
)

export const LSProgramSectionSurveyAct = React.lazy(
  () => import('./LSClassDetailAddSurvey')
)

export const QuestionBanks = React.lazy(() => import('./QuestionBanks'))
export const QuestionBankDetail = React.lazy(
  () => import('./QuestionBankDetail')
)
export const LearningSpaceAddQuestion = React.lazy(
  () => import('./LearningSpaceAddQuestion')
)

export const LearningSpaceEditQuestion = React.lazy(
  () => import('./LearningSpaceEditQuestion')
)

export const ClassQuiz = React.lazy(() => import('./ClassQuiz'))

export const ClassQuizExecute = React.lazy(() => import('./ClassQuizExecute'))
export const ClassQuizReview = React.lazy(() => import('./ClassQuizReview'))
export const AddNewLecture = React.lazy(() => import('./AddNewLecture'))
export const EditLecture = React.lazy(() => import('./EditLecture'))
export const LectureDetail = React.lazy(() => import('./LectureDetail'))

export const AddActivity = React.lazy(() => import('./AddActivity'))
export const EditActivity = React.lazy(() => import('./EditActivity'))

export const CustomCourses = React.lazy(() => import('./CustomCourses'))

export const CreateCustomCourse = React.lazy(
  () => import('./CreateCustomCourse')
)

export const LearningSpaceCustomCourseDetail = React.lazy(
  () => import('./LearningSpaceCustomCourseDetail')
)

export const LearningSpaceCustomCourseAddLecture = React.lazy(
  () => import('./LearningSpaceCustomCourseAddLecture')
)

export const LearningSpaceCustomCourseAddQuiz = React.lazy(
  () => import('./LearningSpaceCustomCourseAddQuiz')
)

export const ConfirmActivationCode = React.lazy(
  () => import('./ConfirmActivationCode')
)

export const ConfirmInvite = React.lazy(() => import('./ConfirmInvite'))

export const LearningSpaceCourseQuizDetail = React.lazy(
  () => import('./LearningSpaceCourseQuizDetail')
)
export const CertificationExams = React.lazy(
  () => import('./CertificationExams')
)
export const CertificationExamDetail = React.lazy(
  () => import('./CertificationExamDetail')
)
export const CertificationExamImportUsers = React.lazy(
  () => import('./CertificationExamImportUsers')
)
export const CertificationExamExecute = React.lazy(
  () => import('./CertificationExamExecute')
)
export const CertificationExamResult = React.lazy(
  () => import('./CertificationExamResult')
)
export const StdCertificationExam = React.lazy(
  () => import('./StdCertificationExam')
)
export const StudentCourseQuizSubmissions = React.lazy(
  () => import('./StudentCourseQuizSubmissions')
)
export const StudentCourseQuizSubmissionDetail = React.lazy(
  () => import('./StudentCourseQuizSubmissionDetail')
)
export const LearningSpaceCourseImportUsers = React.lazy(
  () => import('./LearningSpaceCourseImportUsers')
)
export const CertificationExamImportCertified = React.lazy(
  () => import('./CertificationExamImportCertified')
)

export const LearningSpaceClassEditEvent = React.lazy(
  () => import('./LearningSpaceClassEditEvent')
)

export const LearningSpaceClassChecklists = React.lazy(
  () => import('./LearningSpaceClassChecklists')
)
export const LearningSpaceClassAddChecklist = React.lazy(
  () => import('./LearningSpaceClassAddChecklist')
)
export const LearningSpaceClassCheckListDetail = React.lazy(
  () => import('./LearningSpaceClassCheckListDetail')
)
export const LearningSpaceClassCreateBadge = React.lazy(
  () => import('./LearningSpaceClassCreateBadge')
)

export const LearningSpaceClassSettings = React.lazy(
  () => import('./LearningSpaceClassSettings')
)

export const LearningSpaceClassExpense = React.lazy(
  () => import('./LearningSpaceClassExpense')
)

export const LearningSpaceClassDetailDiligence = React.lazy(
  () =>
    import(
      'views/LearningSpaceClassDetailDiligence/LearningSpaceClassDetailDiligence'
    )
)

export const ClassCourseSettings = React.lazy(
  () => import('views/ClassCourseSettings/ClassCourseSettings')
)

export const EditCourseSettings = React.lazy(
  () => import('./LSClassDetailProgramEditCourseSettings')
)

export const TeachingHoursStatistics = React.lazy(
  () => import('./TeachingHoursStatistics')
)
export const ClassSectionSettings = React.lazy(
  () => import('./ClassSectionSettings')
)

export const ClassCourseSectionSettings = React.lazy(
  () => import('./ClassCourseSectionSettings')
)

export const ClassDetailStudyQuiz = React.lazy(
  () => import('./ClassDetailStudyQuiz')
)

export const ClassDetailStudyQuizSummary = React.lazy(
  () => import('./ClassDetailStudyQuizSummary')
)

export const LearningSpaceCourseQuiz = React.lazy(
  () => import('./LearningSpaceCourseQuiz')
)

export const LearningSpaceCourseQuizSummary = React.lazy(
  () => import('./LearningSpaceCourseQuizSummary')
)

export const Lectures = React.lazy(() => import('./Lectures'))
export const Guidelines = React.lazy(() => import('./Guidelines'))
export const AdminReportClass = React.lazy(() => import('./AdminReportClass'))
export const StatisticTeachingHours = React.lazy(
  () => import('./StatisticTeachingHours')
)
export const ClassReflectionReview = React.lazy(
  () => import('./ClassReflectionReview')
)

export const ImportClassPeople = React.lazy(() => import('./ImportClassPeople'))
export const ClassStudentModuleGradeReport = React.lazy(
  () => import('./ClassStudentModuleGradeReport')
)
export const ResetPassword = React.lazy(() => import('./ResetPassword'))
export const WorkflowReport = React.lazy(() => import('./WorkflowReport'))
