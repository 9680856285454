import { Theme } from '@material-ui/core'
import { firestore } from 'firebase'
import { LearningSpaceClassUser } from 'types'
import { omit } from 'lodash'
import { IFirestoreMetadata } from 'interfaces'
import { getCollectionData } from './FirestoreUtils'

export const getNewLearningSpaceClassUser = (data: {
  name: string
  email: string
  id: string
  statistics?: {
    totalCompleted: number
    totalDropout: number
    totalFailed: number
    totalInProgress: number
    totalStudyTime: number
  }
  photoUrl?: string
  timeTracks?: {
    createdAt: firestore.FieldValue | firestore.Timestamp
    joinedAt: firestore.FieldValue | firestore.Timestamp
    updatedAt: firestore.FieldValue | firestore.Timestamp
  }
  addBy: string
  roles?: { [key: string]: boolean }
  customFields?: {
    [key: string]: any
  }
}) => {
  const newUserAdd: LearningSpaceClassUser = {
    roles: { student: true },
    state: 'ACTIVE',
    statistics: {
      totalCompleted: 0,
      totalDropout: 0,
      totalFailed: 0,
      totalInProgress: 0,
      totalStudyTime: 0
    },
    timeTracks: {
      createdAt: firestore.FieldValue.serverTimestamp(),
      joinedAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    },
    ...omit(data, ['statistics', 'timeTracks'])
  }

  if (data.statistics) {
    newUserAdd.statistics = data.statistics
  }
  if (data.timeTracks) {
    newUserAdd.timeTracks = data.timeTracks
  }

  return newUserAdd
}

export const getNewLearningSpaceClassUserAssistant = (data: {
  name: string
  email: string
  id: string
  statistics?: {
    totalCompleted: number
    totalDropout: number
    totalFailed: number
    totalInProgress: number
    totalStudyTime: number
  }
  photoUrl?: string
  timeTracks?: {
    createdAt: firestore.FieldValue | firestore.Timestamp
    joinedAt: firestore.FieldValue | firestore.Timestamp
    updatedAt: firestore.FieldValue | firestore.Timestamp
  }
  addBy: string
  customFields?: {
    [key: string]: any
  }
}) => {
  const newUserAdd: LearningSpaceClassUser = {
    roles: { assistant: true },
    state: 'ACTIVE',
    statistics: {
      totalCompleted: 0,
      totalDropout: 0,
      totalFailed: 0,
      totalInProgress: 0,
      totalStudyTime: 0
    },
    timeTracks: {
      createdAt: firestore.FieldValue.serverTimestamp(),
      joinedAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    },
    ...data
  }

  return newUserAdd
}

export const getNewLearningSpaceClassUserInstructor = (data: {
  name: string
  email: string
  id: string
  statistics?: {
    totalCompleted: number
    totalDropout: number
    totalFailed: number
    totalInProgress: number
    totalStudyTime: number
  }
  photoUrl?: string
  timeTracks?: {
    createdAt: firestore.FieldValue | firestore.Timestamp
    joinedAt: firestore.FieldValue | firestore.Timestamp
    updatedAt: firestore.FieldValue | firestore.Timestamp
  }
  addBy: string
  customFields?: {
    [key: string]: any
  }
}) => {
  const newUserAdd: LearningSpaceClassUser = {
    roles: { instructor: true },
    state: 'ACTIVE',
    statistics: {
      totalCompleted: 0,
      totalDropout: 0,
      totalFailed: 0,
      totalInProgress: 0,
      totalStudyTime: 0
    },
    timeTracks: {
      createdAt: firestore.FieldValue.serverTimestamp(),
      joinedAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    },
    ...data
  }

  return newUserAdd
}

export function getLearningSpaceClassUserTextState(
  status: LearningSpaceClassUser['state']
) {
  switch (status) {
    case 'ACTIVE':
      return 'Đang hoạt động'
    case 'INACTIVE':
      return 'Đã khóa'
    default:
      return ''
  }
}

export function getLearningSpaceClassUserColorState(
  status: LearningSpaceClassUser['state'],
  theme: Theme
) {
  switch (status) {
    case 'ACTIVE':
      return theme.palette.success.main
    case 'INACTIVE':
      return theme.palette.error.main
    default:
      return ''
  }
}

export const getLearningSpaceClassStudents = (
  users: Array<LearningSpaceClassUser & IFirestoreMetadata>
) => {
  const result: Array<LearningSpaceClassUser & IFirestoreMetadata> = []
  users.forEach((u) => {
    if (
      u.roles.student &&
      !u.roles.assistant &&
      !u.roles.instructor &&
      !u.roles.owner
    ) {
      result.push(u)
    }
  })
  return result
}

export const getPaginationStudents = async (
  classRef: firestore.DocumentReference,
  pageToken: string | null,
  limit: number,
  orderBy: ['email', 'asc' | 'desc']
) => {
  let query = classRef
    .collection('users')
    .where('roles.student', '==', true)
    .orderBy(orderBy[0], orderBy[1])
    .limit(limit)

  if (pageToken) {
    const doc = await classRef.collection('users').doc(pageToken).get()
    query = query.startAfter(doc)
  }

  return getCollectionData<LearningSpaceClassUser>(query, { idField: 'id' })
}
