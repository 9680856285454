import React, { useCallback, useMemo } from 'react'

interface IProps {
  imageUrl: string
  width: number
  height: number
  title: string
  titleColor: string
  titleFontSize: number
  subtitle: string
  certBoxMT: number
  subtitleColor: string
  subtitleFontSize: number
  nameColor: string
  nameFontSize: number
  programColor: string
  programFontSize: number
  certDateColor: string
  certDateFontSize: number
}

const PreviewCertificateTemplate: React.FC<IProps> = ({
  imageUrl,
  width,
  height,
  title,
  subtitle: sub_title,
  certBoxMT,
  titleColor,
  titleFontSize,
  subtitleColor,
  subtitleFontSize,
  nameColor,
  nameFontSize,
  programColor,
  programFontSize,
  certDateColor,
  certDateFontSize
}) => {
  const ratio = useMemo(() => height / width, [width, height])

  const certBoxMTPercent = useMemo(
    () => certBoxMT / height,
    [height, certBoxMT]
  )

  const name = 'Nguyễn Văn A'
  const source_title = 'Được việc'
  const date = '22/11/2001'

  const getCertHtml = useCallback(() => {}, [])

  return (
    <div
      onClick={getCertHtml}
      id="cert-box"
      style={{ width: width, position: 'relative', maxWidth: width }}
    >
      <img
        style={{ width: '100%', aspectRatio: '1/' + ratio.toString() }}
        src={imageUrl}
        alt="certification"
      />
      <div
        style={{
          position: 'absolute',
          textAlign: 'center',
          top: certBoxMTPercent * 100 + '%',
          left: 0,

          right: 0,
          width: '50%',
          margin: 'auto'
        }}
        className="cert-info-box"
      >
        <p
          style={{ color: titleColor, fontSize: titleFontSize + 'px' }}
          className="cert-info-title"
        >
          {title}
        </p>
        <p
          style={{ color: nameColor, fontSize: nameFontSize + 'px', margin: 0 }}
          className="cert-info-student"
        >
          <b>{name}</b>
        </p>
        <p
          style={{ color: subtitleColor, fontSize: subtitleFontSize + 'px' }}
          className="cert-info-subtitle"
        >
          {sub_title}
        </p>
        <p
          style={{ color: programColor, fontSize: programFontSize + 'px' }}
          className="cert-info-program-name"
        >
          <b>{source_title}</b>
        </p>
        <p
          style={{ color: certDateColor, fontSize: certDateFontSize + 'px' }}
          className="cert-info-cert-date"
        >
          {date}
        </p>
      </div>
    </div>
  )
}

export default PreviewCertificateTemplate
