import { TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import React from 'react'
import snakeCase from 'lodash/snakeCase'

interface IProps {
  tags: TagOptionType[]
  selectedTags: TagOptionType[]
  onChangeSelectedTags: (tags: TagOptionType[]) => void
}

const filter = createFilterOptions()

interface TagOptionType {
  inputValue?: string
  display: string
  id: string
  needCreation?: boolean
}

const AddableTagsAutoComplete: React.FC<IProps> = ({
  tags,
  selectedTags,
  onChangeSelectedTags: onChangeSeletedTags
}) => {
  return (
    <Autocomplete
      value={selectedTags}
      fullWidth
      onChange={(event, newValue) => {
        onChangeSeletedTags(newValue as any)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params as any) as TagOptionType[]
        if (params.inputValue !== '' && filtered.length === 0) {
          filtered.push({
            inputValue: params.inputValue,
            display: `Thêm mới "${params.inputValue}"`,
            id: snakeCase(params.inputValue),
            needCreation: true
          })
        }

        return filtered
      }}
      multiple
      id="addable-tag-autocomplete"
      options={tags}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        if (option.inputValue) {
          return option.inputValue
        }
        return option.display
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(option) => option.display}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="" variant="outlined" fullWidth />
      )}
    />
  )
}

export default AddableTagsAutoComplete
