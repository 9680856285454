import firebase, { firestore } from 'firebase'

export async function validateUserInput(
  input: {
    learningSpaceId: string
    organizationName: string
    phone: string
  },
  reCaptchaVerified: boolean
): Promise<{ [key: string]: string }> {
  let error: { [key: string]: string } = {}

  if (!input.learningSpaceId) {
    error.learningSpaceId = 'Bạn phải điền tên không gian học tập'
  } else {
    if (!containsSpecialCharacter(input.learningSpaceId)) {
      const learningSpaceExist = await checkIfLearningSpaceExist(
        input.learningSpaceId
      )

      if (learningSpaceExist) {
        error.learningSpaceId =
          'Không gian học tập này đã tồn tại. Vui lòng nhập tên khác.'
      }
    }

    if (input.learningSpaceId.length > 100) {
      error.learningSpaceId = 'Tên không gian học tập quá dài.'
    }

    if (hasUpperCase(input.learningSpaceId)) {
      error.learningSpaceId =
        'Tên không gian học tập không được có ký tự viết hoa.'
    }

    if (containsSpecialCharacter(input.learningSpaceId)) {
      error.learningSpaceId =
        'Tên không gian học tập không được chứa ký tự đặc biệt.'
    }
  }

  if (!reCaptchaVerified) {
    error.reCaptcha = 'Vui lòng xác nhận reCAPTCHA'
  }

  if (!input.organizationName) {
    error.organizationName = 'Không được bỏ trống tên tổ chức.'
  }

  const numberRegex = /^[0-9]+/

  if (!input.phone) {
    error.phone = 'Không được bỏ trống số điện thoại.'
  } else if (!numberRegex.test(input.phone)) {
    error.phone = 'Số điện thoại không đúng định dạng.'
  }

  return error
}

declare global {
  interface Window {
    recaptchaVerifier: any
    recaptchaWidgetId: any
  }
}

export const renderReCaptcha = (
  onReCaptchaVerified: () => void,
  onReCaptchaExpired: () => void
) => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'normal',
      callback: function (response: any) {
        if (response) onReCaptchaVerified()
      },
      'expired-callback': function () {
        onReCaptchaExpired()
      }
    }
  )
  window.recaptchaVerifier.render().then(function (widgetId: number) {
    window.recaptchaWidgetId = widgetId
  })
}

const checkIfLearningSpaceExist = async (
  learningSpaceId: string
): Promise<boolean> => {
  const db = firestore()
  const learningSpace = await db
    .collection('learning_spaces')
    .doc(learningSpaceId)
    .get()

  return learningSpace.exists
}

const hasUpperCase = (string: string) => {
  return /[A-Z]/.test(string)
}

const containsSpecialCharacter = (string: string) => {
  return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(string)
}
