/* eslint-disable no-use-before-define */
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'

const filter = createFilterOptions()

interface IProps {
  options: {
    id: string
    label: string
  }[]
  value: null | string | { inputValue: string }
  onSelect: (item: { id?: string; label?: string; inputValue?: string }) => void
  placeholder?: string
  error?: string
  label?: string
}

const CreatableAutocomplete: React.FC<IProps> = ({
  options,
  value,
  onSelect,
  placeholder,
  error,
  label
}) => {
  return (
    <Autocomplete
      onChange={(event, newValue) => {
        const v: string | null | { [key: string]: any } = newValue
        onSelect(v as any)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params as any)

        // Suggest the creation of a new value
        if (params.inputValue !== '' && !filtered.length) {
          filtered.push({
            inputValue: params.inputValue,
            label: `+ Tạo mới "${params.inputValue}"`
          })
        }

        return filtered as any
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option: { [key: string]: any } | string) => {
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue
        }
        // Regular option
        return option.label
      }}
      renderOption={(option) => option.label}
      getOptionSelected={(option) => option.id === value}
      freeSolo
      defaultValue={typeof value === 'object' && value ? value.inputValue : options.find(o => o.id === value)?.label}
      size={'small'}
      fullWidth
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          {...params}
          helperText={error}
          error={!!error}
          label={label}
          variant="outlined"
        />
      )}
    />
  )
}

export default CreatableAutocomplete
