import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { merge } from 'lodash'

// Define a type for the slice state
interface IProgramActivities {
  [k: string]: {
    open: boolean
    lectures?: { [k: string]: boolean }
  }
}

// Define the initial state using that type
const initialState: IProgramActivities = {}

export const programActivities = createSlice({
  name: 'programActivities',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    open: (state, action: PayloadAction<string>) => {
      state[action.payload] = {
        open: true
      }
    },
    close: (state, action: PayloadAction<string>) => {
      state[action.payload] = {
        open: false
      }
    },
    collapseLecture: (
      state,
      action: PayloadAction<{
        lectureId: string
        actId: string
      }>
    ) => {
      if (state[action.payload.actId] && state[action.payload.actId].open) {
        const lectures = state[action.payload.actId].lectures || {}
        state[action.payload.actId].lectures = merge(lectures, {
          [action.payload.lectureId]: !lectures[action.payload.lectureId]
        })
      }
    }
  }
})

export const { open, collapseLecture } = programActivities.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSection = (state: RootState, id: string) =>
  state.programActivities[id] || { open: false }

export const selectLecture = (
  state: RootState,
  actId: string,
  lectureId: string
) => {
  return (selectSection(state, actId).lectures || {})[lectureId]
}

export default programActivities.reducer
