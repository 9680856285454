import { Dispatch } from '@reduxjs/toolkit'
import { firestore } from 'firebase'
import debounce from 'lodash/debounce'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTagLoadState, setLoading, setTags } from 'reducers/redux/tags'
import { Tag } from 'types'
import { getCollectionAssoc } from 'utils/FirestoreUtils'
import { useLearningSpace } from '../contexts/LearningSpaceContext'

const getTags = debounce(
  async (spaceRef: firestore.DocumentReference, dispatch: Dispatch<any>) => {
    console.debug('Loading tags')
    const tagCollection = spaceRef.collection('tags')

    const results = await getCollectionAssoc<Tag>(tagCollection, 'id', {
      idField: 'id'
    })

    dispatch(setTags(results))
    console.debug('Tags loaded')
  }
)

export const useLoadTags = () => {
  const loadState = useSelector(selectTagLoadState)
  const space = useLearningSpace()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!loadState.loading && !loadState.loaded) {
      dispatch(setLoading(true))

      getTags(space._meta.ref, dispatch)
    }
  }, [space, dispatch, loadState.loaded, loadState.loading])
}
