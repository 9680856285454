import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { firestore } from 'firebase'
import { DateTime } from 'luxon'
export const TIMEZONE = "Asia/Ho_Chi_Minh"

const fullDatetimeFormatter = new Intl.DateTimeFormat('vi-VN', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
})

/**
 * Convert `Date` to `DD/MM/YYYY hh:mm` string
 * @param {Date} date
 * @returns DD/MM/YYYY hh:mm string
 */
export function longDateAndTimeFormat(date: Date): string {
  const [
    { value: hh },
    ,
    { value: mm },
    ,
    ,
    ,
    // { value: ss },
    { value: DD },
    ,
    { value: MM },
    ,
    { value: YYYY }
  ] = fullDatetimeFormatter.formatToParts(date)

  return `${DD}/${MM}/${YYYY} ${hh}:${mm}`
}

export function parseStatisticDate(date: MaterialUiPickersDate): string {
  const [
    ,
    ,
    ,
    ,
    ,
    ,
    // { value: ss },
    { value: DD },
    ,
    { value: MM },
    ,
    { value: YYYY }
  ] = fullDatetimeFormatter.formatToParts(date as Date)

  return `${YYYY}-${MM}-${DD}`
}

export function commonDateAndTimeFormat(date: Date): string {
  const [
    ,
    ,
    ,
    ,
    ,
    ,
    // { value: ss },
    { value: DD },
    ,
    { value: MM },
    ,
    { value: YYYY }
  ] = fullDatetimeFormatter.formatToParts(date)

  return `${DD}/${MM}/${YYYY}`
}

export const daysApartFromPresent = (dateToCompare: Date): number => {
  // return >0 if dateToCompare is in the future
  // return <0 if dateToCompare is in the past

  const present = Date.now()
  const millisecondsPerDay = 1000 * 60 * 60 * 24

  const millisecondsBetween = dateToCompare.getTime() - present

  return ~~(millisecondsBetween / millisecondsPerDay)
}

export const parseTimeByFormat = (
  date: Date | null | string,
  format: string = 'dd/MM/YYYY'
) => {
  if (!date) {
    return ''
  }
  const d: Date = typeof date === 'string' ? new Date(date) : date

  const [
    { value: hh },
    ,
    { value: mm },
    ,
    { value: ss },
    ,
    { value: dd },
    ,
    { value: MM },

    ,
    { value: YYYY }
  ] = fullDatetimeFormatter.formatToParts(d)

  let time = format.replace('hh', hh)
  time = time.replace('mm', mm)
  time = time.replace('ss', ss)
  time = time.replace('dd', dd)
  time = time.replace('MM', MM)
  time = time.replace('YYYY', YYYY)

  return time
}

export function setTimeZone(date: Date, zone = 'Asia/Ho_Chi_Minh'): Date {
  return new Date(date.toLocaleString('en-us', { timeZone: zone }))
}

export function getServerTime() {
  return new Promise<string>((resolve) => {
    let xhr = new XMLHttpRequest() //XMLHttpRequest object is used to exchange data with the server in the background.
    if (!xhr) {
      xhr = new ActiveXObject('Microsoft.XMLHTTP') // Old versions of Internet Explorer (IE5 and IE6) use ActiveX objects
    }
    xhr.open(
      'HEAD',
      window.location.protocol + '//' + window.location.host,
      true
    ) // Request to get the HEAD of the server
    //accept-ranges: bytes cache-control: no-cache,no-cache content-encoding: gzip content-length: 191 content-type: text/plain date: Mon, 11 May 2020 05:44:33 GMT etag: "b4f95099808cce1:0" last-modified: Mon, 29 Jul 2013 17:25:19 GMT server: Microsoft-IIS/10.0 status: 304 vary: Accept-Encoding x-powered-by: ASP.NET
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(xhr.getResponseHeader('Date') as string)
      }
    }
    xhr.send(null)
  })
}

export function parseUnixTime(time: number) {
  const timeBySecond = time / 1000
  const timeByMinute = timeBySecond / 60
  const timeByHour = timeByMinute / 60

  let result = ''

  const hour = Math.floor(timeByHour)
  const minute = Math.floor(timeByMinute % 60)
  const second = Math.floor(timeBySecond % 60)

  if (hour === 0) {
    if (minute === 0) {
      result = `${second} giây`
    } else result = `${minute} phút`
  } else {
    result = `${hour} giờ ${minute} phút`
  }

  return result
}

export const unlimitedTime = 4102419600000
export const msInOneDay = 1000 * 60 * 60 * 24

export const timestampNow = () => firestore.Timestamp.fromDate(DateTime.fromObject({}, {zone: TIMEZONE}).toJSDate())

export function timeFormatForPath(date: Date): string {
  const [
    { value: hh },
    ,
    { value: mm },
    ,
    ,
    ,
    { value: DD },
    ,
    { value: MM },
    ,
    { value: YYYY }
  ] = fullDatetimeFormatter.formatToParts(date)

  return `${DD}${MM}${YYYY}${hh}${mm}`
}