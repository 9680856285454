import React from 'react'
import { InputLabel as IL } from '@material-ui/core'

interface IProps {}

export const InputLabel: React.FC<IProps> = ({ children }) => {
  return (
    <IL style={{ fontSize: '1em', marginBottom: 4, fontWeight: 500 }}>
      {children}
    </IL>
  )
}

export default InputLabel
