import ErrorBlock from 'components/ErrorBlock'
import LearningSpaceContext from 'contexts/LearningSpaceContext'
import { useDocumentData } from 'hooks'
import React, { useMemo } from 'react'
import { useFirestore } from 'reactfire'
import { LearningSpaceType } from 'types'

interface IProps {
  domain: string
}

const LearningSpaceProvider: React.FC<IProps> = (props) => {
  const { children } = props

  const db = useFirestore()
  const learningSpace = useDocumentData<LearningSpaceType>(
    db.doc(`learning_spaces/${props.domain}`),
    { idField: 'id' }
  )

  const location = useMemo(() => window.location, [])

  if (!learningSpace) {
    return (
      <ErrorBlock
        backUrl={location.protocol + '//' + location.host.split('.').pop()}
        backButtonTitle="Chọn khu vực khác"
        title="Xin lỗi, chúng tôi không thể tìm thấy khu vực học tập mà bạn đang tìm kiếm!"
      ></ErrorBlock>
    )
  }

  return (
    <>
      <LearningSpaceContext.Provider value={learningSpace}>
        {children}
      </LearningSpaceContext.Provider>
    </>
  )
}

export default LearningSpaceProvider
