import config from 'configs'

/**
 * combine many string and return page title
 */
export const combineTitle = (sep: string, ...args: string[]): string => {
  const sanitized: string[] = []
  for (let i = 0; i < args.length; ++i) {
    if (args[i]) {
      sanitized.push(args[i])
    }
  }
  return sanitized.join(sep)
}

export const getPageTitle = (title?: string): string => {
  if (!title) {
    return combineTitle(' - ', config.app.siteName, config.app.siteSlogan)
  }
  return combineTitle(' - ', title, config.app.siteName)
}

export const getOldDirection = () => {
  let oldDirection = localStorage.getItem('old_direction')
  if (!oldDirection) {
    oldDirection = '/'
  } else {
    localStorage.removeItem('old_direction')
  }

  return oldDirection
}
