import React, { useCallback, useMemo } from 'react'
import { ClassReflectionSubmitRateStatistics } from 'types'
import { IFirestoreMetadata } from 'interfaces'
import { Bar } from 'react-chartjs-2'
import { Grid, useTheme } from '@material-ui/core'
import { ChartDataSets } from 'chart.js'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { useReflectionTemplates } from 'hooks/useReflectionTemplate'

interface Props {
  statistics: (ClassReflectionSubmitRateStatistics & IFirestoreMetadata)[]
  selectedClasses: { selected: boolean; className: string; id: string }[]
}

const ReflectionCompletionRateComparisonBarCharts: React.FC<Props> = (
  props
) => {
  const learningSpace = useLearningSpace()
  const { selectedClasses, statistics } = props
  const theme = useTheme()
  const reflectionTemplates = useReflectionTemplates()

  const averagesByReflectionId = useCallback(
    (reflectionId: string) => {
      let result: { [id: string]: { [classId: string]: { average: number } } } =
        { [reflectionId]: {} }
      selectedClasses.forEach((classInfo) => {
        const classStatistics = statistics.filter(
          (statistic) =>
            statistic.classId === classInfo.id &&
            statistic.learningSpaceId === learningSpace.id &&
            statistic.reflectionId === reflectionId
        )

        let totalCompleteCourse = 0
        let totalCourses = 0
        classStatistics.forEach((statistic) => {
          totalCompleteCourse += statistic.totalSubmittedStudents
          totalCourses += statistic.totalStudents
        })

        const average = totalCompleteCourse / totalCourses

        result[reflectionId][classInfo.id] = {
          average: Math.round(average * 100)
        }
      })
      return result
    },
    [learningSpace.id, selectedClasses, statistics]
  )

  const labels = useMemo(
    () => selectedClasses.map((classInfo) => classInfo.className),
    [selectedClasses]
  )

  const datasetsByReflectionId = useCallback(
    (reflectionId: string) => {
      const datasets: ChartDataSets[] = [
        {
          label: 'Tỉ lệ hoàn thành reflection trung bình (%)',
          data: selectedClasses.map((classInfo) =>
            averagesByReflectionId(reflectionId)[reflectionId][classInfo.id]
              ?.average
              ? averagesByReflectionId(reflectionId)[reflectionId][classInfo.id]
                  .average
              : 0
          ),
          backgroundColor: theme.palette.primary.main
        }
      ]

      return datasets
    },
    [averagesByReflectionId, selectedClasses, theme.palette.primary.main]
  )

  return (
    <>
      {reflectionTemplates.map((template) => (
        <Grid key={template.id} item lg={6} md={12}>
          <Bar
            height={80}
            data={{
              labels: labels,
              datasets: datasetsByReflectionId(template.id)
            }}
            options={{
              legend: {
                display: false
              },
              scales: {
                xAxes: [
                  {
                    barPercentage: 0.2
                  } as any
                ]
              },
              title: {
                text: `Tổng quan tỉ lệ hoàn thành reflection ${template.title}`,
                display: true
              }
            }}
          />
        </Grid>
      ))}
    </>
  )
}

export default ReflectionCompletionRateComparisonBarCharts
