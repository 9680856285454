import React from 'react'
import Footer from 'components/Footer'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  notEnableFooter?: boolean
}

const BlankLayout: React.FC<IProps> = (props) => {
  const { notEnableFooter } = props

  return (
    <div className="blank-layout">
      <div className="body">
        {props.children}
        <div style={{ padding: '20px' }}>{!notEnableFooter && <Footer />}</div>
      </div>
    </div>
  )
}

export default BlankLayout
