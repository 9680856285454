import React, { useCallback, useState } from 'react'
import { Box, Container, LinearProgress } from '@material-ui/core'
import PageHeaderAndBackBtn from 'components/PageHeaderAndBackBtn'
import { useLearningSpace } from 'contexts/LearningSpaceContext'
import { useFunctions, useReferenceDocumentDataOnce } from 'hooks'
import { useParams } from 'react-router-dom'
import ErrorBlock from 'components/ErrorBlock'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Stepper from 'components/Stepper'
import CEImportUserConfigStep from 'widgets/CEImportUserConfigStep/CEImportUserConfigStep'
import CEImportUserReviewStep from 'widgets/CEImportUserReviewStep'
import { useSnackbar } from 'contexts/SnackbarContext'
import CertificationExamImportCompleteStep from 'widgets/CertificationExamImportCompleteStep'
import ConfirmProvider from 'providers/ConfirmProvider'

interface IProps {}
interface IPropsBody {
  certificationExam: { title: string }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1)
    },
    typography: {
      marginTop: theme.spacing(1)
    }
  })
)

const CertificationExamImportUsersBody: React.FC<IPropsBody> = () => {
  const classes = useStyles(() => {})
  const [activeStep, setActiveStep] = useState(0)
  const [uploadData, setUploadData] = useState<
    { line: number; email: string; name: string; password: string }[]
  >([])
  const [config, setConfig] = useState<{
    startAt: Date | undefined
    finishAt: Date | undefined
  }>({
    finishAt: undefined,
    startAt: undefined
  })
  const { id } = useParams<{ id: string }>()
  const [result, setResult] = useState<{ [key: string]: any }[]>()
  const [examSession, setExamSession] = useState<
    string | { inputValue: string }
  >('')

  const handleChangeConfig = useCallback(
    (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
      setConfig((c) => ({ ...c, [e.target.name]: e.target.value }))
    },
    []
  )

  const removeRow = useCallback((index: number) => {
    setUploadData((ud) => {
      ud.splice(index, 1)
      return [...ud]
    })
  }, [])

  const functions = useFunctions()
  const space = useLearningSpace()

  const toast = useSnackbar()

  const handleImport = useCallback(async () => {
    try {
      const callable = functions.httpsCallable(
        'callable-importUsersToCertificationExam'
      )
      const r = await callable({
        data: uploadData,
        spaceId: space.id,
        examId: id,
        config: {
          startAt: config.startAt
            ? new Date(config.startAt).toUTCString()
            : null,
          finishAt: config.finishAt
            ? new Date(config.finishAt).toUTCString()
            : null
        },
        examSession: examSession
      })
      setResult(r.data)
      setActiveStep(2)
    } catch (e) {
      toast('Có lỗi xảy ra!', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, uploadData, config, space.id, examSession])

  const handleGoBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <>
      <Box mb={5}>
        <Stepper
          steps={['Nhập dữ liệu', 'Kiểm tra', 'Hoàn thành']}
          activeStep={activeStep}
        />
      </Box>

      <Container maxWidth={'xl'}>
        <CEImportUserConfigStep
          changeConfig={handleChangeConfig}
          config={config}
          classes={classes}
          setActiveStep={setActiveStep}
          setUploadData={setUploadData}
          step={activeStep}
          examSession={examSession}
          onChangeExamSession={setExamSession}
        />
        <CEImportUserReviewStep
          handleGoBack={handleGoBack}
          config={config}
          uploadData={uploadData}
          activeStep={activeStep}
          remove={removeRow}
          import={handleImport}
        />
        <CertificationExamImportCompleteStep
          result={result}
          step={activeStep}
        />
      </Container>
    </>
  )
}

const CertificationExamImportUsers: React.FC<IProps> = () => {
  const space = useLearningSpace()
  const { id } = useParams<{ id: string }>()

  const {
    data: certificationExam,
    loading,
    error
  } = useReferenceDocumentDataOnce<{ title: string }>(
    space._meta.ref.collection('certification_exams').doc(id),
    { refField: 'certificationExamRef' }
  )

  if (loading) return <LinearProgress />
  else if (!certificationExam || (error && error.message === 'not-found'))
    return <ErrorBlock variant="404" />

  return (
    <Container maxWidth="xl">
      <ConfirmProvider>
        <Box minHeight={'75vh'}>
          <PageHeaderAndBackBtn
            label={'Tạo kỳ thi'}
            backTo={`/admin/certification-exams/${id}`}
            backTooltipLabel={certificationExam.title}
          />
          <CertificationExamImportUsersBody
            certificationExam={certificationExam}
          />
        </Box>
      </ConfirmProvider>
    </Container>
  )
}

export default CertificationExamImportUsers
