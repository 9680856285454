import React, { useMemo } from 'react'
import { ClassCourseCompleteRateStatistics } from 'types'
import { Bar } from 'react-chartjs-2'
import { useTheme } from '@material-ui/core'
import { ChartDataSets } from 'chart.js'
import { useLearningSpace } from 'contexts/LearningSpaceContext'

interface Props {
  statistics: ClassCourseCompleteRateStatistics[]
  selectedClasses: { selected: boolean; className: string; id: string }[]
}

const CourseCompletionRateComparisonBarChart: React.FC<Props> = (props) => {
  const learningSpace = useLearningSpace()
  const { selectedClasses, statistics } = props
  const theme = useTheme()

  const averages = useMemo(() => {
    let result: { [classId: string]: { average: number } } = {}
    selectedClasses.forEach((classInfo) => {
      const classStatistics = statistics.filter(
        (statistic) =>
          statistic.classId === classInfo.id &&
          statistic.learningSpaceId === learningSpace.id
      )

      let totalCompleteCourse = 0
      let totalCourses = 0
      classStatistics.forEach((statistic) => {
        totalCompleteCourse += statistic.totalComplete
        totalCourses += statistic.totalCourses
      })

      const average = totalCompleteCourse / totalCourses

      result[classInfo.id] = { average: Math.round(average * 100) }
    })
    return result
  }, [learningSpace.id, selectedClasses, statistics])

  const labels = useMemo(
    () => selectedClasses.map((classInfo) => classInfo.className),
    [selectedClasses]
  )

  const datasets = useMemo(() => {
    const datasets: ChartDataSets[] = [
      {
        label: 'Tỉ lệ hoàn thành khóa học trung bình (%)',
        data: selectedClasses.map((classInfo) =>
          averages[classInfo.id]?.average ? averages[classInfo.id].average : 0
        ),
        backgroundColor: theme.palette.primary.main
      }
    ]

    return datasets
  }, [averages, selectedClasses, theme.palette.primary.main])

  return (
    <>
      <Bar
        height={80}
        data={{ labels: labels, datasets: datasets }}
        options={{
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                barPercentage: 0.2
              } as any
            ]
          },
          title: {
            text: 'Tổng quan tỉ lệ hoàn thành khóa học online',
            display: true
          }
        }}
      />
    </>
  )
}

export default CourseCompletionRateComparisonBarChart
