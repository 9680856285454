import React, { useEffect, useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { LinearProgress } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ErrorBlock from 'components/ErrorBlock'
import ErrorBoundary from 'components/ErrorBoundary'
import Loading from 'components/Loading'
import Snackbar from 'components/Snackbar'
import SubdomainBoundary from 'components/SubdomainBoundary'
import RouteChildrenContext from 'contexts/RouteChildrenContext'
import { SnackbarContext, useToast } from 'contexts/SnackbarContext'
import SubdomainContext, { parseSubdomain } from 'contexts/SubdomainContext'
import firebase from 'firebase'
import ConfirmProvider from 'providers/ConfirmProvider'
import Authentication from 'providers/auth/Authentication'
import { Authorization } from 'providers/auth/Authorization'
import CompleteUserInformation from 'providers/auth/CompleteUserInformation'
import RoleContextProvider from 'providers/role/RoleContextProvider'
import { history } from 'providers/router/BrowserHistory'
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom'
import {
  SuspenseWithPerf,
  preloadAuth,
  preloadFirestore,
  preloadFunctions,
  preloadStorage,
  useAnalytics,
  useFirebaseApp
} from 'reactfire'
import routing from 'routing'
import { getPageTitle } from 'utils'
import {
  ConfirmActivationCode,
  ConfirmInvite,
  CourseCertificate,
  Login,
  RegisterLearningSpace,
  ResetPassword
} from 'views'
import EnterSubdomain from 'views/EnterSubDomain'
import './style.css'
import ScrollToTop from 'components/ScrollToTop'

// Our components will lazy load the SDKs to decrease their bundle size.
// Since we know that, we can start fetching them now
const preloadSDKs = (firebaseApp: firebase.app.App) => {
  return Promise.all([
    preloadFirestore({
      firebaseApp: firebaseApp
      // setup: (f) => f().settings({ host: 'localhost:8080', ssl: false })
    }),
    preloadAuth({
      firebaseApp: firebaseApp,
      setup: (auth) =>
        auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    }),
    preloadStorage({
      firebaseApp: firebaseApp,
      setup: (storage) => storage().setMaxUploadRetryTime(10000)
    }),
    preloadFunctions({
      firebaseApp: firebaseApp,
      setup: (functions) =>
        functions().useFunctionsEmulator('http://localhost:5001')
    })
  ])
}

const PageViewLogger: React.FC = () => {
  const analytics = useAnalytics()
  const { location } = history

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      analytics.logEvent('page-view', { path_name: location.pathname })
    }
  }, [location.pathname, analytics])

  return null
}

function App() {
  const [preLoadingSdk, setPreLoadingSdk] = useState(true)

  const firebaseApp = useFirebaseApp()
  useEffect(() => {
    ;(async () => {
      setPreLoadingSdk(true)
      await preloadSDKs(firebaseApp)
      setPreLoadingSdk(false)
    })()
  }, [firebaseApp])

  const subdomain = parseSubdomain()
  const toastProps = useToast()

  document.title = getPageTitle()

  if (preLoadingSdk) return <LinearProgress />

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ErrorBoundary fallback={ErrorBlock}>
        <SubdomainContext.Provider value={{ domain: subdomain }}>
          <RoleContextProvider>
            <ConfirmProvider>
              <SnackbarContext.Provider value={toastProps}>
                <Snackbar />
                <Router history={history}>
                  <BrowserRouter>
                    <ScrollToTop />
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route
                        exact
                        path="/confirm-invite"
                        component={ConfirmInvite}
                      />{' '}
                      <Route
                        exact
                        path="/reset-password"
                        component={ResetPassword}
                      />
                      <Route
                        exact
                        path="/certificate/:user_name/:course_name/:id"
                        component={CourseCertificate}
                      />
                      <Authentication>
                        <CompleteUserInformation>
                          <Route
                            exact
                            path="/confirm-activation-code"
                            component={ConfirmActivationCode}
                          />
                          <Route
                            exact
                            path="/register-learning-space"
                            component={RegisterLearningSpace}
                          />
                          {routing.map((r) => (
                            <Route
                              exact={!r.childRoutes}
                              key={r.path}
                              path={r.path}
                              render={(props) => (
                                <SubdomainBoundary fallback={EnterSubdomain}>
                                  <RouteChildrenContext.Provider
                                    value={r.childRoutes}
                                  >
                                    <SuspenseWithPerf
                                      fallback={<Loading />}
                                      traceId={'view:' + r.path}
                                    >
                                      <Authorization
                                        requiredClaims={r.requiredClaims}
                                        requiredRoles={r.requiredRoles}
                                        component={r.component}
                                        {...props}
                                      />
                                    </SuspenseWithPerf>
                                  </RouteChildrenContext.Provider>
                                </SubdomainBoundary>
                              )}
                            />
                          ))}
                        </CompleteUserInformation>
                      </Authentication>
                      <Route render={() => <ErrorBlock variant="404" />} />
                    </Switch>
                  </BrowserRouter>
                </Router>
              </SnackbarContext.Provider>
            </ConfirmProvider>
          </RoleContextProvider>
        </SubdomainContext.Provider>
        <PageViewLogger />
      </ErrorBoundary>
    </MuiPickersUtilsProvider>
  )
}

export default App
