export function validateInput(email: string): { [key: string]: string } {
  let error: { [key: string]: string } = {}
  if (!email) {
    error.email = 'Bạn phải nhập email'
  }
  if (!isEmailValid(email)) {
    error.email = 'Email không đúng định dạng'
  }
  return error
}

function isEmailValid(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
