import React from 'react'
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import BookOutlinedIcon from '@material-ui/icons/BookOutlined'

const lectureIcons: { [key: string]: React.ReactElement } = {
  media: <PlayCircleOutlineIcon />,
  practice: <TimerOutlinedIcon />,
  document: <BookOutlinedIcon />
}

export default lectureIcons
