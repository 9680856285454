const PROMISE_CACHED: { [k: string]: any } = {}

const wrapPromise = <T>(promise: Promise<T>) => {
  let status = 'pending'
  let result: T
  let suspender = promise.then(
    (r) => {
      status = 'success'
      result = r
    },
    (e) => {
      status = 'error'
      result = e
    }
  )
  return () => {
    if (status === 'pending') {
      throw suspender
    } else if (status === 'error') {
      throw result
    } else if (status === 'success') {
      return result
    }
  }
}

export const useSuspense: <T>(
  uniqueKey: string,
  handler: () => Promise<any>,
  defaultValue?: any
) => T = (uniqueKey, handler, defaultValue) => {
  if (!PROMISE_CACHED[uniqueKey]) {
    PROMISE_CACHED[uniqueKey] = wrapPromise(handler())
  }

  if (!!PROMISE_CACHED[uniqueKey]) return PROMISE_CACHED[uniqueKey]()

  return defaultValue
}
