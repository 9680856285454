import React, { useState, useRef } from 'react'
import ReactMde from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { compiler } from 'markdown-to-jsx'
import { makeStyles } from '@material-ui/core/styles'
import { styles } from './styles'
import { Box, InputLabel, Typography } from '@material-ui/core'

const useStyle = makeStyles(styles)

interface IProps {
  value: string
  onChange: (value: string) => void | undefined
  selectedTab?: 'write' | 'preview'
  minEditorHeight?: number
  error?: string
  label?: string
}

const MarkdownEditor: React.FC<IProps> = (props) => {
  const classes = useStyle()
  const {
    value,
    onChange,
    selectedTab = 'write',
    minEditorHeight = 100,
    label
  } = props
  const reactMdeRef = useRef<ReactMde>(null)
  const [selectedTabReactMde, setSelectedTabReactMde] = useState<
    'write' | 'preview'
  >(selectedTab)

  return (
    <>
      <div className={classes.editor}>
        {label && (
          <Box mb={0.5}>
            <InputLabel>{label}</InputLabel>
          </Box>
        )}
        <ReactMde
          ref={reactMdeRef}
          value={value}
          onChange={onChange}
          selectedTab={selectedTabReactMde}
          onTabChange={setSelectedTabReactMde}
          minEditorHeight={minEditorHeight}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(compiler(markdown))
          }
        />
      </div>
      <Box mt={0.5}>
        <a
          href={'https://www.markdownguide.org/basic-syntax/'}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <Typography variant="caption">
            Bạn chưa biết sử dụng Markdown?
          </Typography>
        </a>
      </Box>
      {props.error && (
        <Box ml={2} mt={1} className={classes.error}>
          <Typography variant="caption">{props.error}</Typography>
        </Box>
      )}
    </>
  )
}

export default MarkdownEditor
