import { useEffect } from 'react'

export const useInterval = (callback: () => void, delay: number): void => {
  // const savedCallback = useRef<Function>()

  // //remember the latest function
  // useEffect(() => {
  //   savedCallback.current = callback
  // }, [callback])

  //set up the interval
  useEffect(() => {
    let timer = setInterval(callback, delay)
    return () => clearInterval(timer)

    // We want callback has been update
  }, [callback, delay])
}
