export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const LogTasks = {
  CREATE_USER_ON_SSO_AGILEARN: 'CREATE_ACCOUNT_ON_SSO_AGILEARN',
  CREATE_USER_ON_MATTERMOST: 'CREATE_ACCOUNT_ON_MATTERMOST',
  ADD_USER_TO_CHATOPS_TEAM: 'ADD_USER_TO_CHATOPS_TEAM',
  ADD_MEMBER_TO_CHATOPS_CHANNEL: 'ADD_MEMBER_TO_CHATOPS_CHANNEL',
  CREATE_TEAM_ON_LEARNING_SPACE_CREATED:
    'CREATE_TEAM_ON_LEARNING_SPACE_CREATED',
  CREATE_CHANNEL_ON_CLASS_CREATED: 'CREATE_CHANNEL_ON_CLASS_CREATED',
  REMOVE_MEMBER_FROM_CHATOPS_CHANNEL: 'REMOVE_MEMBER_FROM_CHATOPS_CHANNEL',
  SEND_DIRECT_MESSAGE: 'SEND_DIRECT_MESSAGE'
}

export const ROLES = {
  ADMIN: 'admin',
  LEARNING_MANAGER: 'learningManager',
  INSTRUCTOR: 'instructor',
  STUDENT: 'student',
  ISSUE_ADMIN: 'issueAdmin',
  ASSISTANT: 'assistant',
  CLASS_ADMIN: 'classes:admin',
  ADMIN_PAGE_ACCESS: 'admin_page:access',
  CERTIFICATION_ADMIN: 'certifications:admin',
  COURSE_ADMIN: 'courses:admin',
  LESSON_ADMIN: 'lessons:admin',
  QUESTION_BANK_ADMIN: 'question_banks:admin',
  USER_ADMIN: 'users:admin',
  CLASS_REPORT_ACCESS: 'classes:report_access',
  REPORT_FULL_ACCESS: 'reports:full_access'
}

export const GENDER_TEXTS = {
  MALE: 'Nam',
  FEMALE: 'Nữ'
}

export const CLASS_RESPONSE_MESSAGES: {
  INSTRUCTOR_HAD_EVENTS: 'instructor-had-events'
} = {
  INSTRUCTOR_HAD_EVENTS: 'instructor-had-events'
}
