import { Theme, createStyles } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    buttonSubmitSubDomain: {
      margin: '10px 0 0 0'
    },

    formSubDomain: {
      margin: '20px auto 0px auto'
    },

    formTitle: {
      paddingBottom: theme.spacing(2)
    },

    inputSubdomain: {
      alignItems: 'center',
      display: 'flex'
    },
    baseDomain: {
      marginLeft: '5px'
    },
    textValidate: {
      color: 'red',
      paddingLeft: '10px'
    }
  })
