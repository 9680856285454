import React, { useContext, useState, useEffect } from 'react'
import SubdomainContext from 'contexts/SubdomainContext'
import { useFirestore, useUser } from 'reactfire'
import RoleContext from 'contexts/RoleContext'
import { firestore } from 'firebase'

interface IProps {}

const RoleContextProvider: React.FC<IProps> = (props) => {
  const domain = useContext(SubdomainContext).domain
  const { children } = props
  const user = useUser<firebase.User>()
  const db = useFirestore()
  const [userRoles, setUserRoles] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    if (domain && user) {
      const subscriber = db
        .collection('user_metadata')
        .doc(user.uid)
        .collection('space_roles')
        .doc(domain)
        .onSnapshot(function (documentSnapshot: firestore.DocumentSnapshot) {
          setUserRoles(documentSnapshot.data()?.roles)
        })
      return () => subscriber()
    }
  }, [db, domain, user])

  return (
    <>
      <RoleContext.Provider value={{ roles: userRoles }}>
        {children}
      </RoleContext.Provider>
    </>
  )
}

export default RoleContextProvider

export const useMySpaceRoles = () => useContext(RoleContext)?.roles || {}
