import { useCollectionData } from 'hooks/firestoreHooks'
import { ReflectionTemplate } from 'types'
import { useFirestore } from 'reactfire'

export const useReflectionTemplates = () => {
  const db = useFirestore()
  return useCollectionData<ReflectionTemplate>(
    db.collection('reflection_templates').where('state', '==', 'PUBLISHED'),
    { idField: 'id' }
  )
}
