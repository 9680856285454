import LearningSpaceContext from 'contexts/LearningSpaceContext'
import { ActiveWhenType } from 'types'
import { useContext } from 'react'

export const useActiveWhen = (conditions: ActiveWhenType) => {
  const space = useContext(LearningSpaceContext)

  return conditions.every((condition) => {
    switch (condition) {
      case 'space:active':
        return space && space.state === 'ACTIVE'
      default:
        return false
    }
  })
}
