export function validateDataAnswers(
  answers: Array<{
    [key: string]: any
  }>
) {
  let errors: {
    general?: string
    answers: Array<{ [key: string]: string }>
  } = { answers: [], general: '' }

  const isWithoutCorrentAnswer = answers.every((a) => !Boolean(a.isCorrect))
  if (isWithoutCorrentAnswer) {
    errors.general = 'Câu hỏi cần có ít nhất một câu trả lời chính xác!'
  }

  answers.forEach((answer, index) => {
    let error: { [key: string]: string } = {}
    if (!answer.content) {
      error.content = 'Không được bỏ trống!'
    }
    errors.answers[index] = error
  })

  return errors
}
