import React, { useContext, useState } from 'react'
import { useObservable, useFirestore, useUser, useAuth } from 'reactfire'
import { from } from 'rxjs'
import ErrorBlock from 'components/ErrorBlock'
import { RouteProps, useHistory } from 'react-router-dom'
import SubdomainContext from 'contexts/SubdomainContext'
import { useDocumentData } from 'hooks/firestoreHooks'
import { LearningSpaceUserType } from 'types'
import { Box, Container } from '@material-ui/core'
import { clearSessionToken } from 'utils'
import LoadableButton from 'components/LoadableButton'
import WrongDomainNotifier from 'components/WrongDomainNotifier'
import firebase from 'firebase/app'
import { useAuthorization } from 'hooks/useAuthorization'

export declare type AuthProps = {
  authUser: firebase.User
}

export function useIdTokenResult(
  user: firebase.User,
  forceRefresh: boolean = false
) {
  if (!user) {
    throw new Error('you must provide a user')
  }

  const idToken$ = from(user.getIdTokenResult(forceRefresh))

  return useObservable<any>(
    idToken$,
    `auth:idTokenResult:${user.uid}:forceRefresh=${forceRefresh}`
  )
}

interface CheckClaimsProps extends RouteProps {
  component: React.ComponentType<AuthProps> | React.LazyExoticComponent<any>
  user: firebase.User
  requiredClaims: { [key: string]: boolean }
}

export const CheckClaims: React.FC<CheckClaimsProps> = (props) => {
  const { user, component: C, requiredClaims, ...rest } = props
  const { claims } = useIdTokenResult(user, false)
  const missingClaims: {
    [key: string]: { expected: boolean; actual: any }
  } = {}

  Object.keys(requiredClaims).forEach((claim) => {
    if (requiredClaims[claim] !== claims[claim]) {
      missingClaims[claim] = {
        expected: requiredClaims[claim],
        actual: claims[claim]
      }
    }
  })

  if (Object.keys(missingClaims).length !== 0) {
    return <ErrorBlock variant="403" />
  }

  return <C authUser={user} {...rest} />
}

export interface ICheckSubdomain extends RouteProps {
  user: firebase.User
}

export const CheckSubdomain: React.FC<ICheckSubdomain> = (props) => {
  const { children, user } = props

  const auth = useAuth()
  const history = useHistory()
  const [loggingOut, setLoggingOut] = useState(false)
  if (!user) {
    throw new Error('you must provide a user')
  }

  const context = useContext(SubdomainContext)
  const userLearningSpaceDocRef = useFirestore()
    .collection('learning_spaces')
    .doc(context.domain)
    .collection('users')
    .doc(user.uid)
  const learningSpaceUser = useDocumentData<LearningSpaceUserType>(
    userLearningSpaceDocRef,
    { idField: 'id' }
  )

  const handleLogout = async () => {
    setLoggingOut(true)
    clearSessionToken()
      .then(() => {
        auth
          .signOut()
          .then(() => {
            history.push('/login')
          })
          .catch((err) => {
            console.error(err)
            setLoggingOut(false)
          })
      })
      .catch((err) => console.error(err))
  }

  if (!learningSpaceUser) {
    return (
      <Box mt={10}>
        <Container maxWidth={'xs'}>
          <WrongDomainNotifier
            handleLogout={handleLogout}
            loggingOut={loggingOut}
            learningSpaceId={context.domain || ''}
            user={user}
          />
        </Container>
      </Box>
    )
  }

  if (learningSpaceUser.state === 'INACTIVE') {
    return (
      <ErrorBlock
        title="Hiện tại tài khoản của bạn chưa được kích hoạt."
        backButtonTitle="Đăng xuất"
        variant="403"
        actionComponent={() => (
          <Box>
            <LoadableButton
              variant="contained"
              color="primary"
              loading={loggingOut}
              onClick={handleLogout}
            >
              Đăng xuất
            </LoadableButton>
          </Box>
        )}
      />
    )
  }

  if (learningSpaceUser.state === 'BLOCKED') {
    return (
      <ErrorBlock
        title="Xin lỗi, bạn không thể truy cập vào khu vực học tập này!"
        backButtonTitle="Đăng xuất"
        variant="403"
        actionComponent={() => (
          <Box>
            <LoadableButton
              variant="contained"
              color="primary"
              loading={loggingOut}
              onClick={handleLogout}
            >
              Đăng xuất
            </LoadableButton>
          </Box>
        )}
      />
    )
  }

  if (learningSpaceUser?.inviteStatus === 'waiting')
    return (
      <ErrorBlock
        title="Vui lòng kiểm tra thư mời tham dự không gian học tập trong hòm thư của bạn!"
        backButtonTitle="Đăng xuất"
        variant="403"
        actionComponent={() => (
          <Box>
            <LoadableButton
              variant="contained"
              color="primary"
              loading={loggingOut}
              onClick={handleLogout}
            >
              Đăng xuất
            </LoadableButton>
          </Box>
        )}
      />
    )

  return <>{children}</>
}

export interface CheckRolesProps extends RouteProps {
  component: React.ComponentType<AuthProps> | React.LazyExoticComponent<any>
  user: firebase.User
  requiredRoles: { [key: string]: boolean }
}

export const CheckRoles: React.FC<CheckRolesProps> = (props) => {
  const { user, requiredRoles, component: C, ...rest } = props
  
  if (!user) {
    throw new Error('you must provide a user')
  }

  const hasRequiredRoles = useAuthorization(...Object.keys(requiredRoles))

  if (!hasRequiredRoles) {
    return <ErrorBlock variant="403" />
  }

  return <C authUser={user} {...rest} />
}

interface IProps extends RouteProps {
  component: React.ComponentType<AuthProps> | React.LazyExoticComponent<any>
  requiredClaims?: { [key: string]: boolean }
  requiredRoles?: { [key: string]: boolean }
}

export const Authorization: React.FC<IProps> = (props) => {
  const user = useUser<firebase.User>()
  const { component: C, requiredClaims, requiredRoles, ...rest } = props

  const allowedClaims = { admin: true }

  const { claims } = useIdTokenResult(user, false)

  let hasAllowedClaims = false

  const allowedClaimsArray = Object.keys(allowedClaims)

  for (let i = 0; i < allowedClaimsArray.length; i++) {
    if (claims[allowedClaimsArray[i]] === true) {
      hasAllowedClaims = true
      break
    }
  }

  if (hasAllowedClaims) return <C authUser={user} {...rest} />

  return (
    <CheckSubdomain user={user}>
      {requiredClaims ? (
        <CheckClaims
          user={user}
          requiredClaims={requiredClaims}
          component={C}
          {...rest}
        />
      ) : requiredRoles ? (
        <CheckRoles
          user={user}
          requiredRoles={requiredRoles}
          component={C}
          {...rest}
        />
      ) : (
        <C authUser={user} {...rest} />
      )}
    </CheckSubdomain>
  )
}
