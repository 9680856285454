import React from 'react'
import { Paper, PaperProps } from '@material-ui/core'

interface IProps extends PaperProps {
  round?: number
  bgColor?: string
}

const MyPaper: React.FC<IProps> = ({ round = 2, bgColor, ...paperProps }) => {
  return (
    <Paper
      style={{
        borderRadius: round * 10,
        overflow: 'hidden',
        backgroundColor: bgColor
      }}
      {...paperProps}
    />
  )
}

export default MyPaper
