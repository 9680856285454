import React, { useCallback, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { parseTimeByFormat } from 'utils'
import LoadableButton from 'components/LoadableButton'
import MaybachTable, { IHeader } from 'components/MaybachTable'
import { validateRowImport } from 'utils/CertificationExamUtil'
import { useLearningSpace } from 'contexts/LearningSpaceContext'

interface IProps {
  activeStep: number
  config: { [key: string]: any }
  uploadData: { line: number; email: string; password: string; name: string }[]
  remove: (index: number) => void
  import: () => void
  handleGoBack: () => void
}

const ROWS_PER_PAGE = 20

const TABLE_HEADER: IHeader[] = [
  { key: 'line', label: 'Dòng', w: 20, mw: 15 },
  { key: 'message', label: 'Lỗi', w: 200, mw: 150 },
  { key: 'email', label: 'Email', w: 200, mw: 150 },
  { key: 'name', label: 'Tên' },
  { key: 'password', label: 'Mật khẩu' },
  { key: 'startAt', label: 'Bắt đầu' },
  { key: 'finishAt', label: 'Kết thúc' }
]

const CEImportUserReviewStep: React.FC<IProps> = ({
  activeStep,
  uploadData,
  config,
  remove,
  import: Import,
  handleGoBack
}) => {
  const [importing, setImporting] = useState(false)
  const space = useLearningSpace()

  const handleImport = useCallback(async () => {
    setImporting(true)
    await Import()
    setImporting(false)
  }, [Import])

  const customFields = useMemo(
    () => Object.values(space.userCustomFields || {}),
    [space.userCustomFields]
  )

  const tableRows = useMemo(
    () =>
      uploadData.map((row: any) => ({
        name: { label: row.name },
        line: { label: row.line },
        password: { label: row.password },
        email: { label: row.email },
        message: { label: validateRowImport(row) },
        startAt: {
          label: config.startAt
            ? (parseTimeByFormat(
                new Date(config.startAt),
                'hh:mm dd/MM/YYYY'
              ) as string)
            : ''
        },
        finishAt: {
          label: config.finishAt
            ? (parseTimeByFormat(
                new Date(config.finishAt),
                'hh:mm dd/MM/YYYY'
              ) as string)
            : ''
        },
        ...customFields.reduce<{ [k: string]: any }>((fields, field) => {
          fields[field.key] = { label: row[field.key] }

          return fields
        }, {})
      })),
    [uploadData, config, customFields]
  )

  const allValid = tableRows.every((r) => !r.message.label)

  if (activeStep !== 1) return null

  return (
    <MaybachTable
      headers={TABLE_HEADER.concat(
        customFields.map((field) => ({ label: field.display, key: field.key }))
      )}
      rows={tableRows}
      removable
      onRemove={remove}
      rowsPerPage={ROWS_PER_PAGE}
      bottomToolbar={
        <Box p={2} display={'flex'} justifyContent={'flex-end'}>
          <LoadableButton
            style={{ minWidth: 150, marginRight: 15 }}
            color={'default'}
            variant="contained"
            loading={importing}
            onClick={handleGoBack}
          >
            Quay lại
          </LoadableButton>
          <LoadableButton
            color={'primary'}
            variant="contained"
            loading={importing}
            onClick={handleImport}
            disabled={uploadData.length === 0 || !allValid}
          >
            Nhập
          </LoadableButton>
        </Box>
      }
    />
  )
}

export default CEImportUserReviewStep
