import { validateUrl } from './ValidateUrl'

export function validateVideoActivity(input: any): { [key: string]: string } {
  let error: { [key: string]: string } = {}
  if (!input.title) {
    error.title = 'Tiêu đề không được để trống!'
  }

  if (!validateUrl(input.data.url)) {
    error.url = 'Đường dẫn không đúng định dạng'
  }

  if (!input.data.url) {
    error.url = 'Đường dẫn không được để trống!'
  }
  return error
}
