import { IFirestoreMetadata } from 'interfaces'
import { createContext, useContext } from 'react'
import { LearningSpaceType } from 'types'

export const LearningSpaceContext = createContext<
  (LearningSpaceType & IFirestoreMetadata) | undefined
>(undefined)

export const useLearningSpace = () => {
  const learningSpace = useContext(LearningSpaceContext)

  if (!learningSpace) {
    throw Error(
      'Something went wrong!, You must use this function inside LearningSpaceProvider'
    )
  }

  return learningSpace
}

export default LearningSpaceContext
