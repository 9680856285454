import { IFirestoreMetadata } from 'interfaces'
import React, { useContext } from 'react'
import { LearningSpaceClass } from 'types'

const LearningSpaceClassContext = React.createContext<
  (LearningSpaceClass & IFirestoreMetadata) | undefined
>(undefined)

export const LearningSpaceClassProvider: React.FC<{
  value: (LearningSpaceClass & IFirestoreMetadata) | undefined
}> = (props) => {
  return (
    <LearningSpaceClassContext.Provider
      {...props}
    ></LearningSpaceClassContext.Provider>
  )
}

export const useLearningSpaceClass = () => {
  return useContext(LearningSpaceClassContext)
}

export default LearningSpaceClassContext
