import { firestore } from 'firebase'
import {
  ActivityType,
  CheckList,
  CheckListTask,
  ClassAccessibility,
  ClassBussinessFeedback,
  ClassChecklist,
  ClassChecklistTask,
  ClassEventAttendance,
  ClassReflectionSubmission,
  ClassStudentModuleGradeStatistics,
  ConditionOperator,
  CourseActivityType,
  LearningSpaceClass,
  LearningSpaceClassUser,
  LearningSpaceCourseStatisticsType,
  LearningSpaceProgramActivity,
  LearningSpaceProgramGrade,
  LearningSpaceProgramSection,
  LSProgramCourseActivity,
  LSProgramEventActivity,
  StateOfRecord
} from 'types'
import { PATTERNS } from 'utils/StringFormatter'
import { DateTime } from 'luxon'
import { SchemeType } from 'types'
import { IFirestoreMetadata } from 'interfaces'
import { getCollectionData, getDocument } from './FirestoreUtils'
import { isEmpty, merge, omit, pick } from 'lodash'
import { getPaginationStudents } from './LearningSpaceClassUserUtils'
import { convertToMapObject, splitItems } from './Array'
import { groupProgramSectionsWithActivities } from './LSClassProgramUtils'
import { getCourse as getLearningSpaceCourse } from './LearningSpaceUtils'
import {
  getPublishedLectures,
  getPublishedQuizzes,
  getPublishedSections,
  mergeActivitiesAndSortBySection
} from './CourseUtils'
import ValidationError from 'errors/ValidationError'
import { hashKey } from './HashUtils'
import { STATE_OF_RECORD } from 'constants/common'

export function learningSpaceClassValidateForm(data: {
  name: string
  startAt: Date | undefined
  finishAt: Date | undefined
  brief: string
  scheme: (SchemeType & IFirestoreMetadata) | undefined
  fromScheme: boolean
}) {
  const errors: {
    name?: string
    startAt?: string
    finishAt?: string
    brief?: string
    scheme?: string
  } = {}

  if (!data.name.trim()) {
    errors.name = 'Tên không được bỏ trống!'
  }

  if (!data.startAt) {
    errors.startAt = 'Thời gian bắt đầu không được bỏ trống!'
  } else if (!(data.startAt instanceof Date)) {
    errors.startAt = 'Thời gian bắt đầu không đúng định dạng!'
  }

  if (data.finishAt) {
    if (!(data.finishAt instanceof Date)) {
      errors.finishAt = 'Thời gian kết thúc không đúng định dạng!'
    } else if (data.startAt && data.finishAt <= data.startAt) {
      errors.finishAt = 'Thời gian kết thúc phải sau ngày bắt đầu!'
    }
  }

  if (data.brief && data.brief.length > 500) {
    errors.brief = 'Tóm tắt không được quá 500 ký tự!'
  }

  if (data.fromScheme) {
    if (!data.scheme) {
      errors.scheme = 'Bạn phải chọn 1 chương trình học để tạo lớp'
    }
  }

  return errors
}

export function cloneChecklist(
  classRef: firestore.DocumentReference,
  startAt: Date,
  checklist: CheckList,
  tasks: CheckListTask[],
  batch: firestore.WriteBatch
) {
  const defaultDay = DateTime.fromObject(
    {
      year: startAt.getFullYear(),
      month: startAt.getMonth() + 1,
      day: startAt.getDate(),
      hour: 23,
      minute: 59,
      second: 59
    },
    { locale: 'Asia/Ho_Chi_Minh' }
  )

  const timestampNow =
    firestore.FieldValue.serverTimestamp() as firestore.Timestamp
  const ref = classRef.collection('checklists').doc()
  batch.set(ref, {
    name: checklist.name,
    state: 'PUBLISHED',
    timeTracks: { createdAt: timestampNow },
    data: checklist.data || {}
  } as ClassChecklist)

  const convertDuration = (duration: string, isBefore?: boolean) => {
    const results = new RegExp(PATTERNS.CHECKLIST_TASK_DURATION).exec(
      duration + ' '
    )
    if (results) {
      const weeks = !!results[1] ? +results[1].trim().replace('w', '') : 0
      const days = !!results[2] ? +results[2].trim().replace('d', '') : 0
      const hours = !!results[3] ? +results[3].trim().replace('h', '') : 0

      const expireAt = defaultDay.set(
        !!isBefore
          ? {
              day: defaultDay.day - weeks * 7 - days,
              hour: defaultDay.hour - hours
            }
          : {
              day: defaultDay.day + weeks * 7 + days,
              hour: defaultDay.hour + hours
            }
      )

      return firestore.Timestamp.fromDate(expireAt.toJSDate())
    }

    return firestore.Timestamp.fromDate(defaultDay.toJSDate())
  }

  tasks.forEach((task) => {
    const taskRef = ref.collection('tasks').doc()
    batch.set(taskRef, {
      title: task.title,
      timeTracks: { createdAt: timestampNow },
      state: 'PUBLISHED',
      description: task.description,
      ordering: task.ordering,
      status: 'TO_DO',
      expireAt: convertDuration(task.duration, task.beforeClassStart),
      duration: task.duration,
      data: task.data || {},
      deadlineReferencePoint: 'CLASS_START_AT'
    } as ClassChecklistTask)
  })
}

export const validateConditionWithOperator = (
  operator: ConditionOperator,
  point: string,
  secondPoint: string,
  firstSwitchConditionToIn: boolean
) => {
  let valid = true
  let errors: { [p: string]: string } = {}

  if (operator === 'in') {
    if (secondPoint !== '' && point !== '') {
      const secondPointNumber = +secondPoint
      const pointNumber = +point
      if (!isNaN(secondPointNumber) && !isNaN(pointNumber)) {
        if (!firstSwitchConditionToIn) {
          if (secondPointNumber <= pointNumber) {
            errors.secondConditionPoint = 'Số sau phải lớn hơn số trước'
            valid = false
          }
        }
        if (secondPointNumber < 0) {
          errors.secondConditionPoint = 'Bạn phải nhập số lớn hơn 0'
          valid = false
        }
        if (pointNumber < 0) {
          errors.conditionPoint = 'Bạn phải nhập số lớn hơn 0'
          valid = false
        }
      } else {
        if (isNaN(secondPointNumber)) {
          errors.secondConditionPoint = 'Bạn phải nhập số'
          valid = false
        }
        if (isNaN(pointNumber)) {
          errors.conditionPoint = 'Bạn phải nhập số'
          valid = false
        }
      }
    } else {
      if (secondPoint === '') {
        errors.secondConditionPoint = 'Bạn phải nhập số'
        valid = false
      }
      if (point === '') {
        errors.conditionPoint = 'Bạn phải nhập số'
        valid = false
      }
    }
  } else {
    if (point !== '') {
      const pointNumber = +point
      if (isNaN(pointNumber)) {
        errors.conditionPoint = 'Bạn phải nhập số'
        valid = false
      } else {
        if (pointNumber < 0) {
          errors.conditionPoint = 'Bạn phải nhập số lớn hơn 0'
          valid = false
        }
      }
    } else {
      errors.conditionPoint = 'Bạn phải nhập số'
      valid = false
    }
  }

  if (valid) {
    delete errors.conditionPoint
    delete errors.secondConditionPoint
  }
  return { errors: errors, valid: valid }
}
export const parseClassAccessibility = (
  classAccessibility: ClassAccessibility | undefined
) => {
  switch (classAccessibility) {
    case 'PRIVATE':
      return 'Kín'
    case 'PROTECTED':
      return 'Mở'
    case 'PUBLIC':
      return 'Công khai'
    default:
      return 'Kín'
  }
}

export const getAllPublishedEventsInRange = async (
  klass: LearningSpaceClass & IFirestoreMetadata,
  range: { startDate: Date; endDate: Date }
) => {
  const programId = klass.programId as string
  const programRef = klass._meta.ref.collection('programs').doc(programId)
  const query = programRef
    .collection('activities')
    .where('type', '==', 'EVENT')
    .where('state', '==', 'PUBLISHED')
    .where('timeTracks.finishAt', '<=', range.endDate)
    .where('timeTracks.finishAt', '>', range.startDate)

  return getCollectionData<LSProgramEventActivity>(query, { idField: 'id' })
}

export const getActiveUsersByRole = async (
  klassRef: firestore.DocumentReference,
  role: 'assistant' | 'student' | 'onwer' | 'instructor'
) => {
  const query = klassRef
    .collection('users')
    .where('state', '==', 'ACTIVE')
    .where(`roles.${role}`, '==', true)

  return getCollectionData<LearningSpaceClassUser>(query, { idField: 'id' })
}

export const filterActivityHasBeenAssigned = (
  activities: LearningSpaceProgramActivity[]
): [LearningSpaceProgramActivity[], LearningSpaceProgramActivity[]] => {
  const notAssignee: LearningSpaceProgramActivity[] = []
  const assigned: LearningSpaceProgramActivity[] = []

  activities.forEach((act) => {
    if (!!act.assignedTo) {
      assigned.push(act)
    } else {
      notAssignee.push(act)
    }
  })

  return [assigned, notAssignee]
}

export const convertActToLectureActivities = (
  act: LearningSpaceProgramActivity & IFirestoreMetadata
) => {
  const lectureAct: ActivityType & IFirestoreMetadata & { timeTracks: any } = {
    id: act.id as string,
    _meta: act._meta,
    data: omit(act, ['_meta']) as any,
    description: '',
    title: act.title,
    type: ('CLASS_' + act.type) as any,
    state: STATE_OF_RECORD.PUBLISHED as StateOfRecord,
    ordering: act.ordering,
    timeTracks: act.timeTracks
  }

  return lectureAct
}

export const getCourseActivityConfigurations = (
  activity: LSProgramCourseActivity
) => activity.configurations || {}

export const getCourseLecturesActivityOrdersConfig = (
  activity: LSProgramCourseActivity,
  lectureId: string
) => {
  const config = getCourseActivityConfigurations(activity).orders || {}
  return config[lectureId] || {}
}

export const mergeCourseActivityOrdersConfig = (
  configurations: LSProgramCourseActivity['configurations'],
  lectureId: string,
  data: { [k: string]: number }
) => {
  return merge(configurations, { orders: { [lectureId]: data } })
}

export const getAttendanceByUserIds: (
  classRef: firestore.DocumentReference,
  userIds: string[]
) => Promise<(ClassEventAttendance & IFirestoreMetadata)[]> = async (
  classRef,
  userIds
) => {
  if (userIds.length > 1000) throw new Error('Limit maximum is 100 userIds!')
  const attendances: (ClassEventAttendance & IFirestoreMetadata)[] = []

  const splitIds = splitItems(userIds, 10)

  await Promise.all(
    splitIds.map(async (ids) => {
      const query = classRef
        .collection('attendance')
        .where('studentId', 'in', ids)
      const results = await getCollectionData<ClassEventAttendance>(query, {
        idField: 'id'
      })

      attendances.push(...results)
    })
  )

  return attendances
}

export const getGradesByUserIds: (
  classRef: firestore.DocumentReference,
  userIds: string[]
) => Promise<(LearningSpaceProgramGrade & IFirestoreMetadata)[]> = async (
  classRef,
  userIds
) => {
  if (userIds.length > 1000) throw new Error('Limit maximum is 100 userIds!')
  const grades: (LearningSpaceProgramGrade & IFirestoreMetadata)[] = []

  const splitIds = splitItems(userIds, 10)

  await Promise.all(
    splitIds.map(async (ids) => {
      const query = classRef.collection('grades').where('userId', 'in', ids)
      const results = await getCollectionData<LearningSpaceProgramGrade>(
        query,
        { idField: 'id' }
      )

      grades.push(...results)
    })
  )

  return grades
}

export declare type IGetAttendanceReportDataResult = Pick<
  LearningSpaceClassUser,
  'id' | 'email' | 'state' | 'name' | 'customFields' | 'photoUrl'
> & {
  attendanceList: {
    [eventId: string]: ClassEventAttendance & IFirestoreMetadata
  }
}

export const getAttendanceReportData = async (
  classRef: firestore.DocumentReference,
  pageToken: null | string,
  limit: number,
  orderBy: ['email', 'asc' | 'desc']
): Promise<IGetAttendanceReportDataResult[]> => {
  const activeStudents = await getPaginationStudents(
    classRef,
    pageToken,
    limit,
    orderBy
  )
  const userIds = activeStudents.map((u) => u.id || '')
  const attendanceList = await getAttendanceByUserIds(classRef, userIds)

  return activeStudents.map((student) => {
    const studentAttendanceList = attendanceList
      .filter((att) => att.studentId === student.id)
      .map((att) => {
        att.eventId = att.eventRef.id
        return att
      })

    return {
      id: student.id,
      email: student.email,
      state: student.state,
      name: student.name,
      customFields: student.customFields,
      photoUrl: student.photoUrl,
      attendanceList: convertToMapObject(
        studentAttendanceList,
        'eventId'
      ) as any
    }
  })
}

export declare type IGetReportGradesResult = Pick<
  LearningSpaceClassUser,
  'customFields' | 'email' | 'photoUrl' | 'state' | 'name' | 'id'
> & {
  grades: {
    [activity: string]: Pick<
      LearningSpaceProgramGrade,
      'activityId' | 'grade' | 'id' | 'timeTracks'
    >
  }
  totalGrade: number
}

export const getReportGrades = async (
  classRef: firestore.DocumentReference,
  pageToken: null | string,
  limit: number,
  orderBy: ['email', 'asc' | 'desc']
): Promise<IGetReportGradesResult[]> => {
  if (limit > 1000) throw new Error('Limit maximum is 1000 documents')

  const activeStudents = await getPaginationStudents(
    classRef,
    pageToken,
    limit,
    orderBy
  )

  const userIds = activeStudents.map((u) => u.id || '')
  const grades = await getGradesByUserIds(classRef, userIds)

  return activeStudents.map((student) => {
    const studentGrades = grades
      .filter((grade) => grade.userId === student.id)
      .map((grade) => pick(grade, ['activityId', 'id', 'grade', 'timeTracks']))

    const totalGrade = studentGrades.reduce(
      (ttp, grade) => ttp + grade.grade,
      0
    )

    return {
      id: student.id,
      email: student.email,
      state: student.state,
      name: student.name,
      customFields: student.customFields,
      photoUrl: student.photoUrl,
      grades: convertToMapObject(studentGrades, 'activityId') as any,
      totalGrade
    }
  })
}

export const getOrderedSections = async (
  programRef: firestore.DocumentReference
) => {
  const query = programRef
    .collection('sections')
    .where('state', '==', 'PUBLISHED')
    .orderBy('ordering')

  return getCollectionData<LearningSpaceProgramSection>(query, {
    idField: 'id'
  })
}

export const getOrderedClassCourses = async (
  programRef: firestore.DocumentReference
) => {
  const query = programRef
    .collection('activities')
    .where('state', '==', 'PUBLISHED')
    .where('type', '==', 'COURSE')
    .orderBy('ordering')

  return getCollectionData<LSProgramCourseActivity>(query, {
    idField: 'id'
  })
}

export const getCourseStatistic = async (
  learningSpaceRef: firestore.DocumentReference,
  courseId: string
) => {
  if (!courseId)
    return [] as (LearningSpaceCourseStatisticsType & IFirestoreMetadata)[]

  const query = learningSpaceRef
    .collection('courses')
    .doc(courseId)
    .collection('statistics')
  return getCollectionData<LearningSpaceCourseStatisticsType>(query, {
    idField: 'id'
  })
}

export const getCourseStatisticDataInClass = async (
  learningSpaceRef: firestore.DocumentReference,
  classId: string | null,
  courseId: string | null
) => {
  const data: {
    progresses: (LearningSpaceCourseStatisticsType & IFirestoreMetadata)[]
    activities: CourseActivityType[]
  } = {
    progresses: [],
    activities: []
  }
  if (!courseId || !classId) return data

  const lsCourse = await getLearningSpaceCourse(learningSpaceRef, courseId)
  const courseRef = lsCourse?.courseRef

  if (!courseRef) return data

  const query = learningSpaceRef
    .collection('courses')
    .doc(courseId)
    .collection('statistics')
    .where('classId', '==', classId)

  const [sections, lectures, quizzes, progresses] = await Promise.all([
    getPublishedSections(courseRef),
    getPublishedLectures(courseRef),
    getPublishedQuizzes(courseRef),
    getCollectionData<LearningSpaceCourseStatisticsType>(query, {
      idField: 'id'
    })
  ])

  data.activities = mergeActivitiesAndSortBySection(lectures, quizzes, sections)
  data.progresses = progresses

  return data
}

export const getOrderedGradableActivities = async (
  programRef: firestore.DocumentReference
): Promise<(LearningSpaceProgramActivity & IFirestoreMetadata)[]> => {
  const query = programRef
    .collection('activities')
    .where('state', '==', 'PUBLISHED')
    .where('type', 'in', ['COURSE', 'ASSIGNMENT', 'EVENT', 'REFLECTION'])
    .orderBy('ordering', 'asc')

  const [sections, activities] = await Promise.all([
    getOrderedSections(programRef),
    getCollectionData<LearningSpaceProgramActivity>(query, { idField: 'id' })
  ])

  const group = groupProgramSectionsWithActivities(sections, activities)

  return [].concat(...(group.map((g) => g.activities || []) as any))
}

export const getActiveClasses = async (
  spaceRef: firestore.DocumentReference,
  pageToken: string | null,
  limit: number,
  orderBy: ['timeTracks.createdAt', 'asc' | 'desc']
) => {
  const collection = spaceRef.collection('classes')

  let query = collection
    .where('status', '==', 'ACTIVE')
    .orderBy(orderBy[0], orderBy[1])
    .limit(limit)

  if (pageToken) {
    const snapshot = await collection.doc(pageToken).get()
    query = query.startAfter(snapshot)
  }

  return getCollectionData<LearningSpaceClass>(query, { idField: 'id' })
}

export const gradeReflection = async (
  classRef: firestore.DocumentReference,
  submissionId: string,
  gradedById: string,
  grade: number,
  feedback: string
) => {
  let errors: { [k: string]: any } = {}

  if (!grade || !Number.isInteger(grade) || grade < 0)
    errors.grade = 'Điểm phải là một số lớn hơn hoặc bằng 0'

  if (!isEmpty(errors)) throw new ValidationError(errors)

  const ref = classRef.collection('reflection_submissions').doc(submissionId)

  const submission = await getDocument<ClassReflectionSubmission>(ref, {
    idField: 'id'
  })

  if (!submission)
    throw new Error(`Submission với id ${submissionId} không tồn tại`)

  const gradeHistory = submission?.gradeHistory || []
  const latestGrade: ClassReflectionSubmission['latestGrade'] = {
    grade: grade,
    feedback: feedback || '',
    gradedBy: gradedById,
    gradedAt: firestore.Timestamp.now()
  }

  gradeHistory.push(latestGrade)
  const data: ClassReflectionSubmission = {
    latestGrade: latestGrade,
    gradeHistory: gradeHistory,
    status: 'GRADED'
  } as ClassReflectionSubmission

  return submission?._meta.ref.set(data, { merge: true })
}

export const getClassStudentModuleGrade = async (
  db: firestore.Firestore,
  learningSpaceId: string,
  classId: string
) => {
  const collection = db
    .collection('statistics')
    .doc('general')
    .collection('class_student_module_grade')
  const query = collection
    .where('learningSpaceId', '==', learningSpaceId)
    .where('classId', '==', classId)

  return getCollectionData<ClassStudentModuleGradeStatistics>(query, {
    idField: 'id'
  })
}

export function hashClassStudentModuleStatisticsId(
  spaceId: string,
  classId: string,
  sectionId: string,
  userId: string
) {
  return hashKey([spaceId, classId, sectionId, userId].join(','))
}

export async function saveBussinessFeedback(
  db: firestore.Firestore,
  learningSpaceId: string,
  classId: string,
  field: keyof ClassBussinessFeedback,
  value: any
) {
  const batch = db.batch()

  batch.set(
    db.doc(`learning_spaces/${learningSpaceId}/classes/${classId}`),
    {
      props: {
        businessFeedback: {
          [field]: value
        }
      }
    } as Pick<LearningSpaceClass, 'props'>,
    { merge: true }
  )

  await batch.commit()
}
