import React, { useCallback } from 'react'
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

interface IProps {
  label: any
  backTo: string
  backTooltipLabel: string
  showDivider?: boolean
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  style?: React.CSSProperties
}

const PageHeaderAndBackBtn: React.FC<IProps> = ({
  label,
  backTo,
  backTooltipLabel,
  showDivider = false,
  heading = 'h6',
  style
}) => {
  const history = useHistory()
  const back = useCallback(() => history.push(backTo), [backTo, history])
  return (
    <Box style={style}>
      <Box mb={1} display={'flex'} alignItems={'center'}>
        <Tooltip title={backTooltipLabel} placement={'top'}>
          <IconButton onClick={back}>
            <ArrowBackIos fontSize={'small'} />
          </IconButton>
        </Tooltip>

        <Typography
          style={{ marginLeft: 5, fontWeight: 'bold' }}
          variant={heading}
        >
          {label}
        </Typography>
      </Box>
      {showDivider && <Divider />}
    </Box>
  )
}

export default PageHeaderAndBackBtn
